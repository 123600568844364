import _cloneDeep from 'lodash.clonedeep';
import _set from 'lodash.set';
import _get from 'lodash.get';

import actionTypes from '@store/actions/resource/actionTypes';
import { LOADING } from '@config/constants';

const defaultNotif = {
  message: '',
  type: ''
};
const defaultResouce = {
  data: [],
  status: '',
  notif: { ...defaultNotif }
};
export const defaultState = {
  seniorities: _cloneDeep(defaultResouce),
  functionExp: _cloneDeep(defaultResouce),
  companies: _cloneDeep(defaultResouce),
  companySizes: _cloneDeep(defaultResouce),
  requisitionStages: _cloneDeep(defaultResouce),
  companyIndustries: _cloneDeep(defaultResouce),
  productDomains: _cloneDeep(defaultResouce),
  certifications: _cloneDeep(defaultResouce),
  requisitions: _cloneDeep(defaultResouce),
  accounts: _cloneDeep(defaultResouce),
  requisitionStatus: _cloneDeep(defaultResouce)
};

export default (state = defaultState, action = {}) => {
  const { type, ...args } = action;
  const newState = _cloneDeep(state);
  const stateKeyData = _cloneDeep(_get(args.key));

  switch (type) {
    case (actionTypes.RESOURCE_GET_DATA_START):
      return {
        ...state,
        [args.key]: {
          ...state[args.key],
          status: LOADING
        }
      };
    case (actionTypes.RESOURCE_GET_DATA_COMPLETE):
      _set(newState, args.key, {
        ...stateKeyData,
        ...args.data,
        status: ''
      });
      return newState;
    default:
      return state;
  }
};