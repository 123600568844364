import React, { Component } from 'react';
import { unmountComponentAtNode } from 'react-dom';
import eventEmitter from '@services/event-emitter';
import classnames from 'classnames';

import Modal, { ConfirmButtons } from '@components/shared/Modal/Modal';

class ConfirmRoute extends Component {
  state = {
    isModalVisible: true
  };

  afterCloseHandler = () => {
    unmountComponentAtNode(this.props.container);
  };

  save = () => {
    const { destination, eventKey } = this.props.config;
    this.setState({ inProgress: true });
    const cb = (result, hideModal = false) => {
      const stateUpdate = {
        inProgress: false
      };
      if (result === true) {
        stateUpdate.isModalVisible = false;
        this.props.cb(true);
      } else if (hideModal) {
        stateUpdate.isModalVisible = false;
      }
      this.setState(stateUpdate);
    };
    eventEmitter.emit(eventKey, { destination, cb });
  };

  discard = () => {
    this.setState({ isModalVisible: false }, () => {
      this.props.cb(true);
    });
  };

  onCancelHandler = () => {
    if (this.state.inProgress) {
      return;
    }

    this.setState({ isModalVisible: false }, this.props.cb);
  };

  render () {
    const { title, message, proceedLabel, cancelLable } = this.props.config;
    const { isModalVisible, inProgress } = this.state;
    return (
      <Modal
        title={title}
        closable={true}
        visible={isModalVisible}
        onCancel={this.onCancelHandler}
        afterClose={this.afterCloseHandler}
        className={classnames('route-confirm-modal', {
          'in-progress': inProgress
        })}
      >
        <div dangerouslySetInnerHTML={{ __html: message }} />
        <ConfirmButtons
          className="mt-4"
          okButtonProps={{
            children: proceedLabel || 'Yes',
            loading: inProgress,
            onClick: this.save
          }}
          cancelButtonProps={{
            children: cancelLable || 'No',
            disabled: inProgress,
            onClick: this.discard
          }}
        />
      </Modal>
    );
  }
}

export default ConfirmRoute;
