const resource = {
  ats: "api/ats",
  candidates: "/api/candidates",
  candidateProfile: "/api/candidateProfile",
  candidateProspects: "/api/candidateProspects",
  candidateInformations: "/api/candidateInformations",
  departments: "/api/departments",
  companyAccounts: "/api/companyAccounts",
  departmentFunctions: "/api/departmentFunctions",
  candidateSearches: "/api/candidateSearches",
  menuAccesses: "/api/menuAccesses",
  securityRoles: "/api/securityRoles",
  companies: "/api/companies",
  companyAliases: "/api/companyAliases",
  countries: "/api/countries",
  accountAuthorities: "/api/accountAuthorities",
  requisitionStages: "/api/requisitionStages",
  requisitions: "/api/requisitions",
  skills: "/api/skills",
  auth: "/oauth/token",
  seniorities: "/api/seniorities",
  genericOptions: "/mock/generic-options",
  locations: "/api/locations",
  transactionalOperations: "/api/transactionalOperations",
  companyIndustries: "/api/companyIndustries",
  productDomains: "/api/productDomains",
  constants: "/api/constants",
  companySizes: "/api/companySizes",
  accounts: "/api/accounts",
  welcomeEmails: "api/welcomeEmails",
  passwordResets: "api/passwordResets",
  forgotPasswords: "api/forgotPasswords",
  oauthToken: "/oauth/token",
  tasks: "/api/tasks",
  passwordChanges: "/api/passwordChanges",
  candidateUsers: "/api/candidateUsers",
  searchKeyword: "/api/skills/search/findMatchByName",
  contactRefreshRequests: "/api/contactRefreshRequests",
  licenses: "/api/licenses",
  licenseVersions: "/api/license-versions",
  users: "/api/users",
  educationMajors: "/api/educationMajors",
  educationDegrees: "/api/educationDegrees",
  apiStatus: "/api/status",
  gender: "/api/diversity/genders",
  ethnicity: "/api/diversity/ethnicities",
  userLicense: "/api/accounts/license",
  bulkEmail: "/api/bulkEmail",
  people: "/api/people",
  specializations: "/api/specializations",
  securityClearance: "/api/securityClearance",
  autoDialer: "/api/auto-dialer",
  communicationHistory: "api/communication-history",
  contactInformation: "api/contact-information",
};

export default resource;
