import { takeEvery, all, put } from 'redux-saga/effects';

import platormApiSvc from '@services/platform-api/';

import {
  initStart,
  initComplete
} from '../actions/app/';
import actionTypes from '../actions/app/actionTypes';

function * init () {
  yield put(initStart());
  let data;

  try {
    const resp = yield platormApiSvc.get('/api');
    global.headhuntr.resources = resp.data._links;
    data = {
      resources: resp.data._links,
      status: 'ready'
    };
  } catch (e) {
    data = {
      status: 'error'
    };
  }

  yield put(initComplete(data));
}

export default function * app () {
  yield all([
    takeEvery(actionTypes.APP_INIT, init)
  ]);
}