import React from "react";
import { Icon, message } from "antd";
import classnames from "classnames";

import { hhPrefixer } from "@helpers/collection";
const hc = hhPrefixer(
  [
    "bg-sec-green-d1",
    "bg-sec-red",
    "bg-prim-navy-l1",
    "bg-prim-orange-d1",
    "fc-white",
    "message-notif",
  ],
  true
);

const closModal = (key) => {
  message.destroy({ key });
};

export const SuccessMsgNotif = ({
  children,
  className,
  closable,
  notifKey,
  ...props
}) => {
  const classes = classnames(
    className,
    hc["bg-sec-green-d1"],
    hc["fc-white"],
    hc["message-notif"],
    { closable }
  );
  const closeBtn = closable && notifKey && (
    <span className="hhi-close close-btn" onClick={() => closModal(notifKey)} />
  );
  return (
    <div {...props} className={classes}>
      <Icon type="check-circle" theme="filled" />
      {children}
      {closeBtn}
    </div>
  );
};

export const ErrorMsgNotif = ({
  children,
  className,
  closable,
  notifKey,
  ...props
}) => {
  const classes = classnames(
    className,
    hc["bg-sec-red"],
    hc["fc-white"],
    hc["message-notif"],
    { closable }
  );
  const closeBtn = closable && notifKey && (
    <span className="hhi-close close-btn" onClick={() => closModal(notifKey)} />
  );
  return (
    <div {...props} className={classes}>
      <span className="hhi-error" />
      {children}
      {closeBtn}
    </div>
  );
};

export const InfoMsgNotif = ({
  children,
  className,
  closable,
  notifKey,
  icon,
  ...props
}) => {
  const classes = classnames(
    className,
    hc["bg-prim-navy-l1"],
    hc["fc-white"],
    hc["message-notif"]
  );
  const closeBtn = closable && notifKey && (
    <span className="hhi-close close-btn" onClick={() => closModal(notifKey)} />
  );
  return (
    <div {...props} className={classes}>
      {icon ?? <Icon type="info-circle" theme="filled" />}
      {children}
      {closeBtn}
    </div>
  );
};

export const WarnMsgNotif = ({
  children,
  className,
  closable,
  notifKey,
  ...props
}) => {
  const classes = classnames(className, "hh-message-notif hh-warning-notif");
  const closeBtn = closable && notifKey && (
    <span className="hhi-close close-btn" onClick={() => closModal(notifKey)} />
  );
  return (
    <div {...props} className={classes}>
      <Icon type="exclamation-circle" theme="filled" />
      {children}
      {closeBtn}
    </div>
  );
};

export const MsgNotif = ({ children, className, ...props }) => {
  const classes = classnames(className, hc["message-notif"]);
  return (
    <div {...props} className={classes}>
      {children}
    </div>
  );
};
