import React, { useState, useEffect, memo } from 'react';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';

const images = require.context('@assets/images', true);

const Redirect = (props) => {
  const { className, children, history, startCount = 5, redirectUrl = '?' } = props;
  const [counter, setCounter] = useState(startCount);
  const msg = (counter > 0) ? `You will be redirected in ${counter} second(s)` : 'Redirecting...';

  useEffect(() => {
    if (startCount) {
      if (counter > 0) {
        setTimeout(() => {
          setCounter(counter - 1);
        }, 1000);
      } else {
        history.push(redirectUrl);
      }
    }
  }, [counter]);

  return (
    <div className={classnames('page-redirect rounded', className)}>
      <div className="inner">
        {children}
        <div>{msg}</div>
      </div>
    </div>
  );
};

export const recordNotFound = ({ className, ...props }) => {
  return (
    <Redirect {...props} className={classnames(className, 'not-found')}>
      <img className="m-2" src={images('./page-no.svg').default} />
      <div>Record does not exist.</div>
    </Redirect>
  );
};
export const RecordNotFound = memo(withRouter(recordNotFound));

export const pageNotFound = ({ className, ...props }) => {
  return (
    <Redirect {...props} className={classnames(className, 'not-found')}>
      <img className="m-2" src={images('./empty-box.svg').default} />
      <div>Page does not exist.</div>
    </Redirect>
  );
};
export const PageNotFound = memo(withRouter(pageNotFound));

export const unauthorizedPageAccess = ({ className, ...props }) => {
  return (
    <Redirect {...props} className={classnames(className, 'not-found')}>
      <img className="m-2" src={images('./lock.svg').default} />
      <div>You do not have access to this page.</div>
    </Redirect>
  );
};
export const UnauthorizedPageAccess = memo(withRouter(unauthorizedPageAccess));

export const unauthorizedRecordAccess = ({ className, ...props }) => {
  return (
    <Redirect {...props} className={classnames(className, 'not-found')}>
      <img className="m-2" src={images('./lock.svg').default} />
      <div>You do not have access to this content.</div>
    </Redirect>
  );
};
export const UnauthorizedRecordAccess = memo(withRouter(unauthorizedRecordAccess));

export default memo(withRouter(Redirect));