import React from "react";

export const pageContents = {
  configurationComplete:
    "You have successfully completed the configuration for Auto Dialer Integration.",
  deleteModalMessage: (
    <>
      Upon deactivation, Twilio numbers assigned to users will be released
      <br /> and users will not have access to Auto Dialer feature. Please
      confirm.
    </>
  ),
  deactivateDescription:
    "You can deactivate this feature temporarily if you don’t want your users to use Auto Dialer.",
};

export const ACTIVATION_STATUS = {
  SUCCESSFUL_ACTIVATION: "SUCCESSFUL_ACTIVATION",
  SUCCESSFUL_DEACTIVATION: "SUCCESSFUL_DEACTIVATION",
};

export const ACTIVATION_STATUS_MESSAGE = {
  [ACTIVATION_STATUS.SUCCESSFUL_ACTIVATION]:
    "You have successfully activated the Auto Dialer integration.",
  [ACTIVATION_STATUS.SUCCESSFUL_DEACTIVATION]:
    "You have successfully deactivated the Auto Dialer integration.",
};
