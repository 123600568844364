import React from "react";
import yup from "@services/yup";
import { templateStringValidation } from "./validation";

export const messageTemplateGroup = {
  VOICEMAIL: "VOICEMAIL",
  TRANSFER_FOR_CANDIDATE: "TRANSFER_FOR_CANDIDATE",
  TRANSFER_FOR_RECRUITER: "TRANSFER_FOR_RECRUITER",
};

export const messageTemplateGroupLabels = {
  [messageTemplateGroup.VOICEMAIL]: "Voicemail",
  [messageTemplateGroup.TRANSFER_FOR_CANDIDATE]: "Transfer For Candidate",
  [messageTemplateGroup.TRANSFER_FOR_RECRUITER]: "Transfer For Recruiter",
};

export const templateVariables = {
  candidateFirstName: "John",
  candidateLastName: "Wick",
  userCompanyName: "Acme Corp",
  requisitionName: "Senior software engineer",
  requisitionJobTitle: "Full-stack developer",
  userFirstName: "Jane",
  userLastName: "Doe",
  userTwilioNumber: "(617) 461-7720",
  userEmailAddress: "jane.doe@acme.co",
};

export const constantsVariables = {
  CANDIDATE_FIRST_NAME: "{{candidateFirstName}}",
  CANDIDATE_LAST_NAME: "{{candidateLastName}}",
  USER_COMPANY_NAME: "{{userCompanyName}}",
  REQUISITION_NAME: "{{requisitionName}}",
  REQUISITION_JOB_TITLE: "{{requisitionJobTitle}}",
  USER_FIRST_NAME: "{{userFirstName}}",
  USER_LAST_NAME: "{{userLastName}}",
  USER_TWILIO_NUMBER: "{{userTwilioNumber}}",
  USER_EMAIL_ADDRESS: "{{userEmailAddress}}",
};

export const pageContents = {
  description: (
    <>
      <div>
        Here you can configure default message templates at a company level. The
        default templates will be available to your users. You can use the
        system defined template or copy it to create a custom template.
      </div>
      Only one custom template can be created for each of the enabled message
      types. New templates are automatically saved once created.
    </>
  ),
  dummyMessage:
    "Hi Tony Stark, this is Nick Fury from Avengers HQ. I am reaching out to you for a job opportunity Team Lead and I’d like to connect to discuss how you would be a great fit for this role. Please let me know if you are interested, and I can provide you with more information. You can reach out to me at (0617) 123-4567 or email at hr@avengers.com . Looking forward to discuss this opportunity with you. Thanks.",
  voicemailDisabled: (
    <>
      <div>Voicemail and Predefined Transfer Messages are not enabled.</div>
      You can go back and enable the features on the Configuration page, or
      proceed to the next step.
    </>
  ),
  modal: {
    variablesDescription: "Make sure to enclose the variable with {{ }}",
    variablesInfoText:
      "Variables are the dynamic texts that you can add to your Message. Add variable by typing or copying variables below.",
  },
};

export const textToSpeech = {
  label: "Text-To-Speech voices",
  link:
    "https://docs.aws.amazon.com/polly/latest/dg/voicelist.html#availablevoice-list",
};

export const defaultTemplateValues = {
  name: "",
  voice: "",
  message: "",
  saveAsNew: "",
};

export const templateFieldLabels = {
  name: "Template Name",
  voice: "Audio Voice",
  message: "Message",
  saveAsNew: "Save as new template",
};

export const maxMessageContent = 5000;
export const templateNamemaxLegth = 150;
export const variableMatcher = /{{([^}]*)}}/g;

export const templateValidationSchema = yup.object().shape({
  name: yup
    .string()
    .required()
    .max(templateNamemaxLegth)
    .label(templateFieldLabels.name),
  voice: yup
    .string()
    .nullable()
    .required()
    .required()
    .label(templateFieldLabels.voice),
  message: yup
    .string()
    .required()
    .required()
    .test({
      name: "validVariables",
      message: "Invalid variable",
      test: templateStringValidation.bind({
        variables: Object.keys(templateVariables),
        matcher: variableMatcher,
      }),
    })
    .label(templateFieldLabels.message),
});

export const voiceTypes = {
  NEURAL: "NEURAL",
  STANDARD: "STANDARD",
};

export const level = {
  USER: "USER",
  COMPANY: "COMPANY",
  SYSTEM: "SYSTEM",
};
