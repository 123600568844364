import _get from "lodash.get";
import moment from "moment";
import { MODULES, MODULE_LICENSE } from "../config/constants";
import { constants as hhActConstants } from "../config/hh-account";
import { licenseTypeKeys } from "../config/hh-account";

export const communicationLicenseCheck = (license) => {
  return (
    _get(license, "autoDialer.licenseEnabled") &&
    _get(license, "autoDialer.userEnabled")
  );
};

export const getLicenseDefaults = (params) => {
  let data = {};
  if (params.licenseType === licenseTypeKeys.STARTER) {
    data = {
      ...data,
      licenseType: licenseTypeKeys.STARTER,
      moduleName: [
        {
          id: MODULES.TALENT_SOURCING,
          name: MODULE_LICENSE[MODULES.TALENT_SOURCING],
        },
      ],
      licensedUserCount: 1,
      searchResultLimit: 25,
      activationDate: moment(),
      expirationDate: moment().add(1, "month"),
      searchAvailabilityType: hhActConstants.LIMITED,
      totalSearchCount: 5,
      searchPerUserCount: 5,
      requisitionPerUser: hhActConstants.LIMITED,
      requisitionPerUserLimit: 1,
      searchesPerDay: hhActConstants.LIMITED,
      searchesPerDayLimit: 10,
      searchResult: hhActConstants.LIMITED,
      csvExportEnabled: false,
      totalExportCount: 0,
      exportPerUserCount: 0,
    };
  } else if (params.licenseType === licenseTypeKeys.PROFESSIONAL) {
    data = {
      ...data,
      licenseType: licenseTypeKeys.PROFESSIONAL,
      moduleName: [
        {
          id: MODULES.TALENT_SOURCING,
          name: MODULE_LICENSE[MODULES.TALENT_SOURCING],
        },
      ],
      licensedUserCount: 1,
      activationDate: moment(),
      expirationDate: moment().add(1, "year"),
      searchesPerDay: hhActConstants.UNLIMITED,
      searchesPerDayLimit: 0,
      searchResult: hhActConstants.UNLIMITED,
      searchResultLimit: 0,
      requisitionPerUser: hhActConstants.UNLIMITED,
      requisitionPerUserLimit: 0,
      contactAvailabilityType: hhActConstants.UNLIMITED_ON_DEMAND,
      totalContactRequestCount: 50,
      totalContactRequestPerUserCount: 50,
      searchAvailabilityType: hhActConstants.UNLIMITED,
    };
  } else if (params.licenseType === licenseTypeKeys.ENTERPRISE) {
    data = {
      ...data,
      licenseType: licenseTypeKeys.ENTERPRISE,
      moduleName: [
        {
          id: MODULES.TALENT_SOURCING,
          name: MODULE_LICENSE[MODULES.TALENT_SOURCING],
        },
      ],
      activationDate: moment(),
      licensedUserCount: 1,
      searchesPerDay: hhActConstants.UNLIMITED,
      searchesPerDayLimit: 0,
      searchResult: hhActConstants.UNLIMITED,
      searchResultLimit: 0,
      requisitionPerUser: hhActConstants.UNLIMITED,
      requisitionPerUserLimit: 0,
      contactAvailabilityType: hhActConstants.UNLIMITED_ON_DEMAND,
      totalContactRequestCount: 1200,
      totalContactRequestPerUserCount: 1200,
      searchAvailabilityType: hhActConstants.UNLIMITED,
      atsExportEnabled: true,
      csvExportEnabled: true,
      diversityEnabled: true,
      bulkEmailEnabled: true,
      securityLicenseEnabled: true,
      autoDialerEnabled: true,
      autoDialerUserCount: 1,
    };
  } else {
    data = {
      activationDate: moment(),
    };
  }

  return data;
};
