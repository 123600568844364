import _pick from "lodash.pick";

import { atsOptions, configSteps, atsStepLabels } from "@config/ats";

const stepDetails = {
  [atsOptions.GREENHOUSE]: (config) => {
    const completedSteps = [];
    const enabledSteps = [configSteps.SELECT_ATS];

    if (config.atsType) {
      completedSteps.push(configSteps.SELECT_ATS);
      enabledSteps.push(configSteps.AUTH);
    }

    if (config.apiKey) {
      completedSteps.push(configSteps.AUTH, configSteps.WEBHOOK);
      enabledSteps.push(configSteps.WEBHOOK, configSteps.USER_MAPPING);
    }

    if (config?.users?.length) {
      completedSteps.push(configSteps.USER_MAPPING);
      enabledSteps.push(configSteps.ACTIVATION);
    }

    if (config.isActivated) {
      completedSteps.push(configSteps.ACTIVATION);
    }

    return {
      completed: completedSteps,
      enabled: enabledSteps,
      detail: _pick(atsStepLabels, [
        configSteps.SELECT_ATS,
        configSteps.AUTH,
        configSteps.WEBHOOK,
        configSteps.USER_MAPPING,
        configSteps.ACTIVATION,
      ]),
    };
  },
  [atsOptions.LEVER]: (config) => {
    const completedSteps = [];
    const enabledSteps = [configSteps.SELECT_ATS];

    if (config.atsType) {
      completedSteps.push(configSteps.SELECT_ATS);
      enabledSteps.push(configSteps.AUTH);
    }

    if (config.apiKey) {
      completedSteps.push(configSteps.AUTH, configSteps.WEBHOOK);
      enabledSteps.push(configSteps.WEBHOOK, configSteps.USER_MAPPING);
    }

    if (config.users.length) {
      completedSteps.push(configSteps.USER_MAPPING);
      enabledSteps.push(configSteps.ACTIVATION);
    }

    if (config.isActivated) {
      completedSteps.push(configSteps.ACTIVATION);
    }

    return {
      completed: completedSteps,
      enabled: enabledSteps,
      detail: _pick(atsStepLabels, [
        configSteps.SELECT_ATS,
        configSteps.AUTH,
        configSteps.WEBHOOK,
        configSteps.USER_MAPPING,
        configSteps.ACTIVATION,
      ]),
    };
  },
};

const getStepDetails = (ats, config) => {
  const atsKey = atsOptions[ats] ? ats : atsOptions.LEVER;
  const func = stepDetails[atsKey];
  return func ? func(config) : {};
};

const validateStep = (currentStep, config) => {
  const { detail, completed } = getStepDetails(config.atsType, config);
  const steps = Object.keys(detail);
  const stepIndex = steps.findIndex((step) => currentStep === step);

  if (stepIndex === 0) {
    return true;
  } else {
    return completed.includes(steps[stepIndex - 1]);
  }
};

export default {
  getStepDetails,
  validateStep,
};
