import { takeLatest, all, put, select } from "redux-saga/effects";
import { handleApiError } from "@helpers/collection";

import resource from "../../config/resource";
import { SUCCESS, FAIL, LOADING } from "../../config/constants";
import * as actions from "../actions/communication";
import platformApi from "@services/platform-api/";
import actionTypes from "@store/actions/communication/actionTypes";
import * as commsSelector from "../selectors/communication";

function* startGetList(args) {
  const { queryParams } = args;

  yield put(
    actions.setGetCommunications({
      communications: {
        data: [],
      },
      getList: {
        status: LOADING,
        error: null,
      },
    })
  );

  const state = {};
  const payload = {
    page: (queryParams.page ?? 1) - 1,
    size: queryParams.size,
    requisition: queryParams.requisition?.[0],
    candidateName: queryParams.candidate,
    status: queryParams.status,
    type: queryParams.type,
  };

  try {
    const { data } = yield platformApi.post(
      resource.communicationHistory,
      payload
    );
    state.communications = {
      data: data.content,
      total: data.totalResult,
    };
    state.getList = {
      status: SUCCESS,
      error: null,
    };
  } catch (e) {
    handleApiError(e, () => {
      state.getList = {
        status: FAIL,
        error: null,
      };
    });
  }

  yield put(actions.setGetCommunications(state));
}

function* selectCandidates(args) {
  const { ids, toggle = true } = args;
  const selectedCandidates = yield select(commsSelector.selectedCandidates);
  let nextIds = [...selectedCandidates];

  if (toggle) {
    yield ids.forEach((id) => {
      const index = nextIds.indexOf(id);
      if (index < 0) {
        nextIds.push(id);
      } else {
        nextIds.splice(index, 1);
      }
    });
  } else {
    nextIds = ids;
  }

  yield put(actions.setSelectedCandidates(nextIds));
}

function* startBulkCancel(args) {
  const { ids } = args;

  yield put(
    actions.setBulkCancel({
      bulkCancel: {
        status: LOADING,
        error: null,
      },
    })
  );

  const state = {};
  const params = {
    ids: ids.join(),
  };

  try {
    yield platformApi.patch(
      resource.autoDialer + "/bulk/cancel",
      {},
      { params }
    );
    state.bulkCancel = {
      status: SUCCESS,
      error: null,
    };
  } catch (e) {
    handleApiError(e, () => {
      state.bulkCancel = {
        status: FAIL,
        error: null,
      };
    });
  }

  yield put(actions.setBulkCancel(state));
}

export default function* sagas() {
  yield all([takeLatest(actionTypes.COMM_START_GET_LIST, startGetList)]);
  yield all([takeLatest(actionTypes.COMM_START_BULK_CANCEL, startBulkCancel)]);
  yield all([takeLatest(actionTypes.COMM_SELECT_CANDIDATES, selectCandidates)]);
}
