import resource from "../../../../people-legal/config/resource";
import downloadListLegal from "./downloadList";

export const downloadList = (mock) => {
  mock
    .onGet(new RegExp(`${resource.people}/download*`))
    .reply(200, downloadListLegal);
};

export const downloadExport = (mock) => {
  const response = `"Download Name", ${JSON.stringify({ name: "Test" })}`;
  mock
    .onGet(new RegExp(`${resource.people}/download/[0-9]`))
    .reply(200, response, {
      "content-disposition": "HH_12344_SL_021323_21 28' 123''.csv",
    });
};

export default [
  downloadExport,
  //downloadList
];
