import React from "react";
import { message } from "antd";
import yup from "@services/yup";
import _pick from "lodash.pick";
import _get from "lodash.get";

import resource from "@config/resource";
import platformApiSvc from "@services/platform-api/";
import { ErrorMsgNotif } from "@components/shared/MessageNotif/MessageNotif";
import { validationSchema as searchSchema } from "../config/search";
import {
  validationSchema as criteriaSchema,
  defaultCriteria,
} from "../config/criteria";
import { getTotalCriteriaCount } from "../store/selectors/criteria";
import { defaultPagination, pageSizeOptions } from "../../config/collection";

const searchCriteriaSchema = yup.object().shape(criteriaSchema);
const searchDetailSchema = yup.object().shape({ ...searchSchema });
searchDetailSchema.concat(searchCriteriaSchema);

const sortFieldOrder = {
  lastRun: "lastRun",
  name: "name",
};

export const validate = (searchDoc) =>
  searchDetailSchema.validate(searchDoc, {
    abortEarly: false,
    stripUnknown: true,
  });

export const validateCriteria = (criteria) =>
  searchCriteriaSchema.validate(criteria, {
    abortEarly: false,
    stripUnknown: true,
  });

export const validateCriteriaField = (key, criteria) =>
  searchCriteriaSchema.validateSyncAt(key, criteria);

export const hasDefinedCriteria = (criteria, notif = true) => {
  const hasCriteria = getTotalCriteriaCount(criteria);

  if (!hasCriteria && notif) {
    message.open({
      content: (
        <ErrorMsgNotif>
          At least 1 search criteria should be selected.
        </ErrorMsgNotif>
      ),
      key: "empty-criteria",
    });
  }

  return hasCriteria;
};

export const save = (searchDoc) => {
  const { id = "", parameters } = searchDoc;
  const request = {
    method: id ? "patch" : "post",
    url: `${resource.candidateSearches}/${id}`,
    data: {
      ...searchDoc,
      parameters: mapCriteriaToSave(parameters),
    },
  };
  return platformApiSvc(request);
};

export const get = (id, options) => {
  return platformApiSvc.get(resource.candidateSearches + "/" + id, options);
};

export const fetchSearchList = async (params) => {
  const {
    page = defaultPagination.page,
    size = pageSizeOptions[1],
    searchName,
    status = "ALL",
  } = params;
  const _page = isNaN(page) ? 0 : +page - 1;
  // const sort = "sort=name,desc&sort=lastRun,desc";

  let newSort = "?";
  Object.keys(sortFieldOrder).forEach((key, index) => {
    const fieldSorting = params[key];
    if (fieldSorting) {
      newSort = `${newSort}sort=${sortFieldOrder[key]},${fieldSorting}`;
    }
    if (index !== Object.keys(sortFieldOrder).length - 1) {
      newSort = `${newSort}&`;
    }
  });

  return await platformApiSvc.get(
    `${resource.candidateSearches}/search/findByNameLikeV2${newSort}`,
    {
      params: {
        size,
        page: _page,
        name: searchName,
        inclusions: status,
      },
    }
  );
};

export const mapCriteriaToSave = (criteria, filters) => {
  const {
    companies,
    companyIndustries,
    companySpecialization,
    companySizes,
    companyType,
    locations,
    // locationType,
    jobTitles,
    educationDegree,
    educationMajors,
    ethnicity,
    gender,
    veteran,
    excludeFromExportedCandidates,
    seniorityExperience,
    employmentDuration,
    previousCompanyOnly,
    includeRelatedDepartment,
  } = criteria;
  const extractId = ({ id }) => id;
  const extractItemProps = ({ id, name }) => ({ id, name });
  let refineFilters = {};

  if (filters) {
    refineFilters = {
      contactFilters: filters.contactInfo || [],
    };
  }

  return {
    educationDegree: educationDegree?.id || "",
    educationMajors: educationMajors.map(extractId),
    ethnicity: ethnicity.map(extractId),
    excludeFromExportedCandidates,
    gender: gender.map(extractId),
    locations,
    // locationType,
    jobTitles,
    companies: companies.map(extractItemProps),
    companyIndustries: {
      industries: companyIndustries.industries.map(extractId),
    },
    companySpecialization: {
      specializations: companySpecialization.specializations,
    },
    companyType,
    companySizes: {
      sizes: companySizes.sizes.map(extractId),
    },
    seniorityExperience: {
      ...seniorityExperience,
      seniorities: seniorityExperience.seniorities.map(extractId),
    },
    employmentDuration,
    veteran,
    previousCompanyOnly,
    includeRelatedDepartment,
    ...refineFilters,
  };
};

export const getCriteria = ({ parameters, extras }) => {
  const extractItemProps = (item) => _pick(item, ["id", "name"]);
  const getParameterValue = (data, path) =>
    _get(data, path) || _get(defaultCriteria, path);
  const getBooleanValue = (data, path) =>
    _get(data, path, _get(defaultCriteria, path));

  const criteria = {
    companies: (parameters?.companies || []).map(extractItemProps),
    employmentDuration: getParameterValue(parameters, "employmentDuration"),
    companyIndustries: {
      industries: (extras?.industries || []).map(extractItemProps),
    },
    companySpecialization: {
      specializations: parameters?.companySpecialization?.specializations || [],
    },
    companySizes: {
      sizes: (extras?.companySizes || []).map(extractItemProps),
    },
    companyType: {
      type: getParameterValue(parameters, "companyType.type"),
    },
    // locationType: getParameterValue(parameters, "locationType"),
    locations: getParameterValue(parameters, "locations").map((item) =>
      _pick(item, ["location", "option", "distance"])
    ),
    jobTitles: getParameterValue(parameters, "jobTitles"),
    seniorityExperience: {
      ...getParameterValue(parameters, "seniorityExperience"),
      seniorities: (extras?.seniorities || []).map(extractItemProps),
    },
    educationDegree: extras.educationDegree || defaultCriteria.educationDegree,
    educationMajors: getParameterValue(extras, "educationMajors").map(
      extractItemProps
    ),
    ethnicity: getParameterValue(extras, "ethnicity").map(extractItemProps),
    gender: getParameterValue(extras, "gender").map(extractItemProps),
    veteran: getParameterValue(parameters, "veteran"),
    excludeFromExportedCandidates: getBooleanValue(
      parameters,
      "excludeFromExportedCandidates"
    ),
    previousCompanyOnly: getBooleanValue(parameters, "previousCompanyOnly"),
    includeRelatedDepartment: getBooleanValue(
      parameters,
      "includeRelatedDepartment"
    ),
  };

  return criteria;
};

export const refineFiltersFromSearchParams = () => {
  return {
    contactInfo: [],
  };
};

export const refineFiltersFromQueryParams = (data) => {
  return {
    contactInfo: data?.contactInfo || [],
  };
};

export const refineFiltersToQueryParams = (data) => {
  return {
    contactInfo: data?.contactInfo || [],
  };
};

export const disableSearchNextPage = (current, pageSize) =>
  current * pageSize >= 1000;
