import actionTypes from "../actions/company/actionTypes";
import { IDLE } from "@config/constants";

const defaultState = {
  data: {},
  getDetail: {
    status: IDLE,
    error: null,
  },
};

export default (state = defaultState, action = {}) => {
  const { type, ...args } = action;
  switch (type) {
    case actionTypes.COMPANY_SET_DETAIL:
      return {
        ...state,
        data: args.data,
        getDetail: {
          ...state.getDetail,
          ...args.getDetail,
        },
      };
    default:
      return state;
  }
};
