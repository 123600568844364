import yup from "@services/yup";
import { stageCountFilter } from "@config/collection";
import { getMandatoryFields } from "@helpers/criteria";

export const excludedSearchParamsKeys = ["lastRefresh", "sort", "stageFilter"];

export const exprItem = {
  function: null,
  range: {
    min: 0,
    max: 0,
  },
};

export const locationItem = {
  location: null,
  option: null,
  distance: null,
};

export const sortFieldKey = {
  RELEVANCY: "RELEVANCY",
  JOB_TITLE: "JOB_TITLE",
  MUST_HAVES: "MUST_HAVES",
};

export const sortFieldLabel = {
  [sortFieldKey.RELEVANCY]: "Relevancy",
  [sortFieldKey.JOB_TITLE]: "Job Title",
  [sortFieldKey.MUST_HAVES]: "Must Haves",
};

export const defaultSort = {
  field: "RELEVANCY",
  order: "asc",
};

export const defaultCriteria = {
  mustHave: [],
  likeToHave: [],
  totalYearsOfExp: {
    min: 3,
    max: 15,
  },
  currentTenure: {
    min: 1,
    max: 10,
  },
  averageTenure: {
    min: 1,
    max: 10,
  },
  seniorityExp: {
    seniorities: [],
    range: {
      min: 0,
      max: 0,
    },
    currentOnly: false,
  },
  functionExp: [],
  productDomain: "",
  targetCompanies: {
    companies: [],
    currentOnly: false,
  },
  excludedCompanies: {
    companies: [],
    currentOnly: false,
  },
  companySizes: {
    sizes: [],
    currentOnly: false,
  },
  companyType: {
    type: null,
    currentOnly: false,
  },
  companyIndustries: {
    industries: [],
    currentOnly: false,
  },
  locations: [],
  excludeFromActiveRequisition: {
    currentOnly: true,
    stage: null,
  },
  excludeFromExportedCandidates: true,
  educationDegree: {
    id: "",
    name: "",
  },
  educationMajors: [],
  stageFilter: stageCountFilter.ALL,
  veteran: false,
  ethnicity: [],
  gender: [],
  veteran: false,
  jobTitles: [],
  companySpecialization: {
    specializations: [],
    currentOnly: false,
  },
  clearanceLevels: [],
  clearanceAddOns: [],
  clearanceInclusions: {
    inferred: false,
    eligible: false,
  },
};

const strLen = 100;
const stringSchema = yup.string().max(strLen);
const companySchema = yup.object().shape({
  id: stringSchema,
  name: stringSchema,
});
const companyNullableIdSchema = yup.object().shape({
  id: yup.string().nullable(),
  name: stringSchema,
});
const sliderRangeSchema = yup
  .number()
  .min(0)
  .max(20)
  .nullable();
const tenureLimitSchema = yup
  .number()
  .min(0)
  .max(10)
  .nullable();
const expSchema = yup.object({
  function: stringSchema.nullable(),
  range: yup.object({
    min: sliderRangeSchema,
    max: sliderRangeSchema,
  }),
});
const keywordsSchema = yup.array().of(
  yup.object().shape({
    id: stringSchema.nullable(),
    name: stringSchema,
    category: stringSchema.nullable(),
    skillCategory: stringSchema.nullable(),
  })
);
const multiSelectSchema = yup.object({
  id: stringSchema,
  name: stringSchema,
});

export const searchNameSchema = {
  searchName: yup
    .string()
    .max(
      150,
      "You have exceeded the maximum character limit. Please modify your search name."
    )
    .required("You must enter a name for your search")
    .trim()
    .label("Search Name"),
};

const arrayOfRecordsSchema = yup.array().of(
  yup.object().shape({
    id: stringSchema,
    name: stringSchema,
  })
);

export const validationSchema = {
  mandatoryFields: keywordsSchema.test("mandatory-fields", (value, ctx) => {
    const { jobTitles, mustHave, locations, targetCompanies } = ctx.parent;
    const { hhInternalUser } = ctx?.options?.context || {};
    let hasMandatoryField = jobTitles?.length > 0 || mustHave?.length > 0;

    if (hhInternalUser) {
      hasMandatoryField =
        hasMandatoryField ||
        targetCompanies?.companies?.length > 0 ||
        locations?.length > 0;
    }

    return (
      hasMandatoryField ||
      ctx.createError({
        message: `Please provide value for at least one of these fields: ${getMandatoryFields(
          hhInternalUser
        )}`,
      })
    );
  }),
  mustHave: keywordsSchema,
  likeToHave: keywordsSchema,
  locations: yup.array().of(
    yup.object().shape({
      location: stringSchema,
      option: stringSchema,
      distance: yup.mixed().nullable(),
    })
  ),
  totalYearsOfExp: yup.object({
    min: sliderRangeSchema,
    max: yup
      .number()
      .max(20)
      .moreThan(0, "Total Years of Experience is required"),
  }),
  currentTenure: yup.object({
    min: tenureLimitSchema,
    max: tenureLimitSchema,
  }),
  averageTenure: yup.object({
    min: tenureLimitSchema,
    max: tenureLimitSchema,
  }),
  seniorityExp: yup.object({
    seniorities: yup.array(),
    range: yup.object({
      min: sliderRangeSchema,
      max: sliderRangeSchema,
    }),
    currentOnly: yup.boolean(),
  }),
  functionExp: yup.array().of(expSchema),
  productDomain: stringSchema.nullable(),
  companyIndustries: yup.object({
    industries: yup.array(),
    currentOnly: yup.boolean(),
  }),
  companySpecialization: yup.object({
    specializations: yup.array().of(companyNullableIdSchema),
    currentOnly: yup.boolean(),
  }),
  targetCompanies: yup.object({
    companies: yup.array().of(companySchema),
    currentOnly: yup.boolean(),
  }),
  excludedCompanies: yup.object({
    companies: yup.array().of(companySchema),
    currentOnly: yup.boolean(),
  }),
  companySizes: yup.object({
    sizes: yup.array().of(multiSelectSchema),
    currentOnly: yup.boolean(),
  }),
  companyType: yup.object({
    type: stringSchema.nullable(),
    currentOnly: yup.boolean(),
  }),
  educationDegree: yup.object().shape({
    id: stringSchema,
    name: stringSchema,
  }),
  educationMajors: yup.array().of(
    yup.object().shape({
      id: stringSchema,
      name: stringSchema,
    })
  ),
  excludeFromActiveRequisition: yup.object({
    currentOnly: yup.boolean(),
    stage: stringSchema.nullable(),
  }),
  excludeFromExportedCandidates: yup.boolean(),
  lastRefresh: yup.string().nullable(),
  stageFilter: yup.mixed().oneOf(Object.keys(stageCountFilter)),
  ethnicity: arrayOfRecordsSchema,
  gender: arrayOfRecordsSchema,
  veteran: yup.boolean(),
  jobTitles: yup.array().of(yup.string().nullable()),
  clearanceLevels: arrayOfRecordsSchema,
  clearanceAddOns: arrayOfRecordsSchema,
  clearanceInclusions: yup.object({
    inferred: yup.boolean(),
    eligible: yup.boolean(),
  }),
};

export const tabKeys = {
  WEIGHTED: "WEIGHTED",
  ENFORCED: "ENFORCED",
};

export const sections = {
  JTS: {
    label: "Job Title & Skills",
    id: "jts",
  },
  LOCATION: {
    label: "Location",
    id: "location",
  },
  EXPERIENCE: {
    label: "Experience",
    id: "experience",
  },
  COMPANY_PROFILE: {
    label: "Company Profile",
    id: "company-profile",
  },
  EDUCATION: {
    label: "Education",
    id: "education",
  },
  SECURITY_CLEARANCE: {
    label: "Security Clearance",
    id: "security-clearance",
  },
  DIVERSITY: {
    label: "Diversity",
    id: "diversity",
  },
  CANDIDATE_EXCLUSIONS: {
    label: "Candidate Exclusions",
    id: "candidate-exclusions",
  },
};

export const defaultRefineFilters = {
  mustHaves: [],
  likeToHaves: [],
  likelyToJump: false,
  seniorities: [],
  industries: [],
  companySizes: [],
  educationDegrees: [],
  educationMajors: [],
  ethnicity: [],
  gender: [],
  specializations: [],
  clearanceLevels: [],
  clearanceAddOns: [],
};

export const refineFilterMapKeys = {
  mustHaves: "rfMustHaves",
  likeToHaves: "rfLikeToHaves",
  likelyToJump: "rfLikelyToJump",
  seniorities: "rfSeniorities",
  industries: "rfIndustries",
  companySizes: "rfCompanySizes",
  educationDegrees: "rfEducationDegrees",
  educationMajors: "rfEducationMajors",
  ethnicity: "rfEthnicity",
  gender: "rfGender",
  specializations: "rfSpecialization",
  clearanceLevels: "rfClearanceLevels",
  clearanceAddOns: "rfClearanceAddOns",
  refinedSearch: "rfRefinedSearch",
};

export const companyTypeKeys = {
  PRIVATE: "PRIVATE",
  PUBLIC: "PUBLIC",
};

export const companyTypeOptions = {
  [companyTypeKeys.PRIVATE]: "Private",
  [companyTypeKeys.PUBLIC]: "Public",
};

export default {
  stringSchema,
  validationSchema,
  defaultCriteria,
  exprItem,
  excludedSearchParamsKeys,
  tabKeys,
};
