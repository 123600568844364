import React, { useEffect, useContext } from "react";
import { Icon, message } from "antd";
import Loadable from "react-loadable";
import classnames from "classnames";
import { useLocation } from "react-router-dom";
import { ErrorMsgNotif } from "@components/shared/MessageNotif/MessageNotif";
import Logger from "@services/logger";

export const SectionLoader = () => (
  <Icon
    spin
    type="loading-3-quarters"
    className={classnames(
      "hh-fc-prime-navy-l1",
      "hh-circular-progress-md d-block mt-5"
    )}
  />
);

export const PageLoader = () => (
  <div className="root-preloader">
    <div className="logo">{process.env.APP_NAME}</div>
    <Icon
      spin
      type="loading-3-quarters"
      className="hh-circular-progress-md mt-2"
    />
  </div>
);

class LoaderData {
  retryCount = 0;
  key = "";

  incrementRetry = () => {
    this.retryCount += 1;
  };

  reset = (key) => {
    this.key = key;
    this.retryCount = 0;
  };
}

const LoaderContext = React.createContext(new LoaderData());
export const Loader = ({ error, retry, type }) => {
  const loaderContext = useContext(LoaderContext);
  const location = useLocation();

  const retryHandler = () => {
    loaderContext.incrementRetry();
    retry();
  };

  const msgProps = {
    key: "CHUNK_FAILED",
    content: (
      <ErrorMsgNotif>
        Something went wrong. Please try reloading the page.
      </ErrorMsgNotif>
    ),
    duration: 0,
  };

  useEffect(() => {
    loaderContext.reset(location.key);

    return () => {
      message.destroy();
    };
  }, []);

  useEffect(() => {
    if (error) {
      console.log(error);
      if (loaderContext.retryCount < 2) {
        setTimeout(retryHandler, 2000);
      } else {
        message.open(msgProps);
      }
      Logger.captureException(error);
    }
  }, [error]);

  return type === "section" ? <SectionLoader /> : <PageLoader />;
};

const ComponentLoader = ({ type = "section", ...args }) => {
  return Loadable({
    ...args,
    loading(props) {
      return <Loader {...props} type={type} />;
    },
  });
};

export default ComponentLoader;
