import yup from "@services/yup";

export const validationSchema = {
  searchName: yup
    .string()
    .max(
      150,
      "You have exceeded the maximum character limit. Please modify your search name."
    )
    .required("You must enter a name for your search")
    .trim()
    .label("Search Name"),
};
