import actionTypes from "./actionTypes";

export const getConfig = (companyId) => {
  return {
    companyId,
    type: actionTypes.AUTO_DIALER_GET_CONFIG,
  };
};

export const setConfig = (params) => {
  return {
    config: params.config,
    getConfig: params.getConfig,
    steps: params.steps,
    type: actionTypes.AUTO_DIALER_SET_CONFIG,
  };
};

export const saveConfig = (companyId, config, step) => {
  return {
    config,
    companyId,
    step,
    type: actionTypes.AUTO_DIALER_SAVE_CONFIG,
  };
};

export const setSaveConfig = ({ config, saveConfig, steps }) => {
  return {
    config,
    saveConfig,
    steps,
    type: actionTypes.AUTO_DIALER_SET_SAVE_CONFIG,
  };
};

export const resetSaveConfig = () => {
  return {
    type: actionTypes.AUTO_DIALER_RESET_SAVE_CONFIG,
  };
};

export const saveMessageTemplate = (data, companyId) => {
  return {
    data,
    companyId,
    type: actionTypes.AUTO_DIALER_SAVE_MESSAGE_TEMPLATE,
  };
};

export const setSaveMessageTemplate = (args) => {
  return {
    saveMessageTemplate: args.saveMessageTemplate,
    type: actionTypes.AUTO_DIALER_SET_SAVE_MESSAGE_TEMPLATE,
  };
};

export const resetSaveMessageTemplate = () => {
  return {
    type: actionTypes.AUTO_DIALER_RESET_SAVE_MESSAGE_TEMPLATE,
  };
};

export const deleteMessageTemplate = (id) => {
  return {
    id,
    type: actionTypes.AUTO_DIALER_DELETE_MESSAGE_TEMPLATE,
  };
};

export const setDeleteMessageTemplate = (args) => {
  return {
    deleteMessageTemplate: args.deleteMessageTemplate,
    type: actionTypes.AUTO_DIALER_SET_DELETE_MESSAGE_TEMPLATE,
  };
};

export const resetDeleteMessageTemplate = () => {
  return {
    type: actionTypes.AUTO_DIALER_RESET_DELETE_MESSAGE_TEMPLATE,
  };
};

export const getUserMessageTemplates = (companyId) => {
  return {
    companyId,
    type: actionTypes.AUTO_DIALER_GET_USER_MESSAGE_TEMPLATES,
  };
};

export const setUserMessageTemplates = (params) => {
  return {
    userMessageTemplates: params.userMessageTemplates,
    getUserMessageTemplates: params.getUserMessageTemplates,
    type: actionTypes.AUTO_DIALER_SET_USER_MESSAGE_TEMPLATES,
  };
};

export const startSaveUserMessageTemplate = (data, companyId) => {
  return {
    data,
    companyId,
    type: actionTypes.AUTO_DIALER_START_SAVE_USER_MESSAGE_TEMPLATE,
  };
};

export const setSaveUserMessageTemplate = (args) => {
  return {
    saveUserMessageTemplate: args.saveUserMessageTemplate,
    type: actionTypes.AUTO_DIALER_SET_SAVE_USER_MESSAGE_TEMPLATE,
  };
};

export const startBulkSchedule = (args) => {
  return {
    payload: args.payload,
    type: actionTypes.AUTO_DIALER_START_BULK_SCHEDULE,
  };
};

export const setBulkSchedule = (args) => {
  return {
    bulkSchedule: args.bulkSchedule,
    type: actionTypes.AUTO_DIALER_SET_BULK_SCHEDULE,
  };
};

export const resetBulkSchedule = () => {
  return {
    type: actionTypes.AUTO_DIALER_RESET_BULK_SCHEDULE,
  };
};
