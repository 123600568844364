import actionTypes from "./actionTypes";

export const getDetail = (args) => {
  const { companyId, params } = args;
  return {
    params,
    companyId,
    type: actionTypes.COMPANY_GET_DETAIL,
  };
};

export const setDetail = (params) => {
  const { data, getDetail } = params;
  return {
    data,
    getDetail,
    type: actionTypes.COMPANY_SET_DETAIL,
  };
};
