export const ROLE_MENU_ACCESS_DASHBOARD = "ROLE_MENU_ACCESS_DASHBOARD";
export const ROLE_INTERNAL_ADMIN = "ROLE_INTERNAL_ADMIN";
export const ROLE_MENU_ACCESS_MY_TASKS = "ROLE_MENU_ACCESS_MY_TASKS";
export const ROLE_ADMINISTRATOR = "ROLE_ADMINISTRATOR";
export const ROLE_MENU_ACCESS_SEARCH = "ROLE_MENU_ACCESS_SEARCH";
export const ROLE_MENU_ACCESS_HH_ADMIN = "ROLE_MENU_ACCESS_HH_ADMIN";
export const ROLE_APP_SECURITY_ADMIN = "ROLE_APP_SECURITY_ADMIN";
export const ROLE_MENU_ACCESS_ACCOUNT_ADMIN = "ROLE_MENU_ACCESS_ACCOUNT_ADMIN";
export const ROLE_MENU_ACCESS_USER_ADMIN = "ROLE_MENU_ACCESS_USER_ADMIN";
export const ROLE_MENU_ACCESS_MY_PROFILE = "ROLE_MENU_ACCESS_MY_PROFILE";
export const ROLE_MENU_ACCESS_REQUISITIONS = "ROLE_MENU_ACCESS_REQUISITIONS";
export const ROLE_RECRUITER = "ROLE_RECRUITER";
export const ROLE_MENU_ACCESS_REQUISITIONS_HIRING_MANAGER =
  "ROLE_MENU_ACCESS_REQUISITIONS_HIRING_MANAGER";
export const ROLE_ADMIN = "ROLE_ADMIN";
export const ROLE_MENU_ACCESS_APP_ADMIN = "ROLE_MENU_ACCESS_APP_ADMIN";
export const ROLE_MENU_ACCESS_REQS = "ROLE_MENU_ACCESS_REQS";
export const ROLE_MENU_ACCESS_TASKS = "ROLE_MENU_ACCESS_TASKS";
export const ROLE_MENU_ACCESS_LOOK_UP = "ROLE_MENU_ACCESS_LOOK_UP";

export const ROLE_MENU_ACCESS_SEARCH_LEGAL = "ROLE_MENU_ACCESS_SEARCH_LEGAL";
export const ROLE_MENU_ACCESS_DOWNLOADS = "ROLE_MENU_ACCESS_DOWNLOADS";
export const ROLE_MENU_ACCESS_COMMUNICATION = "ROLE_MENU_ACCESS_COMMUNICATION";
export const ROLE_MENU_ACCESS_FULFILL_CONTACT_REQUEST =
  "ROLE_MENU_ACCESS_FULFILL_CONTACT_REQUEST";

export default {
  ROLE_MENU_ACCESS_DASHBOARD,
  ROLE_INTERNAL_ADMIN,
  ROLE_MENU_ACCESS_MY_TASKS,
  ROLE_ADMINISTRATOR,
  ROLE_MENU_ACCESS_SEARCH,
  ROLE_MENU_ACCESS_HH_ADMIN,
  ROLE_APP_SECURITY_ADMIN,
  ROLE_MENU_ACCESS_ACCOUNT_ADMIN,
  ROLE_MENU_ACCESS_USER_ADMIN,
  ROLE_MENU_ACCESS_MY_PROFILE,
  ROLE_MENU_ACCESS_REQUISITIONS,
  ROLE_RECRUITER,
  ROLE_MENU_ACCESS_REQUISITIONS_HIRING_MANAGER,
  ROLE_ADMIN,
  ROLE_MENU_ACCESS_APP_ADMIN,
  ROLE_MENU_ACCESS_REQS,
};
