import actionTypes from '../actions/app/actionTypes';

const defaultState = {
  resources: {},
  showSidePanel: true,
  saveChangesPrompt: {
    visible: false
  },
  status: ''
};

export default (state = defaultState, action = {}) => {
  const { type, ...args } = action;

  switch (type) {
    case (actionTypes.APP_INIT_START):
      return {
        ...state,
        status: 'loading'
      };
    case (actionTypes.APP_INIT_COMPLETE):
      return {
        ...state,
        ...args
      };
    case (actionTypes.TOGGLE_SIDEPANEL):
      return {
        ...state,
        showSidePanel: !state.showSidePanel
      };
    case (actionTypes.SET_SAVE_CHANGES_PROMPT):
      return {
        ...state,
        saveChangesPrompt: {
          ...state.saveChangesPrompt,
          ...args
        }
      };
    default:
      return state;
  }
};