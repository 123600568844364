import _cloneDeep from "lodash.clonedeep";

import { IDLE } from "@config/constants";
import actionTypes from "../actions/communication/actionTypes";

export const defaultState = {
  communications: {
    data: [],
    total: 0,
  },
  selectedCandidates: [],
  getList: {
    status: IDLE,
    error: null,
  },
  bulkCancel: {
    status: IDLE,
    error: null,
  },
};

export default (state = _cloneDeep(defaultState), action = {}) => {
  const { type, ...args } = action;

  switch (type) {
    case actionTypes.COMM_SET_GET_LIST:
      return {
        ...state,
        communications: { ...state.communications, ...args.communications },
        getList: args.getList,
      };
    case actionTypes.COMM_SET_BULK_CANCEL:
      return {
        ...state,
        bulkCancel: { ...state.bulkCancel, ...args.bulkCancel },
        getList: args.getList,
      };
    case actionTypes.COMM_SET_SELECTED_CANDIDATES:
      return {
        ...state,
        selectedCandidates: args.selectedCandidates,
      };
    case actionTypes.COMM_RESET:
      return _cloneDeep(defaultState);
    default:
      return state;
  }
};
