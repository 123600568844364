import { createSelector } from "reselect";

export const selectRequisition = (state) => state.requisition;

const selectUserExportLimit = (state) => state?.exportPerUserCount;
const selectUserExportCount = (state) => state?.currentExportUserCount;
const selectCompanyExportLimit = (state) => state?.totalExportCount;
const selectCompanyExporCount = (state) => state?.currentExportCompanyCount;

export const selectUserExportCredit = createSelector(
  selectUserExportLimit,
  selectUserExportCount,
  (exportLimit, exportCount) => exportLimit - exportCount
);

export const selectCompanyExportCredit = createSelector(
  selectCompanyExportLimit,
  selectCompanyExporCount,
  (exportLimit, exportCount) => exportLimit - exportCount
);

export const selectRequisitionDetails = createSelector(
  selectRequisition,
  (requisition) => {
    return requisition?.details;
  }
);

export const selectRequisitionQueryParams = createSelector(
  selectRequisition,
  (requisition) => {
    return requisition?.queryParameters;
  }
);
