import yup from '@services/yup';

export const EMAIL = 'EMAIL';
export const PHONE = 'PHONE';
export const LINKEDIN = 'LINKEDIN';
export const TEXT = 'TEXT';
export const ADMIN = 'ADMIN';
export const OPEN = 'OPEN';
export const ON_HOLD = 'ON_HOLD';
export const CANCELED = 'CANCELED';
export const COMPLETED = 'COMPLETED';

export const status = {
  [OPEN]: 'Open',
  [ON_HOLD]: 'On Hold',
  [CANCELED]: 'Canceled',
  [COMPLETED]: 'Completed'
};

export const types = {
  [ADMIN]: 'Admin',
  [EMAIL]: 'Email',
  [LINKEDIN]: 'LinkedIn',
  [PHONE]: 'Phone',
  [TEXT]: 'Text'
};

export const fieldMapping = {
  title: 'Title',
  type: 'Type',
  owner: 'Owner',
  requisition: 'Requisition',
  candidate: 'Candidate',
  taskDate: 'Task Date',
  status: 'Status',
  notes: 'Notes'
};

export const validationSchema = yup.object({
  firstName: yup.string().max(50).trim().required().label(fieldMapping.firstName)
});

export const defaultDocumentValue = {};

export default {
  types,
  fieldMapping,
  validationSchema
};