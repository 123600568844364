import { takeLatest, all, put } from "redux-saga/effects";

import resource from "@config/resource";
import * as actions from "../actions/company/";
import platformApi from "@services/platform-api/";
import actionTypes from "@store/actions/company/actionTypes";
import { FAIL, LOADING, IDLE } from "@config/constants";

function* getDetail(args) {
  const { companyId, params } = args;

  yield put(
    actions.setDetail({
      data: {},
      getDetail: {
        status: LOADING,
        error: null,
      },
    })
  );

  const state = {
    data: {},
  };

  try {
    const { data } = yield platformApi.get(
      `${resource.companyAccounts}/${companyId}`,
      {
        params,
      }
    );
    state.data = data;
    state.getDetail = {
      status: IDLE,
      error: null,
    };
  } catch (e) {
    state.getDetail = {
      status: IDLE,
      error: FAIL,
    };
  }

  yield put(actions.setDetail(state));
}

export default function* sagas() {
  yield all([takeLatest(actionTypes.COMPANY_GET_DETAIL, getDetail)]);
}
