export const hhInternal = "/hh";

export const module = {
  LEGAL: "legal",
};

export const legal = {
  search: `${module.LEGAL}/search`,
  bookmark: `${module.LEGAL}/bookmark`,
  download: `${module.LEGAL}/download`,
};

export default {
  userAgreement: "/user-agreement",
  dashboard: "/dashboard",
  bookmark: "/bookmark",
  searchEdit: "/search/edit",
  searchList: "/search",
  search: "/search",
  searches: "/searches",
  candidateLookup: "/candidate/lookup",
  userAccount: "/user",
  companyAccount: "/company",
  user: "/user",
  userAdmin: "/user/admin",
  company: "/company",
  viewCompany: "/view-company",
  hhUser: `${hhInternal}/user`,
  hhCompany: `${hhInternal}/company`,
  hhViewCompany: `${hhInternal}/view-company`,
  internalUserAccount: "/internal/user-account",
  securityRole: "/security-role",
  candidate: "/candidate",
  task: "/task",
  requisition: "/requisition",
  licensePlan: "/license-plan",
  candidateProfile: "/candidate/profile",
  candidateViewedBookmarked: "/candidate/view",
  profile: "/my-profile",
  forgotPassword: "/forgot-password",
  changePassword: "/change-password",
  setPassword: "/set-password",
  password: "/password",
  confirmEmail: "/confirm-email",
  unauthorized: "/unauthorized-page-access",
  home: "/",
  login: "/login",
  logout: "/logout",
  maintenance: "/under-maintenance",
  ats: "/ats",
  leverAuth: "/lever-auth",
  autoDialer: "/auto-dialer",
  communication: "/communication",
  fulfillRequest: "/fulfill-requests",
};
