import actionTypes from "../actions/tipboard/actionTypes";

const defaultState = {
  isShown: false,
  message: "",
  linkUrl: "",
  linkPage: "",
  type: "info", // success, info, error and warning
  key: "",
  shown: [],
};

export default (state = defaultState, action = {}) => {
  const { type, ...args } = action;
  switch (type) {
    case actionTypes.TIP_BOARD_TOGGLE:
      if (state.isShown && args && args.message) {
        return {
          ...state,
          isShown: state.isShown,
          message: args.message,
          linkUrl: args.linkUrl,
          linkPage: args.linkPage,
          type: args.type ? args.type : "info",
        };
      }

      return {
        ...state,
        isShown: !state.isShown,
        message: !state.isShown ? args.message : "",
        linkUrl: !state.isShown ? args.linkUrl : "",
        linkPage: !state.isShown ? args.linkPage : "",
        type: !state.isShown && args.type ? args.type : defaultState.type,
      };
    case actionTypes.TIP_BOARD_SET:
      return {
        ...state,
        ...args,
      };
    case actionTypes.TIP_BOARD_RESET:
      return {
        ...defaultState,
      };
    default:
      return state;
  }
};
