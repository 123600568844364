import { takeEvery, all, put } from "redux-saga/effects";
import _get from "lodash.get";
import _merge from "lodash.merge";

import apiResource from "@config/resource";
import { getDataStart, getDataComplete } from "@store/actions/resource/";
import platormApiSvc from "@services/platform-api/";
import actionTypes from "@store/actions/resource/actionTypes";

function* getData(args) {
  const { key, requestOpts, extractPath } = args;
  yield put(getDataStart(key));
  let data = {};

  switch (key) {
    case "companySize":
      data = yield getGenericOptions();
      break;
    default:
      data = yield getResourceData(key, requestOpts, extractPath);
      break;
  }
  yield put(getDataComplete(key, data));
}

function* getGenericOptions() {
  let res;
  try {
    const { data } = yield platormApiSvc.get(apiResource.genericOptions);
    res = {
      data: data.result,
      status: "",
      notif: {
        message: "",
        type: "",
      },
    };
  } catch (e) {
    res = {
      data: [],
      status: "fail",
      notif: {
        type: "error",
        message: e.apiMessage,
      },
    };
  }

  return res;
}

function* getResourceData(key = "", requestOpts, extractPath) {
  extractPath = extractPath === undefined ? `_embedded.${key}` : extractPath;
  const defaultData = [];
  let res;

  try {
    const apiParams = {
      method: "get",
      url: apiResource[key],
    };

    if (requestOpts !== undefined) {
      _merge(apiParams, requestOpts);
    }

    const { data } = yield platormApiSvc(apiParams);
    const resourceData = extractPath
      ? _get(data, extractPath, defaultData)
      : data;
    res = {
      data: resourceData,
      status: "",
      notif: {
        message: "",
        type: "",
      },
    };
  } catch (e) {
    res = {
      data: [],
      status: "fail",
      notif: {
        type: "error",
        message: e.apiMessage,
      },
    };
  }

  return res;
}

export default function* candidate() {
  yield all([takeEvery(actionTypes.RESOURCE_GET_DATA, getData)]);
}
