import actionTypes from "../actions/user/actionTypes";
import _cloneDeep from "lodash.clonedeep";

const defaultAuth = {
  notif: {
    message: "",
    type: "",
  },
  status: "",
};
const defaultState = {
  data: {},
  auth: defaultAuth,
  isLoggedIn: false,
  license: {
    isLoading: false,
  },
};

export default (state = defaultState, action = {}) => {
  const { type, ...args } = action;
  switch (type) {
    case actionTypes.USER_AUTH_START:
      return {
        ...state,
        auth: {
          ...state.auth,
          status: "loading",
        },
      };
    case actionTypes.USER_AUTH_COMPLETE:
      return {
        ...state,
        isLoggedIn: true,
      };
    case actionTypes.USER_LOGOUT_COMPLETE:
      return {
        data: {},
        auth: _cloneDeep(defaultAuth),
        isLoggedIn: false,
      };
    case actionTypes.USER_SET_DATA:
      return {
        ...state,
        ...args,
      };
    case actionTypes.USER_DISABLE_TUTORIAL:
      return {
        ...state,
        data: {
          ...state.data,
          hideTutorial: true,
        },
      };
    case actionTypes.USER_SET_LICENSE:
      return {
        ...state,
        license: {
          ...state.license,
          ...args,
        },
      };
    default:
      return state;
  }
};
