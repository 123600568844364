import React, { Component } from 'react';
import { message } from 'antd';
import _clonedeep from 'lodash.clonedeep';
import _isfunction from 'lodash.isfunction';
import _set from 'lodash.set';
import _get from 'lodash.get';
import _merge from 'lodash.merge';
import uuid from 'uuid/v4';
import classnames from 'classnames';

import Modal, { ConfirmButtons } from '@components/shared/Modal/Modal';

export const HhioContext = React.createContext({});
HhioContext.displayName = 'Hhio';

export class Provider extends Component {
  constructor (props) {
    super(props);
    this.state = {
      modal: {}
    };
    this.contextValue = {};
  }

  messageSet = config => {
    if (_isfunction(this.message)) {
      this.message();
    }

    if (config) {
      this.message = message.open(config);
    }
  };

  modalAdd = config => {
    const id = uuid();
    const newModal = _clonedeep(config);
    _set(newModal, 'modalProps.visible', true);

    const cancelButtonOnClick = 'cancelButton.onClick';
    if (!_get(newModal, cancelButtonOnClick)) {
      _set(newModal, cancelButtonOnClick, () => this.modalDestroy(id));
    }

    const modalPropsOnCancel = 'modalProps.onCancel';
    if (!_get(newModal, modalPropsOnCancel)) {
      _set(newModal, modalPropsOnCancel, () => this.modalDestroy(id));
    }

    this.setState(({ modal }) => {
      return {
        modal: {
          ..._clonedeep(modal),
          [id]: newModal
        }
      };
    });

    return {
      update: stateUpdate => this.modalUpdate(id, stateUpdate),
      destroy: () => this.modalDestroy(id)
    };
  };

  modalRemove = id => {
    this.setState(state => {
      const modal = _clonedeep(state.modal);
      delete modal[id];
      return { modal };
    });
  };

  modalDestroy = id => {
    const afterClose = _get(this.state, `modal.${id}.modalProps.afterClose`);
    this.modalUpdate(id, {
      modalProps: {
        visible: false,
        afterClose: () => {
          if (_isfunction(afterClose)) {
            afterClose();
          }
          this.modalRemove(id);
        }
      }
    });
  };

  modalUpdate = (id, config) => {
    this.setState(state => {
      const modal = _clonedeep(state.modal);
      const stateUpdate = _merge({}, modal[id], config);
      _set(modal, id, stateUpdate);
      return { modal };
    });
  };

  renderModals = () => {
    const { modal } = this.state;
    return Object.keys(modal).map(id => {
      const {
        content,
        button,
        confirmButton,
        cancelButton,
        modalProps
      } = modal[id];
      const loading = _get(confirmButton, 'loading');
      let modalBtn = button;
      if (!button) {
        const buttonProps = {
          okButtonProps: confirmButton,
          cancelButtonProps: cancelButton,
          className: 'mt-4'
        };
        modalBtn = <ConfirmButtons {...buttonProps} />;
      }

      return (
        <Modal
          key={id}
          {...modalProps}
          className={classnames(modalProps.className, {
            'in-progress': loading
          })}
        >
          {content}
          {modalBtn}
        </Modal>
      );
    });
  };

  getContextValue = () => {
    const { modal } = this.state;
    _set(this.contextValue, 'modal', {
      data: modal,
      add: this.modalAdd
    });

    this.contextValue.message = this.messageSet;

    return this.contextValue;
  };

  render () {
    const { children } = this.props;
    return (
      <HhioContext.Provider value={this.getContextValue()}>
        {children}
        {this.renderModals()}
      </HhioContext.Provider>
    );
  }
}

export const withHhio = contextMapping => Component => props => {
  const subscriber = context => {
    let mappedContext = context;
    if (_isfunction(contextMapping)) {
      mappedContext = contextMapping(context);
      mappedContext = typeof mappedContext === 'object' ? mappedContext : {};
    } else {
      mappedContext = {
        hhio: context
      };
    }
    return <Component {...props} {...mappedContext} />;
  };

  return (
    <HhioContext.Consumer>
      {context => subscriber(context)}
    </HhioContext.Consumer>
  );
};
