import yup from "@services/yup";
import moment from "moment";

export const sections = {
  LOCATION: {
    label: "Location",
    id: "location",
  },
  EXPERIENCE: {
    label: "Experience",
    id: "experience",
  },
  COMPANY_PROFILE: {
    label: "Company Profile",
    id: "company-profile",
  },
  EDUCATION: {
    label: "Education",
    id: "education",
  },
  DIVERSITY: {
    label: "Diversity",
    id: "diversity",
  },
  EXCLUSIONS: {
    label: "Exclusions",
    id: "exclusions",
  },
};

export const JOB_HISTORY = "JOB_HISTORY";
export const CONTACTS = "CONTACTS";
export const DISPLAY_CONTACT_LIMIT = 4;

export const fieldLabel = {
  employmentDuration: {
    min: "Min employment duration",
    max: "Max employement duration",
  },
};

export const LOCATION = {
  WORK: "WORK",
  HOME: "HOME",
};

export const LOCATION_TYPE = {
  [LOCATION.WORK]: "Work",
  [LOCATION.HOME]: "Home",
};

export const defaultCriteria = {
  previousCompanyOnly: true,
  companies: [],
  employmentDuration: {
    min: null,
    max: null,
  },
  companyIndustries: {
    industries: [],
  },
  companySpecialization: {
    specializations: [],
  },
  companySizes: {
    sizes: [],
  },
  companyType: {
    type: null,
  },
  locations: [],
  // locationType: [LOCATION.WORK],
  jobTitles: [],
  seniorityExperience: {
    seniorities: [],
    range: {
      min: 0,
      max: 0,
    },
  },
  educationDegree: {
    id: "",
    name: "",
  },
  educationMajors: [],
  ethnicity: [],
  gender: [],
  veteran: false,
  excludeFromExportedCandidates: true,
  includeRelatedDepartment: false,
};

const string100 = yup.string().max(100);
const arrayOfRecordsSchema = yup.array().of(
  yup.object().shape({
    id: string100,
    name: string100,
  })
);
export const maxSeniorityRange = 20;
const seniorityRangeSlider = yup
  .number()
  .min(0)
  .max(20)
  .nullable();

export const validationSchema = {
  previousCompanyOnly: yup.boolean(),
  companies: yup.array().of(
    yup.object().shape({
      id: string100.nullable(),
      name: string100,
    })
  ),
  employmentDuration: yup
    .object({
      min: yup.mixed().label(fieldLabel.employmentDuration.min),
      max: yup.mixed().label(fieldLabel.employmentDuration.max),
    })
    .label("Employment Duration")
    .test(
      "valid-emp-duration",
      `Min. Duration must be earlier than the
      Max. Duration`,
      ({ min, max }) => {
        if (min && max) {
          return moment(min).isSameOrBefore(max, "day");
        } else {
          return true;
        }
      }
    ),
  companyIndustries: yup.object({
    industries: yup.array(),
  }),
  companySpecialization: yup.object({
    specializations: yup.array(),
  }),
  companySizes: yup.object({
    sizes: yup.array(),
  }),
  companyType: yup.object({
    type: string100.nullable(),
  }),
  companySpecialization: yup.object({
    specializations: yup.array().of(
      yup.object().shape({
        id: string100.nullable(),
        name: string100,
      })
    ),
  }),
  locations: yup.array().of(
    yup.object().shape({
      location: string100.nullable(),
      option: string100,
      distance: yup.mixed().nullable(),
    })
  ),
  // locationType: yup.array().of(yup.string()),
  jobTitles: yup.array().of(yup.string()),
  seniorityExperience: yup.object({
    seniorities: yup.array(),
    range: yup.object({
      min: seniorityRangeSlider,
      max: seniorityRangeSlider,
    }),
  }),
  educationDegree: yup.object().shape({
    id: string100,
    name: string100,
  }),
  educationMajors: arrayOfRecordsSchema,
  ethnicity: arrayOfRecordsSchema,
  gender: arrayOfRecordsSchema,
  veteran: yup.boolean(),
  excludeFromExportedCandidates: yup.boolean(),
  includeRelatedDepartment: yup.boolean(),
};

export const defaultRefineFilter = {
  contactInfo: [],
};
