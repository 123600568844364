import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import createSagaMiddleware from "redux-saga";
// import reduxThunk from 'redux-thunk';

import rootReducer from "./reducers";
import sagas from "./sagas";

const sagaMiddleware = createSagaMiddleware();
const storeCompose = composeWithDevTools;
export const middlewares = [sagaMiddleware]; // [reduxThunk]
const store = createStore(
  rootReducer,
  storeCompose(applyMiddleware(...middlewares))
);

sagaMiddleware.run(sagas);

export default store;
