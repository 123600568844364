import _cloneDeep from "lodash.clonedeep";

import { LOADING, IDLE } from "@config/constants";
import actionTypes from "@store/actions/ats/actionTypes";

export const defaultState = {
  config: {},
  getConfig: {
    status: IDLE,
    error: {},
  },
  saveConfig: {
    status: IDLE,
    error: {},
  },
  steps: {
    detail: {},
    completed: [],
    enabled: [],
  },
};

export default (state = _cloneDeep(defaultState), action = {}) => {
  const { type, ...args } = action;

  switch (type) {
    case actionTypes.ATS_GET_CONFIG_START:
      return {
        ...state,
        getConfig: {
          status: LOADING,
          error: {},
        },
      };
    case actionTypes.ATS_GET_CONFIG_COMPLETE:
      return {
        ...state,
        config: args.config || state.config,
        getConfig: args.getConfig,
        steps: args.steps,
      };
    case actionTypes.ATS_SAVE_CONFIG_START:
      return {
        ...state,
        saveConfig: {
          status: LOADING,
          error: {},
        },
      };
    case actionTypes.ATS_SAVE_CONFIG_COMPLETE:
      return {
        ...state,
        config: args.config || state.config,
        steps: args.steps || state.steps,
        saveConfig: args.saveConfig,
      };
    default:
      return state;
  }
};
