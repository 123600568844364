import React from 'react';
import classnames from 'classnames';
import _get from 'lodash.get';

import { hhPrefixer } from '@helpers/collection';

const hhClass = hhPrefixer([
  'avatar'
], true);

export const Avatar = (props) => {
  const { src, text, className, rounded, name } = props;
  const initials = _get(name, '0', '').substring(0, 1) + _get(name, '1', '').substring(0, 1);
  const content = src ? (
    <div className="img" />
  ) : <div className="text">{text || initials}</div>;

  return (
    <div className={classnames(hhClass.avatar, className, { rounded })}>{content}</div>
  );
};

export default Avatar;