import { createSelector } from "reselect";
import _get from "lodash.get";

const getJobTitles = (criteria) => _get(criteria, "jobTitles.length");
const getCompanies = (criteria) => _get(criteria, "companies").length || 0;
const getEmploymentDuration = (criteria) =>
  _get(criteria, "employmentDuration.min") ||
  _get(criteria, "employmentDuration.max")
    ? 1
    : 0;
const getCompanySize = (criteria) =>
  _get(criteria, "companySizes.sizes", []).length;
const getLocations = (criteria) => _get(criteria, "locations", []).length;
const getCompanyType = (criteria) =>
  _get(criteria, "companyType.type") ? 1 : 0;
const getSeniorityExp = (criteria) =>
  _get(criteria, "seniorityExperience.seniorities", []).filter((value) => value)
    .length;
const getCompanyIndustry = (criteria) =>
  _get(criteria, "companyIndustries.industries", []).length;
const getCompanySpecialization = (criteria) =>
  _get(criteria, "companySpecialization.specializations", []).length;
const getEducationDegree = (criteria) =>
  _get(criteria, "educationDegree.id") ? 1 : 0;
const getEducationMajors = (criteria) =>
  _get(criteria, "educationMajors", []).filter((educ) => educ.name).length;
const getGender = (criteria) => {
  return _get(criteria, "gender", []).length;
};
const getEthnicity = (criteria) => {
  return _get(criteria, "ethnicity", []).length;
};
const getVeteran = (criteria) => {
  return _get(criteria, "veteran", {}) ? 1 : 0;
};

const arraySum = (total, value) => total + value;

export const getCompanyProfileFieldsCount = createSelector(
  [
    getCompanies,
    getEmploymentDuration,
    getCompanyIndustry,
    getCompanySize,
    getCompanyType,
    getCompanySpecialization,
  ],
  (...fields) => fields.reduce(arraySum)
);
export const getExperienceFieldCount = createSelector(
  [getJobTitles, getSeniorityExp],
  (...fields) => fields.reduce(arraySum)
);
export const getEducationFieldCount = createSelector(
  [getEducationDegree, getEducationMajors],
  (...fields) => fields.reduce(arraySum)
);
export const getDiversityCount = createSelector(
  [getGender, getEthnicity, getVeteran],
  (...fields) => fields.reduce(arraySum)
);
export const getExcludeExportedCandidates = (criteria) =>
  criteria.excludeFromExportedCandidates ? 1 : 0;
export const getTotalCriteriaCount = createSelector(
  [
    getCompanyProfileFieldsCount,
    getLocations,
    getExperienceFieldCount,
    getEducationFieldCount,
    getDiversityCount,
  ],
  (...fields) => fields.reduce(arraySum)
);
