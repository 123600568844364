import React from "react";
import DefaultLayout from "@components/Layout/Container/Default";
import SecureRoute from "@containers/SecureRoute/SecureRoute";
import ComponentLoader from "@components/shared/ComponentLoader/ComponentLoader";
import routes from "../config/routes";

const DownloadList = ComponentLoader({
  loader: () => import("../../people-legal/pages/download"),
});

const Lookup = ComponentLoader({
  loader: () => import("../../people-legal/pages/lookup/lookup"),
});

const BookmarkList = ComponentLoader({
  loader: () => import("../pages/bookmark/bookmark"),
});

const SearchSecureRoute = [
  <SecureRoute exact path={routes.downloadList} key="legal-download-list">
    <DefaultLayout className="legal-download-list">
      <DownloadList />
    </DefaultLayout>
  </SecureRoute>,
  <SecureRoute exact path={routes.bookmarkList} key="legal-bookmark-list">
    <DefaultLayout className="legal-bookmark-list">
      <BookmarkList />
    </DefaultLayout>
  </SecureRoute>,
  <SecureRoute exact path={routes.lookup} key="legal-lookup-list">
    <DefaultLayout className="legal-lookup-list">
      <Lookup />
    </DefaultLayout>
  </SecureRoute>,
];

export default SearchSecureRoute;
