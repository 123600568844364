import React, { useCallback, useEffect, useState } from "react";
import { Icon, message } from "antd";
import axios from "axios";

import Carousel from "@components/shared/Carousel/Carousel";
import { ErrorMsgNotif } from "@components/shared/MessageNotif/MessageNotif";
import { getConfigUrl, getImageUrl } from "@helpers/tutorial";

const TutorialCarousel = (props) => {
  const { tutorialKey, width = 600, children } = props;
  const [slides, setSlides] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const empty = children || <p className="text-center">No slides found.</p>;

  const getTutorialData = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(getConfigUrl(tutorialKey));
      const slides = data.map((slide) => {
        return {
          ...slide,
          image: getImageUrl(slide.image),
        };
      });
      setSlides(slides);
    } catch (e) {
      message.open({
        content: <ErrorMsgNotif>Failed to load tutorial data.</ErrorMsgNotif>,
      });
    } finally {
      setIsLoading(false);
    }
  }, [tutorialKey]);

  useEffect(() => {
    if (tutorialKey) getTutorialData(tutorialKey);
  }, [getTutorialData]);

  if (isLoading) {
    return (
      <Icon
        spin
        type="loading-3-quarters"
        className="hh-circular-progress-md m-2 modal-icon d-block text-center"
      />
    );
  }

  return slides.length ? (
    <Carousel slides={slides} style={{ width }} className="tutorial-carousel" />
  ) : (
    empty
  );
};

export default TutorialCarousel;
