import actionTypes from "./actionTypes";

export const search = (config, pagination) => {
  return {
    config,
    pagination,
    type: actionTypes.CANDIDATE_SEARCH,
  };
};
export const getList = (resultLink) => {
  return {
    resultLink,
    type: actionTypes.CANDIDATE_GET_LIST,
  };
};
export const getListStart = (params) => {
  return {
    params,
    type: actionTypes.CANDIDATE_GET_LIST_START,
  };
};
export const getListComplete = (args) => {
  return {
    ...args,
    type: actionTypes.CANDIDATE_GET_LIST_COMPLETE,
  };
};
export const resetState = () => {
  return {
    type: actionTypes.CANDIDATE_RESET_STATE,
  };
};
export const updateProp = (key, value) => {
  return {
    key,
    value,
    type: actionTypes.CANDIDATE_UPDATE_PROP,
  };
};
export const setExportData = (exportData) => {
  return {
    exportData,
    type: actionTypes.CANDIDATE_SET_EXPORT_DATA,
  };
};
