import _set from "lodash.set";

export const getLicenseHelpersObj = (args) => {
  const data = { ...args };

  if (args.exportLicense) {
    const {
      userExportLimit,
      userCurrentExportCount,
      companyExportLimit,
      companyCurrentExportCount,
    } = args.exportLicense || {};
    const remainingUserExport = userExportLimit - userCurrentExportCount;
    const remainingCompanyExport =
      companyExportLimit - companyCurrentExportCount;
    _set(data, "exportLicense.remainingUserExport", remainingUserExport);
    _set(data, "exportLicense.remainingCompanyExport", remainingCompanyExport);
    _set(
      data,
      "exportLicense.hasAvailableCredits",
      remainingUserExport > 0 && remainingCompanyExport > 0
    );
  }

  return data;
};
