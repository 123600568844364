const peopleData = {
  id: "1628002",
  candId: 1628002,
  photoUrl: "static-exp1.licdn.com/sc/h/244xhbkr7g40x6bsu4gi6q4ry",
  firstName: "Vijayan",
  lastName: "Manogaran",
  fullName: "vijayan manogaran",
  headline: "Sotware professional at The hartford",
  currentWorkTitle: "Sotware professional",
  currentCompanyName: "The Hartford",
  linkedinUrl: "linkedin.com/in/vijayan-manogaran-bb4933103",
  githubUrl: null,
  twitterUrl: null,
  facebookUrl: null,
  zip: "06040",
  city: "Manchester",
  state: "Connecticut",
  country: "United States",
  stateCode: "CT",
  countryCode: "US",
  gender: "They/Them",
  isVeteran: false,
  ethnicity: [],
  contactInformation: [
    {
      id: 50204998,
      sequence: 1,
      type: "EMAIL",
      value: "vijayan.manogaran@thehartford.com",
    },
  ],
  educations: [],
  educationMajors: [],
  jobHistories: [
    {
      companyId: "4770005",
      masterCompanyId: "4770005",
      sequence: 12,
      jobTitle: "Chief Technology Officer",
      jobDescription:
        "Chief Technology Officer and Chief Information Officer for a startup technology services firm.",
      companySizeId: "1001",
      companySize: "1 - 50",
      size: "SMALL",
      industry: "IT Services & Consulting",
      companyType: "Private",
      companyName: "Pinstripe Inc.",
      masterCompanyName: "Pinstripe Inc.",
      yearsOfExperience: 3.08,
      parentDepartment: "Information Technology",
      department: "IT Mgmt",
      departmentFunction: "IT Mgmt",
      startDate: "2005-01-01T00:00:00.000+00:00",
      endDate: "2008-01-01T00:00:00.000+00:00",
      startDateStr: "2005",
      endDateStr: "2008",
      present: false,
    },
    {
      companyId: "22926690",
      masterCompanyId: "22926690",
      sequence: 1,
      jobTitle:
        "SVP, Global Information Security, Network Security Assessments",
      jobDescription: "",
      companySizeId: "1008",
      companySize: "> 10,000",
      size: "LARGE",
      industry: "Banking",
      companyType: "Public",
      companyName: "Bank of America",
      masterCompanyName: "Bank of America",
      yearsOfExperience: 0.42,
      parentDepartment: "Security",
      department: "Cybersecurity",
      departmentFunction: "Information Security",
      startDate: "2022-09-01T00:00:00.000+00:00",
      endDate: "2023-02-01T00:00:00.000+00:00",
      startDateStr: "Sep 2022",
      endDateStr: "Present",
      present: true,
    },
    {
      companyId: "22926690",
      masterCompanyId: "22926690",
      sequence: 3,
      jobTitle: "SVP, Global Information Security, Digital Brand Protection",
      jobDescription: "",
      companySizeId: "1008",
      companySize: "> 10,000",
      size: "LARGE",
      industry: "Banking",
      companyType: "Public",
      companyName: "Bank of America",
      masterCompanyName: "Bank of America",
      yearsOfExperience: 2.5,
      parentDepartment: "Security",
      department: "Cybersecurity",
      departmentFunction: "Information Security",
      startDate: "2020-08-01T00:00:00.000+00:00",
      endDate: "2023-02-01T00:00:00.000+00:00",
      startDateStr: "Aug 2020",
      endDateStr: "Present",
      present: true,
    },
    {
      companyId: "22926690",
      masterCompanyId: "22926690",
      sequence: 5,
      jobTitle:
        "SVP, Global Information Security, Business Information Security Officer",
      jobDescription: "",
      companySizeId: "1008",
      companySize: "> 10,000",
      size: "LARGE",
      industry: "Banking",
      companyType: "Public",
      companyName: "Bank of America",
      masterCompanyName: "Bank of America",
      yearsOfExperience: 1.5,
      parentDepartment: "Security",
      department: "Cybersecurity",
      departmentFunction: "Information Security",
      startDate: "2019-02-01T00:00:00.000+00:00",
      endDate: "2020-08-01T00:00:00.000+00:00",
      startDateStr: "Feb 2019",
      endDateStr: "Aug 2020",
      present: false,
    },
    {
      companyId: "22926690",
      masterCompanyId: "22926690",
      sequence: 6,
      jobTitle:
        "SVP, Global Information Security, Governance, Risk, and Compliance",
      jobDescription: "",
      companySizeId: "1008",
      companySize: "> 10,000",
      size: "LARGE",
      industry: "Banking",
      companyType: "Public",
      companyName: "Bank of America",
      masterCompanyName: "Bank of America",
      yearsOfExperience: 3.08,
      parentDepartment: "Information Technology",
      department: "Business Intelligence",
      departmentFunction: "MIS / Reporting",
      startDate: "2017-01-01T00:00:00.000+00:00",
      endDate: "2020-02-01T00:00:00.000+00:00",
      startDateStr: "Jan 2017",
      endDateStr: "Feb 2020",
      present: false,
    },
    {
      companyId: "22926690",
      masterCompanyId: "22926690",
      sequence: 7,
      jobTitle:
        "VP, Global Information Security, Cyber Threat Intelligence Senior Manager",
      jobDescription:
        "Cyber Threat Intelligence focused on Mobile and Internet of Things  IoT",
      companySizeId: "1008",
      companySize: "> 10,000",
      size: "LARGE",
      industry: "Banking",
      companyType: "Public",
      companyName: "Bank of America",
      masterCompanyName: "Bank of America",
      yearsOfExperience: 1.0,
      parentDepartment: "Security",
      department: "Cybersecurity",
      departmentFunction: "Information Security",
      startDate: "2016-01-01T00:00:00.000+00:00",
      endDate: "2017-01-01T00:00:00.000+00:00",
      startDateStr: "Jan 2016",
      endDateStr: "Jan 2017",
      present: false,
    },
    {
      companyId: "22926690",
      masterCompanyId: "22926690",
      sequence: 8,
      jobTitle: "VP, Global Information Security, Quantitative Risk Manager",
      jobDescription:
        "Factor Analysis of Risk  FAIR  and other quantitative risk analysis relevant to cybersecurity and risk mitigation.",
      companySizeId: "1008",
      companySize: "> 10,000",
      size: "LARGE",
      industry: "Banking",
      companyType: "Public",
      companyName: "Bank of America",
      masterCompanyName: "Bank of America",
      yearsOfExperience: 0.5,
      parentDepartment: "Security",
      department: "Cybersecurity",
      departmentFunction: "Information Security",
      startDate: "2016-01-01T00:00:00.000+00:00",
      endDate: "2016-07-01T00:00:00.000+00:00",
      startDateStr: "Jan 2016",
      endDateStr: "Jul 2016",
      present: false,
    },
    {
      companyId: "22926690",
      masterCompanyId: "22926690",
      sequence: 9,
      jobTitle:
        "VP, Global Information Security, Cyber Threat Intelligence Analyst",
      jobDescription:
        "Cyber Threat Intelligence specializing in Nation State threats.",
      companySizeId: "1008",
      companySize: "> 10,000",
      size: "LARGE",
      industry: "Banking",
      companyType: "Public",
      companyName: "Bank of America",
      masterCompanyName: "Bank of America",
      yearsOfExperience: 2.5,
      parentDepartment: "Information Technology",
      department: "Business Intelligence",
      departmentFunction: "MIS / Reporting",
      startDate: "2014-01-01T00:00:00.000+00:00",
      endDate: "2016-07-01T00:00:00.000+00:00",
      startDateStr: "Jan 2014",
      endDateStr: "Jul 2016",
      present: false,
    },
    {
      companyId: "22926690",
      masterCompanyId: "22926690",
      sequence: 10,
      jobTitle:
        "VP, Global Wealth & Investment Management, Infrastructure Architect",
      jobDescription: "",
      companySizeId: "1008",
      companySize: "> 10,000",
      size: "LARGE",
      industry: "Banking",
      companyType: "Public",
      companyName: "Bank of America",
      masterCompanyName: "Bank of America",
      yearsOfExperience: 3.17,
      parentDepartment: "Information Technology",
      department: "Infrastructure",
      departmentFunction: "Infrastructure",
      startDate: "2010-11-01T00:00:00.000+00:00",
      endDate: "2014-01-01T00:00:00.000+00:00",
      startDateStr: "Nov 2010",
      endDateStr: "Jan 2014",
      present: false,
    },
    {
      companyId: "22926690",
      masterCompanyId: "22926690",
      sequence: 11,
      jobTitle:
        "Global Wealth & Investment Management, Infrastructure Engineer",
      jobDescription: "",
      companySizeId: "1008",
      companySize: "> 10,000",
      size: "LARGE",
      industry: "Banking",
      companyType: "Public",
      companyName: "Bank of America",
      masterCompanyName: "Bank of America",
      yearsOfExperience: 2.17,
      parentDepartment: "Information Technology",
      department: "Infrastructure",
      departmentFunction: "Infrastructure",
      startDate: "2008-09-01T00:00:00.000+00:00",
      endDate: "2010-11-01T00:00:00.000+00:00",
      startDateStr: "Sep 2008",
      endDateStr: "Nov 2010",
      present: false,
    },
    {
      companyId: "25859433",
      masterCompanyId: "25859433",
      sequence: 4,
      jobTitle: "Retired Marine Cyberspace Officer",
      jobDescription: "",
      companySizeId: "1005",
      companySize: "1,001 - 2,000",
      size: "MID",
      industry: "Armed Forces",
      companyType: "Government Agency",
      companyName: "United States Marine Forces Reserve",
      masterCompanyName: "Marine Forces Reserve",
      yearsOfExperience: 23.0,
      parentDepartment: "Security",
      department: "Cybersecurity",
      departmentFunction: "Cybersecurity",
      startDate: "1998-01-01T00:00:00.000+00:00",
      endDate: "2021-01-01T00:00:00.000+00:00",
      startDateStr: "1998",
      endDateStr: "2021",
      present: false,
    },
    {
      companyId: "6564027",
      masterCompanyId: "6564027",
      sequence: 13,
      jobTitle: "Information Technology Consultant",
      jobDescription: "",
      companySizeId: "1005",
      companySize: "1,001 - 2,000",
      size: "MID",
      industry: "Outsourcing/Offshoring",
      companyType: "Private",
      companyName: "Procom",
      masterCompanyName: "Procom",
      yearsOfExperience: 2.0,
      parentDepartment: "Information Technology",
      department: "IT Operations",
      departmentFunction: "IT Consulting",
      startDate: "2003-01-01T00:00:00.000+00:00",
      endDate: "2005-01-01T00:00:00.000+00:00",
      startDateStr: "2003",
      endDateStr: "2005",
      present: false,
    },
    {
      companyId: "9198317",
      masterCompanyId: "9198317",
      sequence: 14,
      jobTitle: "Director Of Information Technology",
      jobDescription: "",
      companySizeId: "1002",
      companySize: "51 - 200",
      size: "SMALL",
      industry: "Performing Arts",
      companyType: "Non Profit",
      companyName: "Blumenthal Performing Arts",
      masterCompanyName: "Blumenthal Performing Arts",
      yearsOfExperience: 2.0,
      parentDepartment: "Information Technology",
      department: "IT Mgmt",
      departmentFunction: "IT Mgmt",
      startDate: "2001-01-01T00:00:00.000+00:00",
      endDate: "2003-01-01T00:00:00.000+00:00",
      startDateStr: "2001",
      endDateStr: "2003",
      present: false,
    },
    {
      companyId: "28601228",
      masterCompanyId: "28604520",
      sequence: 2,
      jobTitle: "Adjunct Professor",
      jobDescription: "",
      companySizeId: null,
      companySize: "Unknown",
      size: null,
      industry: "Higher Education",
      companyType: null,
      companyName: "University of North Carolina at Charlotte",
      masterCompanyName: "University of North Carolina at Charlotte",
      yearsOfExperience: 0.58,
      parentDepartment: "Education",
      department: "Edu Admin",
      departmentFunction: "Edu Admin",
      startDate: "2022-07-01T00:00:00.000+00:00",
      endDate: "2023-02-01T00:00:00.000+00:00",
      startDateStr: "Jul 2022",
      endDateStr: "Present",
      present: true,
    },
  ],
};

const data = {
  content: [
    {
      ...peopleData,
      id: 12345789,
      fullName: "Sample Candidate 0",
      facebookUrl: "facebook.com/test",
      githubUrl: null,
      linkedinUrl: "linkedin.com/in/test",
      twitterUrl: null,
      startDate: "2015-02-02T03:26:06.112431Z",
      endDate: "2023-02-02T03:26:06.112431Z",
      exportedOnCSV: "2022-12-15T04:00:14+00:00",
      bookmarked: "2023-02-01T00:00:00.000+00:00",
      contactInformation: [
        {
          productId: null,
          sequence: 4,
          type: "PHONE",
          value: "+1 (203) 524-5505",
          tags: ["PHONE"],
          phoneType: "mobile",
          useTypeV2: "general",
          contactSocial: "",
          socialType: "",
          source: ["pdl"],
          dateLastRefreshed: "2021-10-24T00:00:00.000+00:00",
          hidden: false,
        },
        {
          productId: null,
          sequence: 3,
          type: "PHONE",
          value: "+1 (203) 972-6974",
          tags: ["PHONE"],
          phoneType: "general",
          useTypeV2: "general",
          contactSocial: "",
          socialType: "",
          source: ["pdl"],
          dateLastRefreshed: "2021-10-24T00:00:00.000+00:00",
          hidden: false,
        },
        {
          productId: null,
          sequence: 1,
          type: "EMAIL",
          value: "waterburyswim12@optonline.net",
          tags: ["EMAIL"],
          phoneType: "",
          useTypeV2: "personal",
          contactSocial: "",
          socialType: "",
          source: ["pdl"],
          dateLastRefreshed: "2021-12-10T00:00:00.000+00:00",
          hidden: false,
        },
        {
          productId: null,
          sequence: 2,
          type: "EMAIL",
          value: "mhansley@fb.com",
          tags: ["EMAIL"],
          phoneType: "",
          useTypeV2: "work",
          contactSocial: "",
          socialType: "",
          source: ["pdl"],
          dateLastRefreshed: "2021-12-10T00:00:00.000+00:00",
          hidden: false,
        },
        {
          productId: null,
          sequence: 2,
          type: "EMAIL",
          value: "test@fb.com",
          tags: ["EMAIL"],
          phoneType: "",
          useTypeV2: "work",
          contactSocial: "",
          socialType: "",
          source: ["pdl"],
          dateLastRefreshed: "2021-12-10T00:00:00.000+00:00",
          hidden: false,
        },
        {
          productId: null,
          sequence: 2,
          type: "EMAIL",
          value: "samples@fb.com",
          tags: ["EMAIL"],
          phoneType: "",
          useTypeV2: "work",
          contactSocial: "",
          socialType: "",
          source: ["pdl"],
          dateLastRefreshed: "2021-12-10T00:00:00.000+00:00",
          hidden: false,
        },
      ],
      contactRequestIndicator: "OPEN",
    },
    {
      ...peopleData,
      id: 12345788,
      fullName: "Sample Candidate 1",
      isVeteran: false,
      educationDegree: null,
      startDate: "2015-02-02T03:26:06.112431Z",
      endDate: "2021-03-02T03:26:06.112431Z",
    },
    {
      ...peopleData,
      id: 123456787,
      fullName: "Sample Candidate 2",
      startDate: "2019-05-02T03:26:06.112431Z",
      endDate: "2023-02-02T03:26:06.112431Z",
      exportedOnCSV: "2022-12-15T04:00:14+00:00",
      bookmarked: "2023-02-02T00:00:00.000+00:00",
      distance: 15,
      educationDegree: {
        id: "",
        degree: {
          sequence: 1,
          order: 4,
          institution: "Penn State University",
          degree: "Bachelor of Arts   BA, International Relations and Affairs",
          degreeTypeFull: "Bachelor",
          degreeTypeAbbr: "BA",
          educationMajor: "Computer Science",
          summary: "",
          educationMajorParent: "Computer Science",
          yearStart: "2018",
          yearEnd: "2020",
          major: "Computer Science",
        },
      },
      contactInformation: [
        {
          productId: null,
          sequence: 4,
          type: "PHONE",
          value: "+1 (968) 524-1234",
          tags: ["PHONE"],
          phoneType: "mobile",
          useTypeV2: "general",
          contactSocial: "",
          socialType: "",
          source: ["pdl"],
          dateLastRefreshed: "2021-10-24T00:00:00.000+00:00",
          hidden: false,
        },
        {
          productId: null,
          sequence: 1,
          type: "EMAIL",
          value: "sample@sample.net",
          tags: ["EMAIL"],
          phoneType: "",
          useTypeV2: "personal",
          contactSocial: "",
          socialType: "",
          source: ["pdl"],
          dateLastRefreshed: "2021-12-10T00:00:00.000+00:00",
          hidden: false,
        },
      ],
      contactRequestIndicator: "PENDING",
      facebookUrl: null,
      githubUrl: "github.com/test",
      linkedinUrl: null,
      twitterUrl: "twitter.com/in/test",
    },
    {
      ...peopleData,
      id: 12345786,
      fullName: "Sample Candidate 3",
      isVeteran: false,
      startDate: "2017-02-02T03:26:06.112431Z",
      endDate: "2023-01-02T03:26:06.112431Z",
      educationDegree: null,
      ethnicity: [],
      ethnicityObjectList: [],
      genderObject: null,
      gender: null,
      facebookUrl: "facebook.com/test",
      githubUrl: "github.com/test",
      linkedinUrl: "linkedin.com/in/test",
      twitterUrl: "twitter.com/in/test",
    },
    {
      ...peopleData,
      id: 123456785,
      fullName: "Sample Candidate 4",
      facebookUrl: null,
      githubUrl: null,
      linkedinUrl: null,
      twitterUrl: null,
      startDate: "2016-02-02T03:26:06.112431Z",
      endDate: "2023-02-01T03:26:06.112431Z",
      educationDegree: {
        id: "",
        degree: {
          sequence: 1,
          order: 4,
          institution: "Penn State University",
          degree: "Bachelor of Arts   BA, International Relations and Affairs",
          degreeTypeFull: "Bachelor",
          degreeTypeAbbr: "BA",
          educationMajor: "Information Technology",
          summary: "",
          educationMajorParent: "Information Technology",
          yearStart: "2018",
          yearEnd: "2020",
          major: "Information Technology",
        },
      },
      contactInformation: [
        {
          productId: null,
          sequence: 4,
          type: "PHONE",
          value: "+1 (968) 524-5698",
          tags: ["PHONE"],
          phoneType: "mobile",
          useTypeV2: "general",
          contactSocial: "",
          socialType: "",
          source: ["pdl"],
          dateLastRefreshed: "2021-10-24T00:00:00.000+00:00",
          hidden: false,
        },
        {
          productId: null,
          sequence: 1,
          type: "EMAIL",
          value: "test@sample.net",
          tags: ["EMAIL"],
          phoneType: "",
          useTypeV2: "personal",
          contactSocial: "",
          socialType: "",
          source: ["pdl"],
          dateLastRefreshed: "2021-12-10T00:00:00.000+00:00",
          hidden: false,
        },
      ],
      ethnicity: ["asian"],
      ethnicityObjectList: [{ name: "Asian", id: 44 }],
      genderObject: { name: "He/His", id: 1 },
      gender: "male",
      contactRequestIndicator: "FULFILLED",
    },
    {
      ...peopleData,
      id: 123456783,
      fullName: "Sample Candidate 5",
      startDate: "2011-02-02T03:26:06.112431Z",
      endDate: "2023-02-02T03:26:06.112431Z",
      educationDegree: {
        id: "",
        degree: {
          sequence: 1,
          order: 4,
          institution: "Penn State University",
          degree: "Bachelor of Arts   BA, International Relations and Affairs",
          degreeTypeFull: "Bachelor",
          degreeTypeAbbr: "BA",
          educationMajor: "Computer Science",
          summary: "",
          educationMajorParent: "Computer Science",
          yearStart: "2018",
          yearEnd: "2020",
          major: "Computer Science",
        },
      },
      facebookUrl: "facebook.com/test",
      githubUrl: "github.com/test",
      linkedinUrl: null,
      twitterUrl: "twitter.com/in/test",
      contactInformation: [
        {
          productId: null,
          sequence: 1,
          type: "EMAIL",
          value: "test-second@sample.net",
          tags: ["EMAIL"],
          phoneType: "",
          useTypeV2: "personal",
          contactSocial: "",
          socialType: "",
          source: ["pdl"],
          dateLastRefreshed: "2021-12-10T00:00:00.000+00:00",
          hidden: false,
        },
        {
          productId: null,
          sequence: 4,
          type: "PHONE",
          value: "+1 (802) 124-5698",
          tags: ["PHONE"],
          phoneType: "mobile",
          useTypeV2: "general",
          contactSocial: "",
          socialType: "",
          source: ["pdl"],
          dateLastRefreshed: "2021-10-24T00:00:00.000+00:00",
          hidden: false,
        },
        {
          productId: null,
          sequence: 1,
          type: "EMAIL",
          value: "sample-test@sample.net",
          tags: ["EMAIL"],
          phoneType: "",
          useTypeV2: "personal",
          contactSocial: "",
          socialType: "",
          source: ["pdl"],
          dateLastRefreshed: "2021-12-10T00:00:00.000+00:00",
          hidden: false,
        },
        {
          productId: null,
          sequence: 1,
          type: "EMAIL",
          value: "test-fourth@sample.net",
          tags: ["EMAIL"],
          phoneType: "",
          useTypeV2: "personal",
          contactSocial: "",
          socialType: "",
          source: ["pdl"],
          dateLastRefreshed: "2021-12-10T00:00:00.000+00:00",
          hidden: false,
        },
      ],
      ethnicity: [],
      ethnicityObjectList: [],
      genderObject: { name: "He/His", id: 1 },
      gender: "male",
      contactRequestIndicator: "OPEN",
      jobHistories: [],
    },
  ],
  totalPages: 200,
  totalResult: 5000,
};

export default data;
