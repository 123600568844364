import actionTypes from "./actionTypes";

export const resetRequisition = () => {
  return {
    type: actionTypes.REQUISITION_RESET,
  };
};

export const getRequisitionCandidates = (sort, params) => {
  return {
    type: actionTypes.REQUISITION_GET_CANDIDATES,
    sort,
    params,
  };
};
export const setRequisitionCandidates = (args) => {
  return {
    ...args,
    type: actionTypes.REQUISITION_SET_CANDIDATES,
  };
};

export const setReqQueryParameters = (args) => {
  return {
    ...args,
    type: actionTypes.REQUISITION_SET_QUERY_PARAMETERS,
  };
};

export const setReqSelectedCandidates = (id) => {
  return {
    id,
    type: actionTypes.REQUISITION_SET_SELECTED_CANDIDATES,
  };
};

export const getReqDetails = (id, clear) => {
  return {
    id,
    clear,
    type: actionTypes.REQUISITION_GET_DETAILS,
  };
};

export const setReqDetails = (requisition = null) => {
  return {
    requisition,
    type: actionTypes.REQUISITION_SET_DETAILS,
  };
};
