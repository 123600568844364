import { cacheBuster } from "@helpers/collection";

export const constructedTipMessage = (tip) => {
  return {
    message: tip.message || tip,
    linkUrl: tip.linkUrl || "",
    linkPage: tip.linkPage || "",
  };
};

export const baseUrl = () => {
  return (process.env.TUTORIAL_URL ?? "").replace(/\/?$/, "/");
};

export const getConfigUrl = (key) => {
  const fileName = key + "-tutorial.json?" + cacheBuster();
  return baseUrl() + fileName;
};

export const getImageUrl = (fileName) => {
  return baseUrl() + fileName;
};
