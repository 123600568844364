import { takeLatest, all, put } from "redux-saga/effects";
import _get from "lodash.get";
import { select } from "redux-saga/effects";

import resource from "@config/resource";
import actionTypes from "../actions/requisition/actionTypes";
import * as actions from "../actions/requisition";
import platformApiSvc from "@services/platform-api/";
import * as requisitionService from "@services/requisition";
import { DEFAULT_ERROR, MISSING_PAGE } from "@config/requisition";
import * as selectors from "../selectors/requisition";
import { stages } from "@config/requisition";

function* getRequisitionDetails({ id, clear }) {
  if (clear) {
    yield put(actions.setReqDetails(null));
  }

  try {
    const { data } = yield requisitionService.getRequisitionDetails(id);
    yield put(actions.setReqDetails(data));
  } catch (e) {}
}

function* getRequisitionCandidates({ sort, params }) {
  const defaultState = {
    type: actionTypes.REQUISITION_SET_CANDIDATES,
    loading: false,
    data: [],
    errorCode: "",
    pagination: {},
  };
  yield put({
    type: actionTypes.REQUISITION_SET_CANDIDATES,
    loading: true,
  });
  yield put({
    type: actionTypes.REQUISITION_SET_SELECTED_CANDIDATES,
    id: [],
  });
  try {
    const { data } = yield platformApiSvc.get(
      `${resource.candidateProspects}/search/findByRequisitionAndStages?${sort}`,
      {
        params,
      }
    );

    const pagination = _get(data, "page");
    const candidates = _get(data, "_embedded.candidateProspects", []);
    const { page } = params;

    if (page > 1 && page > pagination.totalPages) {
      // Redirect to page 1 if requested page is not available
      return yield put({
        ...defaultState,
        errorCode: MISSING_PAGE,
      });
    }

    const requisition = yield select(selectors.selectRequisitionDetails);
    const stageCount = _get(data, "extras.stageCount");
    yield put(
      actions.setReqDetails({
        ...requisition,
        stageCount,
        inProcessCount: Object.keys(stages).reduce(
          (total, key) => total + _get(stageCount, `${key}.inProcessCount`, 0),
          0
        ),
      })
    );
    yield put({
      ...defaultState,
      data: candidates,
      pagination: pagination,
    });
  } catch (e) {
    const errorCode = _get(e, "response.data.error", DEFAULT_ERROR);
    yield put({
      ...defaultState,
      errorCode,
    });
  }
}

export default function* requisition() {
  yield all([
    takeLatest(
      actionTypes.REQUISITION_GET_CANDIDATES,
      getRequisitionCandidates
    ),
    takeLatest(actionTypes.REQUISITION_GET_DETAILS, getRequisitionDetails),
  ]);
}
