export const IDLE = "IDLE";
export const LOADING = "LOADING";
export const SUCCESS = "SUCCESS";
export const FAIL = "FAIL";
export const ERROR = "ERROR";

export const MOVE_TO_PROSPECT = "MOVE_TO_PROSPECT";
export const REMOVE_FROM_PROSPECT = "REMOVE_FROM_PROSPECT";
export const MOVE_TO_DISQUALIFIED = "MOVE_TO_DISQUALIFIED";
export const REMOVE_FROM_DISQUALIFIED = "REMOVE_FROM_DISQUALIFIED";

// START: candidate stages
export const DNQ = "DNQ";
export const DISQUALIFIED = "DISQUALIFIED";
export const PROSPECT = "PROSPECT";
export const SHORT_LIST = "SHORT_LIST";
export const SCREEN = "SCREEN";
export const MANAGER_REVIEW = "MANAGER_REVIEW";
export const INTERVIEW = "INTERVIEW";
export const OFFER = "OFFER";
export const HIRE = "HIRE";
// END: candidate stages

// CONTACT CATEGORY
export const WORK = "work";
export const PERSONAL = "personal";

// STORAGE
export const IS_AUTO_COLLAPSE_ON = "isAutoCollapseOn";

// MODULE LICENSE
export const MODULES = {
  TALENT_SOURCING: "TALENT_SOURCING",
  WITNESS_FINDER: "WITNESS_FINDER",
};

export const MODULE_LICENSE = {
  [MODULES.TALENT_SOURCING]: "Talent Sourcing",
  [MODULES.WITNESS_FINDER]: "Witness/Participant Finder",
};

// SECURITY CLEARANCE
export const CLEARANCE_LEVEL = "clearance_level";
export const CLEARANCE_ADDON = "clearance_addon";

export default {
  MOVE_TO_PROSPECT,
  MOVE_TO_DISQUALIFIED,
  SUCCESS,
  LOADING,
  FAIL,
  ERROR,
};
