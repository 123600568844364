import platformApiSvc from "@services/platform-api/";
import resource from "../config/resource";

export const search = (payload, options) => {
  return platformApiSvc.post(resource.people + "/search", payload, options);
};

export const bookmarkList = (payload, options) => {
  return platformApiSvc.get(
    resource.legalPeople + "/bookmark",
    payload,
    options
  );
};

export const downloadHistory = async (params) => {
  const queryParams = queryString.stringify(params);
  return await platformApiSvc.get(
    `${resource.legalPeople}/download/history?${queryParams}`
  );
};

export const sendContactRequest = async (peopleIds) => {
  return await platformApiSvc.post(
    `${resource.legalPeople}/contactRefreshRequests`,
    {
      peopleIds,
    }
  );
};

export const getIdsForContactRequests = () => {
  let contactRequests = localStorage.getItem("contactRequests");
  contactRequests = contactRequests ? contactRequests.split(",") : [];

  return contactRequests;
};
