import { createSelector } from "reselect";
import _get from "lodash.get";
import _clonedeep from "lodash.clonedeep";

import { mapApiToStateSchema } from "@helpers/criteria";
import { stageCountFilter } from "@config/collection";

export const criteriaFromSearchData = (state) => {
  const parameters = _get(state, "search.data.parameters", {});
  const requisitionId = _get(state, "search.data.requisition.id");

  return {
    ...mapApiToStateSchema(_clonedeep(parameters)),
    requisitionFilter: {
      inclusion: requisitionId ? stageCountFilter.PENDING_REVIEW : null,
    },
  };
};

export const getSearch = (state) => state.search;

export const searchRefineResult = createSelector(
  getSearch,
  (search) => search.refineSearch
);
