import apiResource from "@config/resource";
import { fullConfig, voiceList } from "./data";

export const getConfig = (mock) => {
  mock
    .onGet(new RegExp(`${apiResource.autoDialer}/configuration/full*`))
    .reply(200, fullConfig);
};

export const getVoiceList = (mock) => {
  mock
    .onGet(new RegExp(`${apiResource.autoDialer}/voice*`))
    .reply(200, voiceList);
};

export const setActiveStatus = (mock) => {
  mock
    .onPut(new RegExp(`${apiResource.autoDialer}/configuration/activate*`))
    .reply(200, voiceList);
};

export const saveAnyConfiguration = (mock) => {
  mock
    .onPut(new RegExp(`${apiResource.autoDialer}/configuration*`))
    .reply(200, {
      configuration: {
        country: "CA",
        areaCode: "204",
        callRecordings: true,
        candidatePredefinedTransferMessage: true,
        recruiterPredefinedTransferMessage: true,
        callForwarding: true,
        voicemail: true,
        redial: true,
        maxRedialPerDay: 1,
        maxDaysForRedial: 5,
        redialStartTime: "10:00",
        delayTime: true,
        leaveVoicemail: "FIRST_CALL",
        delayTimeBetweenCalls: 5,
        editDelayTime: true,
      },
      userDetail: {
        autoDialerUserCount: 2,
        currentAutoDialerUserCount: 2,
        users: [
          {
            accountId: 10002,
            fullName: "Acme Administrator",
            mobileNumber: "(333) 333-3333",
            twilioPhoneNumber: null,
            active: false,
          },
          {
            accountId: 10003,
            fullName: "Recroites Reclusion",
            mobileNumber: "",
            twilioPhoneNumber: null,
            active: false,
          },
          {
            accountId: 10004,
            fullName: "Harringer Manarsky",
            mobileNumber: "2345167890",
            twilioPhoneNumber: null,
            active: false,
          },
          {
            accountId: 10005,
            fullName: "Sauce Cerrer",
            mobileNumber: "",
            twilioPhoneNumber: null,
            active: false,
          },
          {
            accountId: 10023,
            fullName: "H hh",
            mobileNumber: "3333331234",
            twilioPhoneNumber: "+12044003436",
            active: true,
          },
          {
            accountId: 10031,
            fullName: "Rohit Dubey",
            mobileNumber: "",
            twilioPhoneNumber: "+12049000029",
            active: true,
          },
          {
            accountId: 10139,
            fullName: "Jim Labio",
            mobileNumber: "",
            twilioPhoneNumber: null,
            active: false,
          },
          {
            accountId: 10331,
            fullName: "rujuta test",
            mobileNumber: "",
            twilioPhoneNumber: null,
            active: false,
          },
        ],
      },
      messageTemplate: {
        templates: [
          {
            id: 76,
            templateName: "Voicemail Template CL 1",
            messageType: "VOICEMAIL",
            messageTemplate:
              "Voicemail Template CL 1\n\nHi {{candidateFirstName}}, this is {{userFirstName}} {{userLastName}} from {{userCompanyName}}. I am reaching out to you for a job opportunity {{requisitionJobTitle}} and I’d like to connect to discuss how you would be a great fit for this role. Please let me know if you are interested, and I can provide you with more information. You can reach out to me at {{userTwilioNumber}} or email me at {{userEmailAddress}}. Looking forward to discuss this opportunity with you. Thanks.",
            audioVoice: {
              id: 16,
              type: "NEURAL",
              lang: "English (US)",
              code: "en-US",
              gender: "Female",
              name: "Kendra",
            },
            defaultFlag: true,
            level: "COMPANY",
            accountId: null,
            createdBy: "H hh",
            s3Url:
              "https://s3.us-west-2.amazonaws.com/hh-polly-audio-files/qa/76.2a779404-df5e-4b9c-854f-873fd94e9067.mp3",
            previewMessage:
              "Voicemail Template CL 1\n\nHi John, this is Jane Doe from Acme Corp. I am reaching out to you for a job opportunity Full-Stack Developer and I’d like to connect to discuss how you would be a great fit for this role. Please let me know if you are interested, and I can provide you with more information. You can reach out to me at +16174617720 or email me at jane.doe@acme.co. Looking forward to discuss this opportunity with you. Thanks.",
          },
          {
            id: 14,
            templateName: "System Template",
            messageType: "VOICEMAIL",
            messageTemplate:
              "Hi {{candidateFirstName}}, this is {{userFirstName}} {{userLastName}} from {{userCompanyName}}. I am reaching out to you for a job opportunity {{requisitionJobTitle}} and I’d like to connect to discuss how you would be a great fit for this role. Please let me know if you are interested, and I can provide you with more information. You can reach out to me at {{userTwilioNumber}} or email me at {{userEmailAddress}}. Looking forward to discuss this opportunity with you. Thanks.",
            audioVoice: {
              id: 16,
              type: "NEURAL",
              lang: "English (US)",
              code: "en-US",
              gender: "Female",
              name: "Kendra",
            },
            defaultFlag: false,
            level: "SYSTEM",
            accountId: null,
            createdBy: "SYSTEM",
            s3Url:
              "https://hh-polly-audio-files.s3.us-west-2.amazonaws.com/voicemail-default.b414761e-2923-43a3-829c-7f6d26156662.mp3",
            previewMessage:
              "Hi John, this is Jane Doe from Acme Corp. I am reaching out to you for a job opportunity Full-Stack Developer and I’d like to connect to discuss how you would be a great fit for this role. Please let me know if you are interested, and I can provide you with more information. You can reach out to me at +16174617720 or email me at jane.doe@acme.co. Looking forward to discuss this opportunity with you. Thanks.",
          },
          {
            id: 44,
            templateName: "Candidate Template",
            messageType: "TRANSFER_FOR_CANDIDATE",
            messageTemplate:
              "Hi {{candidateFirstName}}, this is a call from {{userFirstName}} {{userLastName}} from {{userCompanyName}} about a job opportunity for {{requisitionJobTitle}}. Please hold while the call is connected.",
            audioVoice: {
              id: 16,
              type: "NEURAL",
              lang: "English (US)",
              code: "en-US",
              gender: "Female",
              name: "Kendra",
            },
            defaultFlag: true,
            level: "COMPANY",
            accountId: null,
            createdBy: "Admin QA Application Security",
            s3Url:
              "https://s3.us-west-2.amazonaws.com/hh-polly-audio-files/qa/44.5c8afbbd-0a06-42f4-8161-56eecf3ffe21.mp3",
            previewMessage:
              "Hi John, this is a call from Jane Doe from Acme Corp about a job opportunity for Full-Stack Developer. Please hold while the call is connected.",
          },
          {
            id: 15,
            templateName: "System Template",
            messageType: "TRANSFER_FOR_CANDIDATE",
            messageTemplate:
              "Hi {{candidateFirstName}}, this is a call from {{userFirstName}} {{userLastName}} from {{userCompanyName}} about a job opportunity for {{requisitionJobTitle}}. Please hold while the call is connected.",
            audioVoice: {
              id: 16,
              type: "NEURAL",
              lang: "English (US)",
              code: "en-US",
              gender: "Female",
              name: "Kendra",
            },
            defaultFlag: false,
            level: "SYSTEM",
            accountId: null,
            createdBy: "SYSTEM",
            s3Url:
              "https://hh-polly-audio-files.s3.us-west-2.amazonaws.com/transfer-for-candidate-default.491f6bf9-1c16-4d94-9aa0-2e49aa69ab4a.mp3",
            previewMessage:
              "Hi John, this is a call from Jane Doe from Acme Corp about a job opportunity for Full-Stack Developer. Please hold while the call is connected.",
          },
          {
            id: 232,
            templateName: "Transfer Recruiter - company template",
            messageType: "TRANSFER_FOR_RECRUITER",
            messageTemplate:
              "Transfer Recruiter - company template\n\nHi {{userFirstName}}, this is a call from Headhuntr.io to connect you to the candidate {{candidateFirstName}} {{candidateLastName}} about the job {{requisitionJobTitle}}. The candidate is on the line. Please hold while the call is connected.",
            audioVoice: {
              id: 19,
              type: "STANDARD",
              lang: "English (US)",
              code: "en-US",
              gender: "Male",
              name: "Joey",
            },
            defaultFlag: true,
            level: "COMPANY",
            accountId: null,
            createdBy: "H hh",
            s3Url:
              "https://s3.us-west-2.amazonaws.com/hh-polly-audio-files/qa/232.0d72c5de-9c61-4f75-ae5e-3dd4c5c44471.mp3",
            previewMessage:
              "Transfer Recruiter - company template\n\nHi Jane, this is a call from Headhuntr.io to connect you to the candidate John Wick about the job Full-Stack Developer. The candidate is on the line. Please hold while the call is connected.",
          },
          {
            id: 16,
            templateName: "System Template",
            messageType: "TRANSFER_FOR_RECRUITER",
            messageTemplate:
              "Hi {{userFirstName}}, this is a call from Headhuntr.io to connect you to the candidate {{candidateFirstName}} {{candidateLastName}} about the job {{requisitionJobTitle}}. The candidate is on the line. Please hold while the call is connected.",
            audioVoice: {
              id: 16,
              type: "NEURAL",
              lang: "English (US)",
              code: "en-US",
              gender: "Female",
              name: "Kendra",
            },
            defaultFlag: false,
            level: "SYSTEM",
            accountId: null,
            createdBy: "SYSTEM",
            s3Url:
              "https://hh-polly-audio-files.s3.us-west-2.amazonaws.com/transfer-for-recruiter-default.75d8a946-723c-42b4-9f3d-093db78d85ac.mp3",
            previewMessage:
              "Phone numbers are assigned to 5 out of 10 users. Not enough good phone numbers are available for this area code. Please try again or select another area code.",
          },
        ],
      },
      activation: {
        active: true,
      },
      completedSteps: [
        "ACTIVATION",
        "USERS",
        "MESSAGE_TEMPLATES",
        "CONFIGURATION",
      ],
      result:
        "Phone numbers are assigned to 5 out of 10 users. Not enough good phone numbers are available for this area code. Please try again or select another area code.",
    });
};

export default [
  // saveAnyConfiguration,
  // setActiveStatus,
  // getConfig,
  // getVoiceList,
];
