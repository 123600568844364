import appConfig from "@config/app";

export const configSteps = {
  SELECT_ATS: "select-ats",
  AUTH: "auth",
  WEBHOOK: "webhook",
  USER_MAPPING: "user-mapping",
  ACTIVATION: "activation",
};

export const atsStepLabels = {
  [configSteps.SELECT_ATS]: "Select ATS",
  [configSteps.AUTH]: "Authorize",
  [configSteps.WEBHOOK]: "Webhooks",
  [configSteps.USER_MAPPING]: "User Mapping",
  [configSteps.ACTIVATION]: "Activation",
};

export const atsOptions = {
  GREENHOUSE: "GREENHOUSE",
  LEVER: "LEVER",
};

export const atsOptionsLabel = {
  [atsOptions.GREENHOUSE]: "Greenhouse",
  [atsOptions.LEVER]: "Lever",
};

export const configLink = {
  [atsOptions.GREENHOUSE]: appConfig.hhWebsite + "/greenhouse-integration",
  [atsOptions.LEVER]: appConfig.hhWebsite + "/lever-integration",
};

export const webhookLink = {
  [atsOptions.GREENHOUSE]:
    appConfig.hhWebsite + "/greenhouse-integration.html#configure-webhook",
  [atsOptions.LEVER]:
    appConfig.hhWebsite + "/lever-integration.html#configure-webhook",
};

export const navBtn = {
  SAVE: "Save",
  SAVE_PROCEED: "Save & Proceed",
};

export const leverWebhookTokens = [
  {
    key: "applicationCreated",
    label: "Application Created",
  },
  {
    key: "candidateStageChange",
    label: "Candidate State Change",
  },
  {
    key: "candidateArchiveChange",
    label: "Candidate Archive State Change",
  },
  {
    key: "candidateHired",
    label: "Candidate Hired",
  },
  {
    key: "candidateDeleted",
    label: "Candidate Deleted",
  },
];

export const leverStateKey = "leverState";
