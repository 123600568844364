import { createSelector } from "reselect";
import { constants as hhAccountConstants } from "../../config/hh-account";

export const userStateSelector = (state) => state.user;

export const userDataSelector = createSelector(
  userStateSelector,
  (user) => user.data
);

export const licenseSelector = createSelector(
  userStateSelector,
  (user) => user?.license
);

export const autoDialer = createSelector(
  licenseSelector,
  (license) => license?.autoDialer
);

export const searchResultLimit = createSelector(licenseSelector, (license) => {
  return license?.searchResult === hhAccountConstants.LIMITED
    ? license?.searchResultLimit
    : undefined;
});
