import Chance from 'chance';

const chance = new Chance();

const data = {
  _embedded: {
    requisitions: Array.from({ length: 50 }, (item, i) => {
      const id = 1000 + i;
      return {
        id,
        name: chance.profession(),
        parameters: {
          mustHave: [{
            id: 'JAVA',
            type: 'Programming Language',
            category: 'PRODUCT',
            name: 'Java'
          }, {
            id: 'C++',
            type: 'Programming Language',
            category: 'SKILL',
            name: 'C++'
          }, {
            id: 'TABLEAU',
            type: 'Programming Language',
            category: 'SPECIALIZATION',
            name: 'Tableau'
          }, {
            id: 'KNOWLEDGE OF DATABASE',
            type: 'PRODUCT',
            category: 'Database',
            name: 'Knowledge of Database'
          }, {
            id: 'ATLASSIAN JIRA',
            type: 'Project Management tool',
            category: 'SKILL',
            name: 'Atlassian JIRA'
          }],
          likeToHave: [{
            id: 'POWER BI',
            name: 'Data Visualization tool',
            type: 'SPECIALIZATION',
            category: 'Power BI'
          }, {
            id: 'ZENDESK',
            name: 'Zendesk',
            type: 'Integration',
            category: 'API PRODUCT',
            inferred: true
          }, {
            id: 'SLACK',
            name: 'Slack',
            type: 'Collaboration Tool',
            category: 'SKILL'
          }, {
            id: 'C',
            name: 'C',
            type: 'Programming Language',
            category: 'SKILL'
          }]
        }
      };
    })
  }
};

export default data;