import _mergewith from "lodash.mergewith";
import _get from "lodash.get";
import axios from "axios";
import momentTz from "moment-timezone";

import yup from "@services/yup";
import { hhPrefix, defaultPagination } from "@config/collection";

export const hhPrefixer = (data, returnAsCollection = true) => {
  if (Array.isArray(data)) {
    const collection = {};
    const _array = data.map((item) => {
      const prefixedValue = `${hhPrefix}-${item}`;
      collection[item] = prefixedValue;
      return prefixedValue;
    });

    return returnAsCollection ? collection : _array;
  } else {
    return `${hhPrefix}-${data}`;
  }
};

export const validatePagination = (value) => {
  const { object, number } = yup;
  const schema = object().shape({
    page: number().min(defaultPagination.page),
    size: number().oneOf(defaultPagination.pageSizeOptions),
  });

  return schema.validateSync(value);
};

export const objectToParams = (data) => {
  return Object.entries(data)
    .map(([key, val]) => `${key}=${val}`)
    .join("&");
};

export const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const mergeObject = (...args) => {
  return _mergewith(...args, (dest, src) => {
    if (Array.isArray(dest || src)) {
      return src;
    }
  });
};

export const handleApiError = (e, cb) => {
  if (
    !axios.isCancel(e) &&
    _get(e, "response.status") !== 401 &&
    _get(e, "response.data.error") !== "ONGOING_MAINTENANCE"
  ) {
    cb(e);
  }
};

export const mathRound = (value, decimalPlaces = 2) => {
  const decimalPlace = Math.pow(10, decimalPlaces);
  return Math.round((value + Number.EPSILON) * decimalPlace) / decimalPlace;
};

export const apiUrl = (path) =>
  new URL(path, process.env.PLATFORM_API_URL).toString();

export const cacheBuster = () => process.env.CACHE_BUST;

export const ensureUrlProtocol = (url, secure = false) => {
  const protocols = ["https://", "http://"];
  const protocol = secure ? "https://" : "http://";

  if (!url) return url;

  protocols.forEach((p) => {
    url = url.replace(new RegExp(p), "");
  });

  return protocol + url;
};

export const getSystemTz = () =>
  momentTz.tz(momentTz.tz.guess(true)).format("z");

export default {
  mathRound,
  hhPrefixer,
  objectToParams,
  mergeObject,
  getSystemTz,
};
