export default {
  AUTO_DIALER_GET_CONFIG: "AUTO_DIALER_GET_CONFIG",
  AUTO_DIALER_SET_CONFIG: "AUTO_DIALER_SET_CONFIG",
  AUTO_DIALER_SAVE_CONFIG: "AUTO_DIALER_SAVE_CONFIG",
  AUTO_DIALER_SET_SAVE_CONFIG: "AUTO_DIALER_SET_SAVE_CONFIG",
  AUTO_DIALER_RESET_SAVE_CONFIG: "AUTO_DIALER_RESET_SAVE_CONFIG",
  AUTO_DIALER_SAVE_MESSAGE_TEMPLATE: "AUTO_DIALER_SAVE_MESSAGE_TEMPLATE",
  AUTO_DIALER_SET_SAVE_MESSAGE_TEMPLATE:
    "AUTO_DIALER_SET_SAVE_MESSAGE_TEMPLATE",
  AUTO_DIALER_RESET_SAVE_MESSAGE_TEMPLATE:
    "AUTO_DIALER_RESET_SAVE_MESSAGE_TEMPLATE",
  AUTO_DIALER_DELETE_MESSAGE_TEMPLATE: "AUTO_DIALER_DELETE_MESSAGE_TEMPLATE",
  AUTO_DIALER_SET_DELETE_MESSAGE_TEMPLATE:
    "AUTO_DIALER_SET_DELETE_MESSAGE_TEMPLATE",
  AUTO_DIALER_RESET_DELETE_MESSAGE_TEMPLATE:
    "AUTO_DIALER_RESET_DELETE_MESSAGE_TEMPLATE",
  AUTO_DIALER_GET_USER_MESSAGE_TEMPLATES:
    "AUTO_DIALER_GET_USER_MESSAGE_TEMPLATES",
  AUTO_DIALER_SET_USER_MESSAGE_TEMPLATES:
    "AUTO_DIALER_SET_USER_MESSAGE_TEMPLATES",
  AUTO_DIALER_START_SAVE_USER_MESSAGE_TEMPLATE:
    "AUTO_DIALER_START_SAVE_USER_MESSAGE_TEMPLATE",
  AUTO_DIALER_SET_SAVE_USER_MESSAGE_TEMPLATE:
    "AUTO_DIALER_SET_SAVE_USER_MESSAGE_TEMPLATE",
  AUTO_DIALER_START_BULK_SCHEDULE: "AUTO_DIALER_START_BULK_SCHEDULE",
  AUTO_DIALER_SET_BULK_SCHEDULE: "AUTO_DIALER_SET_BULK_SCHEDULE",
  AUTO_DIALER_RESET_BULK_SCHEDULE: "AUTO_DIALER_RESET_BULK_SCHEDULE",
};
