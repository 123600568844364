import React from "react";
import PropTypes from "prop-types";

import Modal from "@components/shared/Modal/Modal";
import Carousel from "@components/Tutorial/Carousel/Carousel";
import { modalTitle } from "@config/tutorial";

const TutorialModal = (props) => {
  const { tutorialKey, ...restProps } = props;

  return (
    <Modal
      title={modalTitle[tutorialKey]}
      footer={null}
      closable={true}
      destroyOnClose={true}
      className="tutorial-modal"
      {...restProps}
    >
      <Carousel tutorialKey={tutorialKey} />
    </Modal>
  );
};

TutorialModal.defaultProps = {
  visible: false,
  tutorialKey: "",
  onClose: () => {},
};

TutorialModal.propTypes = {
  visible: PropTypes.bool,
  tutorialKey: PropTypes.string,
  onClose: PropTypes.func,
};

export default TutorialModal;
