import _get from "lodash.get";
import apiResource from "@config/resource";
import companyData from "./data.company-profile.json";

export const findByIdIn = (mock) => {
  mock.onGet(`${apiResource.companies}/findByIdIn`).reply((config) => {
    const ids = _get(config, "params.ids", "").split(",");
    const data = {
      _embedded: {
        companies: ids.map((id) => {
          return {
            id,
            name: `Option ${id}`,
          };
        }),
      },
    };

    return [201, data];
  });
};

export const getCompanyProfile = (mock) => {
  mock
    .onGet(new RegExp(`${apiResource.companyAccounts}/*`))
    .reply(200, companyData);
};

export const registerDomain = (mock) => {
  mock.onPost(new RegExp(`${resource.bulkEmail}/registerDomain`)).reply(() => {
    const data = {
      bcc: ["frontend.hhv1@gmail.com"],
      cc: ["frontend.hhv1@gmail.com"],
      companyId: 10045,
      domain: "masu-test.io",
    };
    return [200, data];
  });
};

export default [
  // getCompanyProfile,
  // findByIdIn,
  // registerDomain
];
