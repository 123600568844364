import React, { Component } from "react";
import { Select, Tag, Menu, Dropdown } from "antd";
import { useDrop, useDrag, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import classnames from "classnames";

const fieldTypes = {
  MUST_HAVE: "MUST_HAVE",
  LIKE_TO_HAVE: "LIKE_TO_HAVE",
};

const { Option } = Select;
const options = Array.from({ length: 30 }, (v, key) => ({
  key: `tag-${key + 1}`,
  label: `Skill ${key + 1}`,
}));

function Skill(props) {
  const { data, type } = props;
  const [{ isDragging }, drag] = useDrag(() => ({
    type,
    item: data,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <span className="d-inline-block mr-2 mb-1">
      <span
        ref={drag}
        className={classnames({ "draggable-tem is-dragging": isDragging })}
      >
        {props.children}
      </span>
    </span>
  );
}

function SkillGroup(prop) {
  const { children, onDrop, accept } = prop;
  const [{ over, active }, drop] = useDrop(() => ({
    accept,
    drop: onDrop,
    collect: (monitor) => ({
      over: monitor.isOver(),
      active: monitor.canDrop(),
    }),
  }));

  return (
    <div className={classnames("skill-group", { active, over })} ref={drop}>
      {children}
    </div>
  );
}

function MultiSelect(props) {
  const {
    value = [],
    onChange,
    moveLabel,
    onMove,
    onDrop,
    onRemoveItem,
    fieldType,
    accept,
    options,
  } = props;

  const selected = value.map(({ key }) => key);

  const optionsList = options.map(({ key, label }) => {
    if (props.hideSelected && selected.includes(value)) return null;

    return (
      <Option key={key} value={key}>
        {label}
      </Option>
    );
  });

  const onSelectHandler = (item) => {
    onChange([item, ...value]);
  };

  return (
    <>
      <Select
        value={value}
        onSelect={onSelectHandler}
        onDeselect={onRemoveItem}
        autoClearSearchValue={false}
        notFoundContent={null}
        labelInValue
        mode="multiple"
        className="w-100"
      >
        {optionsList}
      </Select>
      {!!props.showExtTags && (
        <div className={classnames("selected-items mt-2", fieldType)}>
          <SkillGroup accept={accept} onDrop={onDrop}>
            {value.map((item, i) => {
              const { key, label } = item;
              const menu = (
                <Menu>
                  <Menu.Item onClick={() => onMove(item, i)}>
                    {moveLabel}
                  </Menu.Item>
                </Menu>
              );

              return (
                <Skill
                  key={key}
                  index={i}
                  data={item}
                  type={fieldType}
                  moveCard={() => {}}
                >
                  <Dropdown overlay={menu} trigger={["contextMenu"]}>
                    <Tag
                      className="skill"
                      onClose={() => onRemoveItem(item)}
                      closable
                    >
                      {label}
                    </Tag>
                  </Dropdown>
                </Skill>
              );
            })}
          </SkillGroup>
        </div>
      )}
    </>
  );
}

export default class Poc extends Component {
  state = {
    option1: [],
    option2: [],
    option3: [],
    option4: [],
    mustHave: options.slice(0, 8),
    likeToHave: options.slice(10, 18),
  };

  updateValue(value) {
    const { field } = this;

    this.setState({
      [field]: value,
    });
  }

  moveToMustHave = (item) => {
    this.setState((state) => ({
      likeToHave: state.likeToHave.filter(({ key }) => item.key !== key),
      mustHave: [item, ...state.mustHave],
    }));
  };

  moveToLikeToHave = (item) => {
    this.setState((state) => ({
      mustHave: state.mustHave.filter(({ key }) => item.key !== key),
      likeToHave: [item, ...state.likeToHave],
    }));
  };

  removeItem = (key, item) => {
    this.setState((state) => ({
      [key]: state[key].filter(({ key }) => item.key !== key),
    }));
  };

  render() {
    const { mustHave, likeToHave } = this.state;

    return (
      <DndProvider backend={HTML5Backend}>
        <div className="dnd-poc-container">
          <div className="ms-cont keywords hide-selected">
            <h3>Must Have</h3>
            <MultiSelect
              value={mustHave}
              fieldType={fieldTypes.MUST_HAVE}
              accept={fieldTypes.LIKE_TO_HAVE}
              onChange={(value) => this.setState({ mustHave: value })}
              onMove={this.moveToLikeToHave}
              onDrop={this.moveToMustHave}
              onRemoveItem={(item) => this.removeItem("mustHave", item)}
              moveLabel="Move to `Like To Have`"
              showExtTags={true}
              options={options}
            />

            <h3 className="mt-3">Like to Have</h3>
            <MultiSelect
              value={likeToHave}
              fieldType={fieldTypes.LIKE_TO_HAVE}
              accept={fieldTypes.MUST_HAVE}
              onChange={(value) => this.setState({ likeToHave: value })}
              onMove={this.moveToMustHave}
              onDrop={this.moveToLikeToHave}
              onRemoveItem={(item) => this.removeItem("likeToHave", item)}
              moveLabel="Move to `Must Have`"
              showExtTags={true}
              options={options}
            />
          </div>
        </div>
      </DndProvider>
    );
  }
}
