import actionTypes from "./action-types";

export const setData = (searchData) => {
  return {
    searchData,
    type: actionTypes.SEARCH_LEGAL_SET_DATA,
  };
};
export const get = (id) => {
  return {
    id,
    type: actionTypes.SEARCH_LEGAL_GET,
  };
};
export const getStart = () => {
  return {
    type: actionTypes.SEARCH_LEGAL_GET_START,
  };
};
export const getComplete = (params) => {
  return {
    ...params,
    type: actionTypes.SEARCH_LEGAL_GET_COMPLETE,
  };
};
export const save = (params) => {
  return {
    ...params,
    type: actionTypes.SEARCH_LEGAL_SAVE,
  };
};
export const saveStart = () => {
  return {
    type: actionTypes.SEARCH_LEGAL_SAVE_START,
  };
};
export const saveComplete = (params) => {
  return {
    ...params,
    type: actionTypes.SEARCH_LEGAL_SAVE_COMPLETE,
  };
};
export const saveAs = (config) => {
  return {
    config,
    type: actionTypes.SEARCH_LEGAL_SAVE_AS,
  };
};
export const saveAsStart = () => {
  return {
    type: actionTypes.SEARCH_LEGAL_SAVE_AS_START,
  };
};
export const saveAsComplete = (params) => {
  return {
    ...params,
    type: actionTypes.SEARCH_LEGAL_SAVE_AS_COMPLETE,
  };
};
export const resetState = () => {
  return {
    type: actionTypes.SEARCH_LEGAL_RESET_STATE,
  };
};
export const searchToggleAutoCollapse = (param) => {
  return {
    isAutoCollapseOn: param,
    type: actionTypes.SEARCH_LEGAL_TOGGLE_AUTO_COLLAPSE,
  };
};
