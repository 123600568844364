import _get from "lodash.get";
import _set from "lodash.set";
import _cloneDeep from "lodash.clonedeep";
import _mergewith from "lodash.mergewith";
import _pick from "lodash.pick";
import _invert from "lodash.invert";
import { v4 as uuidV4 } from "uuid";

import yup from "@services/yup";
import {
  defaultCriteria,
  validationSchema,
  locationItem,
  refineFilterMapKeys,
} from "@config/criteria";

export const fieldMapping = {
  lastRefresh: "lastRefresh",
  mustHaveSkills: "mustHave",
  niceToHaveSkills: "likeToHave",
  functionalExperience: "functionExp",
  locations: "locations",
  productDomain: "productDomain",
  companyIndustries: "companyIndustries",
  educationDegree: "educationDegree",
  includeCompanies: "targetCompanies",
  excludeCompanies: "excludedCompanies",
  companySizes: "companySizes",
  companyType: "companyType",
  excludeFromActiveRequisition: "excludeFromActiveRequisition",
  excludeFromExportedCandidates: "excludeFromExportedCandidates",
  totalExperience: "totalYearsOfExp",
  currentTenure: "currentTenure",
  averageTenure: "averageTenure",
  seniorityExperience: "seniorityExp",
  educationMajors: "educationMajors",
  gender: "gender",
  ethnicity: "ethnicity",
  veteran: "veteran",
  jobTitles: "jobTitles",
  companySpecialization: "companySpecialization",
  "securityClearanceSearch.clearanceLevel": "clearanceLevels",
  "securityClearanceSearch.addOn": "clearanceAddOns",
  "securityClearanceSearch.inferred": "clearanceInclusions.inferred",
  "securityClearanceSearch.eligible": "clearanceInclusions.eligible",
  // 'managementExperience.function': 'seniorityExp.function',
  // 'managementExperience.range.min': 'seniorityExp.range.min',
  // 'managementExperience.range.max': 'seniorityExp.range.max'
  // requisitionFilter: 'requisitionFilter',
  // 'sortOrder.sort': 'sortOrder.sort',
  // 'sortOrder.descending': 'sortOrder.descending'
};

export const validate = (value) => {
  const schema = yup.object().shape(validationSchema);
  return schema.validateSync(value, { strict: true });
};

export const mapStateToApiSchema = (values = {}) => {
  const mappedData = {};
  Object.keys(fieldMapping).forEach((key) => {
    const value = _get(values, fieldMapping[key]);
    _set(mappedData, key, value);
  });

  return mappedData;
};

export const mapApiToStateSchema = (values = {}) => {
  const criteria = _cloneDeep(defaultCriteria);
  Object.keys(fieldMapping).forEach((key) => {
    const value = _get(values, key);
    if (typeof value !== "undefined") {
      _set(criteria, fieldMapping[key], value);
    }
  });

  return criteria;
};

export const getCriteriaFromSearchData = (searchData) => {
  const search = _cloneDeep(searchData);
  return mapApiToStateSchema(_get(search, "parameters", {}));
};

// for deperecattion, used helper/collection/mergeObject instead
export const mergeCriteria = (...args) => {
  return _mergewith(...args, (dest, src) => {
    if (Array.isArray(dest || src)) {
      return src;
    }
  });
};

export const generateCompanyItem = () => ({
  id: uuidV4(),
  name: "",
});

export const generateLocationItem = () => ({
  ...locationItem,
  location: uuidV4(),
});

export const mapCriteriaToStateSchema = (criteria) => {
  const data = {};
  const src = _cloneDeep(criteria);
  const mapToCompanySchema = ({ id, name }) => ({ id, name });

  src.includeCompanies.companies = src.includeCompanies.companies.map(
    mapToCompanySchema
  );
  src.excludeCompanies.companies = src.excludeCompanies.companies.map(
    mapToCompanySchema
  );
  src.locations = src.locations.map(({ location, option, distance }) => ({
    location,
    option,
    distance,
  }));

  Object.keys(fieldMapping).forEach((key) => {
    const value = _get(src, key, defaultCriteria[key]);
    _set(data, fieldMapping[key], value);
  });

  return data;
};

export const getEducDegreesFromMin = (minId, educDegreeList) => {
  const indexOfEducDegree = educDegreeList.findIndex(({ id }) => minId === id);
  const educList =
    indexOfEducDegree > -1 ? educDegreeList.slice(indexOfEducDegree) : [];
  return educList.map(({ id }) => id);
};

export const generateRefineFilters = (criteria, { educDegreeList }) => {
  const getIds = ({ id }) => id;
  return {
    mustHaves: [],
    likeToHaves: [],
    likelyToJump: false,
    seniorities: _get(criteria, "seniorityExp.seniorities", []).map(getIds),
    industries: _get(criteria, "companyIndustries.industries", []).map(getIds),
    companySizes: _get(criteria, "companySizes.sizes", []).map(getIds),
    specializations: _get(
      criteria,
      "companySpecialization.specializations",
      []
    ).map((spec, i) => i),
    educationDegrees: getEducDegreesFromMin(
      criteria.educationDegree.id,
      educDegreeList
    ),
    educationMajors: _get(criteria, "educationMajors", []).map(getIds),
    ethnicity: _get(criteria, "ethnicity", []).map(getIds),
    gender: _get(criteria, "gender", []).map(getIds),
    clearanceLevels: _get(criteria, "clearanceLevels", []).map(getIds),
    clearanceAddOns: _get(criteria, "clearanceAddOns", []).map(getIds),
  };
};

export const setCriteriaFilters = (criteria, filters) => {
  criteria.likelyToJumpOnly = filters.likelyToJump;
  criteria.seniorityExperience.refined = filters.seniorities;
  criteria.companyIndustries.refined = filters.industries;
  criteria.companySizes.refined = filters.companySizes;
  criteria.refinedEducationMajors = filters.educationMajors;
  criteria.refinedEducationDegrees = filters.educationDegrees;
  criteria.refinedEthnicity = filters.ethnicity;
  criteria.refinedGender = filters.gender;
  criteria.securityClearanceSearchRefined = {
    clearanceLevel: filters.clearanceLevels,
    addOn: filters.clearanceAddOns,
  };

  if (filters.mustHaves) {
    filters.mustHaves.forEach((i) => {
      _set(criteria, `mustHaveSkills.${i}.refined`, true);
    });
  }

  if (filters.likeToHaves) {
    filters.likeToHaves.forEach((i) => {
      _set(criteria, `niceToHaveSkills.${i}.refined`, true);
    });
  }

  if (filters.specializations) {
    const specializations = criteria.companySpecialization.specializations.map(
      (spec, i) => ({
        ...spec,
        refined: filters.specializations.includes(i) ? true : false,
      })
    );
    criteria.companySpecialization = {
      ...criteria.companySpecialization,
      specializations,
    };
  }

  return criteria;
};

export const mapCriteriaToApiSchema = (criteria, filters) => {
  const src = _cloneDeep(criteria);
  const extractIds = (entries, entry) =>
    entry.name ? [...entries, entry.id] : entries;
  const mapToIds = ({ id }) => id;
  let data = {};
  src.targetCompanies.companies = src.targetCompanies.companies.reduce(
    extractIds,
    []
  );
  src.excludedCompanies.companies = src.excludedCompanies.companies.reduce(
    extractIds,
    []
  );
  src.locations = src.locations.reduce(
    (locations, item) => (item.option ? [...locations, item] : locations),
    []
  );
  src.educationDegree = src.educationDegree.id;
  src.educationMajors = src.educationMajors.map(({ id }) => id);
  src.companySizes.sizes = src.companySizes.sizes.map(({ id }) => id);
  src.companyIndustries.industries = src.companyIndustries.industries.map(
    ({ id }) => id
  );
  src.seniorityExp.seniorities = src.seniorityExp.seniorities.map(
    ({ id }) => id
  );
  src.ethnicity = src.ethnicity.map(mapToIds);
  src.gender = src.gender.map(mapToIds);
  src.clearanceLevels = src.clearanceLevels.map(mapToIds);
  src.clearanceAddOns = src.clearanceAddOns.map(mapToIds);

  Object.keys(fieldMapping).forEach((key) => {
    const value = _get(src, fieldMapping[key]);
    _set(data, key, value);
  });

  if (filters) {
    data = setCriteriaFilters(data, filters);
  }

  return data;
};

export const populateSearchCriteria = (search) => {
  const mapProps = ({ id, name }) => ({ id, name });
  const criteria = {
    ..._cloneDeep(search.parameters),
    locations: _get(search, "extras.locations", []).map(
      ({ distance, location, option }) => ({
        location,
        option,
        distance: (distance || "").toString(),
      })
    ),
  };
  _set(
    criteria,
    "includeCompanies.companies",
    _get(search, "extras.includeCompanies", []).map(mapProps)
  );
  _set(
    criteria,
    "excludeCompanies.companies",
    _get(search, "extras.excludeCompanies", []).map(mapProps)
  );
  _set(
    criteria,
    "functionalExperience",
    _get(search, "extras.departmentFunctions", []).map(mapProps)
  );
  _set(
    criteria,
    "educationMajors",
    _get(search, "extras.educationMajors", []).map(mapProps)
  );
  _set(
    criteria,
    "educationDegree",
    _get(search, "extras.educationDegree") || defaultCriteria.educationDegree
  );

  if (!criteria.seniorityExperience) {
    _set(criteria, "seniorityExperience", defaultCriteria.seniorityExp);
  }
  _set(
    criteria,
    "seniorityExperience.seniorities",
    _get(search, "extras.seniorities", []).map(mapProps)
  );

  _set(
    criteria,
    "companySizes.sizes",
    _get(search, "extras.companySizes", []).map(mapProps)
  );
  _set(
    criteria,
    "companyIndustries.industries",
    _get(search, "extras.industries", []).map(mapProps)
  );
  criteria.ethnicity =
    _get(search, "extras.ethnicity") || defaultCriteria.ethnicity;
  criteria.gender = _get(search, "extras.gender") || defaultCriteria.gender;
  criteria.veteran =
    _get(search, "extras.isVeteran") || defaultCriteria.veteran;
  criteria.companySpecialization =
    criteria?.companySpecialization || defaultCriteria.companySpecialization;

  const setAbbreviation = (v) => {
    const abbrev = v.abbreviation ? `(${v.abbreviation}) ` : "";
    return {
      id: v.clearanceId,
      name: abbrev + v.name,
    };
  };

  const clearanceLevel = (_get(search, "extras.clearanceLevel") || []).map(
    setAbbreviation
  );
  _set(criteria, "securityClearanceSearch.clearanceLevel", clearanceLevel);

  const secClearanceAddon = (_get(search, "extras.clearanceAddOn") || []).map(
    setAbbreviation
  );
  _set(criteria, "securityClearanceSearch.addOn", secClearanceAddon);

  return criteria;
};

export const encodeRefineFilter = (filters) => {
  const encodedFilters = {};

  Object.keys(filters).forEach((key) => {
    const propKey = refineFilterMapKeys[key];
    encodedFilters[propKey] = filters[key];
  });

  return encodedFilters;
};

export const decodeRefineFilter = (data) => {
  const filtersObject = _cloneDeep(data);
  const encodedFilters = _pick(
    filtersObject,
    Object.values(refineFilterMapKeys)
  );
  const mapping = _invert(refineFilterMapKeys);
  const decodedFilters = {};

  Object.keys(encodedFilters).forEach((key) => {
    const propKey = mapping[key];
    decodedFilters[propKey] = encodedFilters[key];
  });

  if (decodedFilters.mustHaves) {
    decodedFilters.mustHaves = decodedFilters.mustHaves.map((i) => parseInt(i));
  }

  if (decodedFilters.likeToHaves) {
    decodedFilters.likeToHaves = decodedFilters.likeToHaves.map((i) =>
      parseInt(i)
    );
  }

  if (decodedFilters.specializations) {
    decodedFilters.specializations = decodedFilters.specializations.map((i) =>
      parseInt(i)
    );
  }

  if (decodedFilters.ethnicity) {
    decodedFilters.ethnicity = decodedFilters.ethnicity.map((i) => parseInt(i));
  }

  if (decodedFilters.gender) {
    decodedFilters.gender = decodedFilters.gender.map((i) => parseInt(i));
  }

  return decodedFilters;
};

export const countRefineFilters = (filters, groupCount, license = {}) => {
  const filtersCount = [
    filters.likelyToJump ? 1 : 0,
    _get(filters, "mustHaves.length", 0),
    _get(filters, "likeToHaves.length", 0),
    _get(filters, "seniorities.length", 0),
    _get(filters, "industries.length", 0),
    _get(filters, "companySizes.length", 0),
    _get(filters, "educationDegrees.length", 0),
    _get(filters, "educationMajors.length", 0),
    _get(filters, "specializations.length", 0),
  ];

  if (license.securityLicenseEnabled) {
    filtersCount.push(
      _get(filters, "clearanceLevels.length", 0),
      _get(filters, "clearanceAddOns.length", 0)
    );
  }

  if (license.diversityEnabled) {
    filtersCount.push(
      _get(filters, "ethnicity.length", 0),
      _get(filters, "gender.length", 0)
    );
  }

  if (groupCount) {
    return filtersCount.filter((item) => !!item).length;
  } else {
    return filtersCount.reduce((partial, n) => partial + n, 0);
  }
};

export const getDraftSearchName = (criteria) => {
  const { jobTitles, mustHaveSkills, locations } = criteria;
  const { includedCompanies } = criteria.extras || {};

  if (jobTitles?.length) {
    return jobTitles[0];
  } else if (mustHaveSkills?.length) {
    return mustHaveSkills[0].name;
  } else if (locations?.length) {
    return locations.reverse()[0].option;
  } else if (includedCompanies?.length) {
    return includedCompanies[0]?.name;
  }

  return "";
};

export const getMandatoryFields = (internalUser) => {
  const mandatoryFields = ["Job Title", "Must Have"];

  if (internalUser) {
    mandatoryFields.push("Targeted Company", "Location");
  }

  return mandatoryFields;
};

export default {
  validate,
  mergeCriteria,
  mapApiToStateSchema,
  mapStateToApiSchema,
  generateCompanyItem,
};
