import React, { useState, useRef } from "react";
import { Icon, Carousel as AntdCarousel } from "antd";
import cs from "classnames";

const Carousel = (props) => {
  const { slides, className, ...restProps } = props;
  const [currentSlide, setCurrentSlide] = useState(0);
  const ref = useRef();

  const handleCurrentSlide = (_, to) => {
    setCurrentSlide(to);
  };

  const handleSlideChange = (slide) => {
    ref.current.goTo(slide, false);
  };

  return (
    <>
      <AntdCarousel
        dots={false}
        {...restProps}
        ref={ref}
        className={cs("hh-carousel", className)}
        beforeChange={handleCurrentSlide}
        afterChange={handleSlideChange}
      >
        {slides.map((carousel, index) => (
          <div key={`slide-${index}`}>
            <div className="slide-container">
              <div className="content-container">
                <img
                  src={carousel.image}
                  alt={`image-step-${index}`}
                  className="img-responsive"
                />
              </div>
            </div>
          </div>
        ))}
      </AntdCarousel>
      <div className="hh-carousel-nav">
        <ul className="slick-dots">
          <li key="prev" className={cs({ disabled: currentSlide === 0 })}>
            <span onClick={() => ref.current.prev()}>
              <Icon type="arrow-left" />
            </span>
          </li>
          {slides.map((_, index) => (
            <li
              key={`navigation-${index}`}
              className={cs({ "slick-active": currentSlide === index })}
            >
              <button onClick={() => handleSlideChange(index)} />
            </li>
          ))}
          <li
            key="next"
            className={cs({ disabled: currentSlide === slides.length - 1 })}
          >
            <span onClick={() => ref.current.next()}>
              <Icon type="arrow-right" />
            </span>
          </li>
        </ul>
      </div>
      <div className="hh-carousel-info">
        {slides.map((carousel, index) => (
          <div
            key={`content-${index}`}
            className={cs("content", { active: currentSlide === index })}
          >
            {carousel.title && <p>{carousel.title}</p>}
            {carousel.description && (
              <div
                className="content-container"
                dangerouslySetInnerHTML={{ __html: carousel.description }}
              />
            )}
          </div>
        ))}
      </div>
    </>
  );
};

Carousel.defaultProps = {
  slides: [],
};

export default Carousel;
