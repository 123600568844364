import apiResource from '@config/resource';

export const searchAndRequisition = (mock) => {
  mock.onPost(apiResource.transactionalOperations).reply(config => {
    config.data = JSON.parse(config.data);
    let data = {};
    if (config.data.type === 'SEARCH_AND_REQUISITION') {
      data = {
        search: {
          id: 1000
        },
        requisition: {
          id: 1000
        }
      };
    }

    return [201, data];
  });
};

export default [
  // searchAndRequisition
];