import { takeLatest, all, put } from "redux-saga/effects";
import Axios from "axios";

import resource from "@config/resource";
import {
  getConfigStart,
  getConfigComplete,
  saveConfigStart,
  saveConfigComplete,
} from "../actions/ats/";
import platformApi from "@services/platform-api/";
import actionTypes from "@store/actions/ats/actionTypes";
import { SUCCESS, FAIL } from "@config/constants";
import AtsService from "@services/ats";

function* getConfig(args) {
  const { companyId } = args;
  yield put(getConfigStart());

  const state = {};

  try {
    const { data } = yield platformApi.get(resource.ats + "/configuration", {
      params: {
        company: companyId,
      },
    });
    state.config = data;
    state.steps = AtsService.getStepDetails(data.atsType, data);
    state.getConfig = {
      status: SUCCESS,
      error: {},
    };
  } catch (e) {
    state.getConfig = {
      status: FAIL,
      error: Axios.isAxiosError(e) ? e.toJSON() : e,
    };
  }

  yield put(getConfigComplete(state));
}

function* saveConfig(args) {
  const { companyId, config, step = "" } = args;
  yield put(saveConfigStart());
  const state = {};

  try {
    const { data } = yield platformApi.put(
      resource.ats + "/configuration/" + step,
      config,
      {
        params: {
          company: companyId,
        },
      }
    );
    state.config = data;
    state.steps = AtsService.getStepDetails(data.atsType, data);
    state.saveConfig = {
      status: SUCCESS,
      error: {},
    };
  } catch (e) {
    state.saveConfig = {
      status: FAIL,
      error: Axios.isAxiosError(e) ? e.toJSON() : e,
    };
  }

  yield put(saveConfigComplete(state));
}

export default function* sagas() {
  yield all([
    takeLatest(actionTypes.ATS_GET_CONFIG, getConfig),
    takeLatest(actionTypes.ATS_SAVE_CONFIG, saveConfig),
  ]);
}
