import _cloneDeep from "lodash.clonedeep";

import { LOADING, IS_AUTO_COLLAPSE_ON } from "@config/constants";
import actionTypes from "../actions/action-types";
import { DEFAULT_STATE } from "../../constants/store";

const defaultState = {
  ...DEFAULT_STATE,
  navigationPanel: {
    isAutoCollapseOn: localStorage.getItem(IS_AUTO_COLLAPSE_ON) === "true",
  },
};

export default (state = _cloneDeep(defaultState), action = {}) => {
  const { type, ...args } = action;

  switch (type) {
    case actionTypes.SEARCH_LEGAL_SET_DATA:
      return {
        ...state,
        searchData: {
          ...state.searchData,
          ...args.searchData,
        },
      };
    case actionTypes.SEARCH_LEGAL_GET_START:
      return {
        ...state,
        get: {
          status: LOADING,
          response: {},
        },
      };
    case actionTypes.SEARCH_LEGAL_GET_COMPLETE:
      return {
        ...state,
        searchData: {
          ...state.searchData,
          ...args.searchData,
        },
        get: {
          status: args.status,
          response: {
            ...state.get.response,
            ...args.response,
          },
        },
      };
    case actionTypes.SEARCH_LEGAL_SAVE_START:
      return {
        ...state,
        save: {
          status: LOADING,
          response: {},
        },
      };
    case actionTypes.SEARCH_LEGAL_SAVE_COMPLETE:
      return {
        ...state,
        searchData: {
          ...state.searchData,
          ...args?.searchData,
        },
        save: {
          status: args.status,
          response: {
            ...state.save.response,
            ...args?.response,
          },
        },
      };
    case actionTypes.SEARCH_LEGAL_RESET_STATE:
      return _cloneDeep(defaultState);
    case actionTypes.SEARCH_LEGAL_TOGGLE_AUTO_COLLAPSE:
      return {
        ...state,
        navigationPanel: {
          isAutoCollapseOn: args.isAutoCollapseOn,
        },
      };
    default:
      return state;
  }
};
