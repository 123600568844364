import appRoutes from '@config/app-routes';

export const hasAccess = (userAssignedRoles, roleKeys) => {
  roleKeys = Array.isArray(roleKeys) ? roleKeys : [roleKeys];
  return userAssignedRoles.some(key => roleKeys.includes(key));
};

export const generateUserRouteLink = (hhPrivate, link) => {
  const baseUrl = hhPrivate ? appRoutes.hhUser : appRoutes.userAccount;
  link = link.startsWith('/') ? link : `/${link}`;
  return baseUrl + link;
};

export const getRefreshToken = () => localStorage.getItem('refreshToken');

export default {
  hasAccess
};