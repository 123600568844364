import React, { memo } from "react";
import { useSelector } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import _flow from "lodash.flow";
import classnames from "classnames";

import appRoutes from "@config/app-routes";
import authSvc from "@services/auth/";
import legalSearchRoute from "../../../search-legal/config/routes";
import legalPeopleRoute from "../../../people-legal/config/routes";
import {
  ROLE_MENU_ACCESS_SEARCH,
  ROLE_MENU_ACCESS_REQS,
  ROLE_MENU_ACCESS_SEARCH_LEGAL,
  ROLE_MENU_ACCESS_DOWNLOADS,
  ROLE_MENU_ACCESS_COMMUNICATION,
  ROLE_MENU_ACCESS_LOOK_UP,
} from "@config/authorities";
import { MODULES } from "@config/constants";
import { licenseSelector } from "../../../store/selectors/user";
import { communicationLicenseCheck } from "../../../helpers/license";

export const NavItem = ({ className, ...props }) => {
  return <NavLink className={classnames("nav-item", className)} {...props} />;
};

const defaultNavItems = () => {
  return (
    <NavItem exact to="/">
      <span className="hhi-home nav-icon" /> Home
    </NavItem>
  );
};
export const DefaultNavItems = memo(defaultNavItems);

const talentSoucingNaveItems = (props) => {
  const userLicense = useSelector(licenseSelector);
  const searchPageAccess = authSvc.hasAccess(ROLE_MENU_ACCESS_SEARCH);
  const lookupPageAccess = authSvc.hasAccess(ROLE_MENU_ACCESS_LOOK_UP);

  const onPageNavigate = (e) => {
    const href = e.target.getAttribute("href");
    if (href === props.location.pathname) {
      e.preventDefault();
    }
  };

  return (
    <>
      {authSvc.hasAccess(ROLE_MENU_ACCESS_REQS) && (
        <NavItem to={appRoutes.requisition}>
          <span className="hhi-reqs nav-icon" /> Reqs
        </NavItem>
      )}
      {(searchPageAccess || lookupPageAccess) && (
        <div className="group-nav">
          <NavItem to={appRoutes.search}>
            <span className="hhi-magnify nav-icon" /> Search{" "}
            <span className="hhi-expand-more sn-icon" />
          </NavItem>
          <div className="sub-nav search">
            {searchPageAccess && (
              <NavLink to={appRoutes.searchEdit} onClick={onPageNavigate}>
                Quick Search
              </NavLink>
            )}
            {lookupPageAccess && (
              <NavLink exact to={appRoutes.candidateLookup}>
                Candidate Lookup
              </NavLink>
            )}
            {searchPageAccess && (
              <NavLink exact to={appRoutes.search}>
                My Searches
              </NavLink>
            )}
          </div>
        </div>
      )}
      {authSvc.hasAccess(ROLE_MENU_ACCESS_COMMUNICATION) &&
        communicationLicenseCheck(userLicense) && (
          <NavItem to={appRoutes.communication}>
            <span className="hhi-contact-inout nav-icon" /> COMMUNICATION
          </NavItem>
        )}
    </>
  );
};

export const TalentSoucingNaveItems = _flow([memo, withRouter])(
  talentSoucingNaveItems
);

const legalNaveItems = () => {
  return (
    <>
      {authSvc.hasAccess(ROLE_MENU_ACCESS_SEARCH_LEGAL) && (
        <div className="group-nav">
          <NavItem to={legalSearchRoute.search}>
            <span className="hhi-magnify nav-icon" /> Search{" "}
            <span className="hhi-expand-more sn-icon" />
          </NavItem>
          <div className="sub-nav search">
            <NavLink to={legalSearchRoute.search + "/new"}>
              Quick Search
            </NavLink>
            <NavLink exact to={legalSearchRoute.lookup}>
              People Lookup
            </NavLink>
            <NavLink exact to={legalSearchRoute.search}>
              My Searches
            </NavLink>
          </div>
        </div>
      )}
      {authSvc.hasAccess(ROLE_MENU_ACCESS_DOWNLOADS) && (
        <NavItem to={legalPeopleRoute.downloadList}>
          <span className="hhi-downloads nav-icon" /> Downloads
        </NavItem>
      )}
    </>
  );
};

export const LegalNaveItems = memo(legalNaveItems);

export const navItems = {
  [MODULES.TALENT_SOURCING]: TalentSoucingNaveItems,
  [MODULES.WITNESS_FINDER]: LegalNaveItems,
};
