import actionTypes from "../actions/search/actionTypes";
import _cloneDeep from "lodash.clonedeep";
import _merge from "lodash.merge";
import { LOADING, IS_AUTO_COLLAPSE_ON } from "@config/constants";

let isAutoCollapseOn = true;

if (localStorage.getItem(IS_AUTO_COLLAPSE_ON))
  isAutoCollapseOn = localStorage.getItem(IS_AUTO_COLLAPSE_ON) === "true";

const defaultState = {
  data: {
    name: "",
  },
  sourceData: {},
  get: {
    status: "",
    notif: {
      message: "",
      type: "",
    },
  },
  save: {
    status: "",
    notif: {
      message: "",
      type: "",
    },
  },
  saveAs: {
    status: "",
    notif: {
      message: "",
      type: "",
    },
  },
  requisition: {
    data: {},
    status: "",
    notif: {
      message: "",
      type: "",
    },
  },
  navigationPanel: {
    isAutoCollapseOn,
  },
  refineSearch: {
    status: "",
    count: undefined,
    requestId: undefined,
  },
};

export default (state = _cloneDeep(defaultState), action = {}) => {
  const { type, ...args } = action;
  const newState = _cloneDeep(state);
  switch (type) {
    case actionTypes.SEARCH_SET_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          ...args.data,
        },
      };
    case actionTypes.SEARCH_GET_START:
      return {
        ...state,
        get: {
          ...state.get,
          status: LOADING,
        },
      };
    case actionTypes.SEARCH_GET_COMPLETE:
      return {
        ...state,
        data: {
          ...args.data,
        },
        requisition: {
          ...state.requisition,
          data: args.requisition,
        },
        get: {
          ...state.get,
          ...args.get,
        },
      };
    case actionTypes.SEARCH_SAVE_START:
      return {
        ...state,
        save: {
          ...state.save,
          status: LOADING,
        },
      };
    case actionTypes.SEARCH_SAVE_COMPLETE:
      return {
        ...state,
        data: {
          ...state.data,
          ...args.data,
        },
        save: {
          ...state.save,
          ...args.save,
          notif: {
            ...state.save.notif,
            ...args.save.notif,
          },
        },
      };
    case actionTypes.SEARCH_SAVE_AS_START:
      return _merge(newState, {
        saveAs: {
          status: LOADING,
        },
      });
    case actionTypes.SEARCH_SAVE_AS_COMPLETE:
      return _merge(newState, args.state);
    case actionTypes.SEARCH_ASSIGN_REQ_START:
      return {
        ...state,
        requisition: {
          ...state.requisition,
          status: args.isNew ? "" : "loading",
        },
      };
    case actionTypes.SEARCH_ASSIGN_REQ_COMPLETE:
      return {
        ...state,
        requisition: {
          data: {
            ...args.data,
          },
          status: "success",
          notif: {
            ...args.notif,
          },
        },
      };
    case actionTypes.SEARCH_NEW_REQ_COMPLETE:
      return {
        ...state,
        data: {
          ...state.data,
          requisition: args,
        },
      };
    case actionTypes.SEARCH_COPY_REQ_COMPLETE:
      return {
        ...state,
        requisition: {
          data: {
            ...state.data,
          },
          status: "copied",
          notif: {
            ...state.notif,
          },
        },
      };
    case actionTypes.SEARCH_SET_DEFAULT:
      return defaultState;
    case actionTypes.SEARCH_SET_REQUISITION:
      return {
        ...state,
        data: {
          ...state.data,
          requisition: {
            ...args.requisition,
          },
        },
      };
    case actionTypes.SEARCH_SET_NOTIF:
      newState.save.notif = args.notif;
      return newState;
    case actionTypes.SEARCH_RESET_STATE:
      return _cloneDeep(defaultState);
    case actionTypes.SEARCH_KEYWORD_MATCH_START:
      return {
        ...state,
        data: {
          ...state.data,
          status: LOADING,
        },
      };
    case actionTypes.SEARCH_KEYWORD_MATCH_COMPLETE:
      return {
        ...state,
        data: {
          ...state.data,
          ...args.data,
          status: "success",
        },
      };
    case actionTypes.SEARCH_TOGGLE_AUTO_COLLAPSE:
      return {
        ...state,
        navigationPanel: {
          isAutoCollapseOn: args.isAutoCollapseOn,
        },
      };
    case actionTypes.SEARCH_UPDATE_REFINE_SEARCH_RESULT_COUNT:
      return {
        ...state,
        refineSearch: {
          ...state.refineSearch,
          ...args.data,
        },
      };
    default:
      return state;
  }
};
