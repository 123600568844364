import React from "react";
import axios from "axios";
import moment from "moment";
import _get from "lodash.get";
import { message, notification } from "antd";
import Logger from "@services/logger";

import { getRefreshToken } from "@helpers/auth";
import resource from "@config/resource";
import configCollection from "@config/collection";
import reduxStore from "@store/configure";
import { logout, auth } from "@store/actions/user/";
import { InfoMsgNotif } from "@components/shared/MessageNotif/MessageNotif";

const baseURL = process.env.PLATFORM_API_URL;
const {
  auth: { unauthorizedNotifKey },
} = configCollection;
const axiosInstance = axios.create({ baseURL });
const tokenCheckInterval = 5000;

export const checkTokenExp = () => {
  const tokenExp = localStorage.getItem("tokenExp");
  if (tokenExp) {
    const timeDiff = moment(tokenExp).diff(moment(), "seconds");
    // timediff is less than or equal to 2 mins
    if (timeDiff <= 120) {
      refreshToken();
    } else {
      clearTimeout(axiosInstance.checkTokenInterval);
      axiosInstance.checkTokenInterval = setTimeout(
        checkTokenExp,
        tokenCheckInterval
      );
    }
  }
};

export const refreshToken = async (notify = false) => {
  axiosInstance.refreshTokenRequest = true;

  try {
    const params = {
      scope: "any",
      grant_type: "refresh_token",
      refresh_token: getRefreshToken(),
    };
    const payload = new URLSearchParams();
    Object.keys(params).forEach((key) => {
      payload.append(key, params[key]);
    });
    const uri = new URL(resource.oauthToken, baseURL);
    const { data } = await axiosInstance.post(uri.toString(), payload, {
      auth: {
        username: "webapp",
        password: "webapp_secret",
      },
    });

    reduxStore.dispatch(auth(data));

    if (notify) {
      message.open({
        key: "USER_REFRESH_TOKEN",
        content: (
          <InfoMsgNotif>
            We have renewed your session. Please try again.
          </InfoMsgNotif>
        ),
      });
    }
    axiosInstance.refreshTokenRequest = false;
  } catch (error) {
    axiosInstance.refreshTokenRequest = false;
    const status = _get(error, "response.status");
    const errorCode = _get(error, "response.data.error");

    if (status === 401) {
      let message = "Login Required";
      let description =
        "You are signed out of your Headhuntr account. It's either you are using an expired or invalid session.";

      if (errorCode === "MULTIPLE_LOGINS_NOT_ALLOWED") {
        message = "Session Terminated";
        description =
          "You are signed out as we detected a new login using your account.";
      }

      reduxStore.dispatch(logout({ api: false }));
      notification.open({
        message,
        description,
        key: unauthorizedNotifKey,
      });
      return;
    }

    setTimeout(() => {
      refreshToken(true);
    }, tokenCheckInterval);
  }
};

axiosInstance.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem("accessToken");
  if (accessToken) {
    axiosInstance.unauthorizedNotif = false;
    notification.close(unauthorizedNotifKey);
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const status = _get(error, "response.status");
    const configUrl = _get(error, "response.config.url");

    if (
      status === 401 &&
      configUrl !== resource.oauthToken &&
      !axiosInstance.refreshTokenRequest
    ) {
      if (_get(error, "response.data.error") !== "INVALID_GRANT") {
        refreshToken(true);
      }
    }

    if (status >= 500) {
      Logger.captureException(error);
    }

    if (!axios.isCancel(error)) {
      const customMessage = _get(
        error,
        "response.data.message",
        "Something went wrong."
      );
      error.apiMessage = customMessage;
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
