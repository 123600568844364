import React, { Suspense, Component } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import _get from "lodash.get";
import _flow from "lodash.flow";

import "@assets/styles/index.scss";
import resource from "@config/resource";
import { getRefreshToken } from "@helpers/auth";
import { getData as userGetData } from "@store/actions/user/";
import { getData as resourceGetData } from "@store/actions/resource/";
import appRoutes from "@config/app-routes";

import platformApiSvc, { refreshToken } from "@services/platform-api/";
import {
  UnauthorizedPageAccess,
  UnauthorizedRecordAccess,
  PageNotFound,
} from "@components/shared/PageHandler/PageHandler";
import ComponentLoader, {
  PageLoader,
} from "@components/shared/ComponentLoader/ComponentLoader";
import DefaultLayout from "@components/Layout/Container/Default";
import SecureRoute from "@containers/SecureRoute/SecureRoute";
import Poc1 from "@components/Poc/Dnd/index";
import Poc2 from "@components/Poc/Dnd2/index";
import SearchLegalSecureRoute from "./search-legal/routes/secure";
import PeopleLegalSecureRoute from "./people-legal/routes/secure";

const Home = ComponentLoader({
  loader: () => import("@containers/Home/Home"),
});
const Dashboard = ComponentLoader({
  loader: () => import("@containers/Dashboard/Dashboard"),
});
const Logout = ComponentLoader({
  loader: () => import("@containers/Logout/Logout"),
});
const Search = ComponentLoader({
  loader: () => import("@containers/Search/Search"),
});
const Requisition = ComponentLoader({
  loader: () => import("@containers/Requisition/Requisition"),
});
const Searches = ComponentLoader({
  loader: () => import("@containers/Searches/Searches"),
});
const Lookup = ComponentLoader({
  loader: () => import("@containers/Candidate/Lookup"),
});
const LookupProfile = ComponentLoader({
  loader: () => import("@containers/Candidate/LookupProfile"),
});
const UserList = ComponentLoader({
  loader: () => import("@containers/Candidate/UserList/UserList"),
});
const Task = ComponentLoader({
  loader: () => import("@containers/Task/Task"),
});
const CandidateProfile = ComponentLoader({
  loader: () => import("@containers/Candidate/Profile"),
});
const MyProfile = ComponentLoader({
  loader: () => import("@containers/UserAccount/MyProfile"),
});
const Login = ComponentLoader({
  loader: () => import("@containers/Login/Login"),
  type: "page",
});
const TaskDetail = ComponentLoader({
  loader: () => import("@containers/Task/Detail"),
});
const UserAccountList = ComponentLoader({
  loader: () => import("@containers/UserAccount/List"),
});
const UserAdminList = ComponentLoader({
  loader: () => import("@containers/UserAdmin/List"),
});
const UserAccountDetail = ComponentLoader({
  loader: () => import("@containers/UserAccount/Detail"),
});
const CompanyAccountDetail = ComponentLoader({
  loader: () => import("@containers/CompanyAccount/Detail"),
});
const CompanyReadDetail = ComponentLoader({
  loader: () => import("@containers/CompanyAccount/ReadDetail"),
});
const SecurityRoleList = ComponentLoader({
  loader: () => import("@containers/SecurityRole/List"),
});
const SecurityRoleDetail = ComponentLoader({
  loader: () => import("@containers/SecurityRole/Detail"),
});
const ChangePassword = ComponentLoader({
  loader: () => import("@containers/ChangePassword"),
});
const SetPassword = ComponentLoader({
  loader: () => import("@containers/SetPassword"),
});
const ForgotPassword = ComponentLoader({
  loader: () => import("@containers/SetPassword/ForgotPassword"),
});
const UserAgreement = ComponentLoader({
  loader: () => import("@containers/UserAgreement/UserAgreement"),
});
const LicensePlanDetail = ComponentLoader({
  loader: () => import("@containers/LicensePlan/Detail"),
});
const LicensePlanList = ComponentLoader({
  loader: () => import("@containers/LicensePlan/List"),
});
const UnderMaintenance = ComponentLoader({
  loader: () => import("@containers/UnderMaintenance/UnderMaintenance"),
  type: "page",
});
const Ats = ComponentLoader({
  loader: () => import("@containers/Ats/Ats"),
  type: "page",
});
const AuthCallback = ComponentLoader({
  loader: () => import("@containers/Integrations/Lever/AuthCallback"),
  type: "page",
});
const AutoDialer = ComponentLoader({
  loader: () => import("@containers/AutoDialer/AutoDialer"),
  type: "page",
});
const Communication = ComponentLoader({
  loader: () => import("@containers/Communication/Communication"),
  type: "page",
});
const FulFillRequest = ComponentLoader({
  loader: () => import("@containers/FulFillRequest/FulFillRequest"),
  type: "page",
});

class App extends Component {
  async componentDidMount() {
    const { history, location } = this.props;

    platformApiSvc.interceptors.response.use(null, async (error) => {
      const errorCode = _get(error, "response.data.error");
      if (
        errorCode === "ONGOING_MAINTENANCE" &&
        location.pathname !== appRoutes.maintenance
      ) {
        history.push(appRoutes.maintenance);
      }

      return Promise.reject(error);
    });

    if (getRefreshToken()) {
      await refreshToken();
    }

    window.addEventListener("storage", ({ key, newValue }) => {
      if (key === "accessToken") {
        if (!newValue) {
          window.location = appRoutes.login;
        } else if (location.pathname === appRoutes.login) {
          window.location = "/";
        }
      }
    });
  }

  componentDidUpdate(prevProps) {
    const { isLoggedIn } = this.props.user;

    if (isLoggedIn && isLoggedIn !== prevProps.user.isLoggedIn) {
      this.preload();
    }
  }

  preload() {
    const { resourceGetData } = this.props;
    this.props.userGetData();
    resourceGetData(
      "pipelineStageMatrix",
      { url: resource.requisitionStages },
      "_embedded.requisitionStages"
    );
  }

  render() {
    const {
      user: { isLoggedIn, data },
    } = this.props;
    const refreshToken = getRefreshToken();
    if (
      ((refreshToken && !isLoggedIn) || (isLoggedIn && !data.id)) &&
      window.location.pathname !== appRoutes.maintenance
    ) {
      return <PageLoader />;
    }

    const homePage = (
      <SecureRoute>
        <DefaultLayout className="home-page">
          <Home />
        </DefaultLayout>
      </SecureRoute>
    );

    return (
      <>
        <Suspense fallback={<PageLoader />}>
          <Switch>
            {/* START: Public Routes */}
            <Route path="/" exact>
              {isLoggedIn ? homePage : <Redirect to={appRoutes.login} />}
            </Route>
            <Route path="/poc1">
              <Poc1 />
            </Route>
            <Route path="/poc2">
              <Poc2 />
            </Route>
            <Route path={appRoutes.login} component={Login} />
            <Route path="/register">
              <DefaultLayout>
                <div>Register</div>
              </DefaultLayout>
            </Route>
            <Route path={appRoutes.setPassword}>
              <DefaultLayout className="password-page">
                <SetPassword />
              </DefaultLayout>
            </Route>
            <Route path={appRoutes.forgotPassword}>
              <DefaultLayout className="password-page">
                <ForgotPassword />
              </DefaultLayout>
            </Route>
            <Route path={`${appRoutes.password}/:type?`}>
              <DefaultLayout className="password-page">
                <SetPassword type="passwordReset" />
              </DefaultLayout>
            </Route>
            <Route path={appRoutes.confirmEmail}>
              <DefaultLayout className="password-page">
                <SetPassword type="welcomeEmail" />
              </DefaultLayout>
            </Route>
            <Route path={appRoutes.maintenance} component={UnderMaintenance} />
            {/* END: Public Routes */}
            {/* START: Private Routes */}
            <SecureRoute path={appRoutes.leverAuth}>
              <DefaultLayout>
                <AuthCallback />
              </DefaultLayout>
            </SecureRoute>
            <SecureRoute path={`${appRoutes.ats}/:companyId/:step?`}>
              <DefaultLayout className="ats-page">
                <Ats />
              </DefaultLayout>
            </SecureRoute>
            <SecureRoute path={appRoutes.dashboard}>
              <DefaultLayout className="dashboard-page">
                <Dashboard />
              </DefaultLayout>
            </SecureRoute>
            <SecureRoute path={`${appRoutes.requisition}/:rqnId?`}>
              <DefaultLayout className="req-page">
                <Requisition />
              </DefaultLayout>
            </SecureRoute>
            <SecureRoute exact path={`${appRoutes.task}`}>
              <DefaultLayout className="task-page">
                <Task />
              </DefaultLayout>
            </SecureRoute>
            <SecureRoute exact path={`${appRoutes.task}/:documentId`}>
              <DefaultLayout className="task-detail-page">
                <TaskDetail />
              </DefaultLayout>
            </SecureRoute>
            <SecureRoute exact path={appRoutes.profile}>
              <DefaultLayout className="my-profile">
                <MyProfile />
              </DefaultLayout>
            </SecureRoute>
            <SecureRoute exact path={appRoutes.userAccount}>
              <DefaultLayout className="user-list-page">
                <UserAccountList />
              </DefaultLayout>
            </SecureRoute>
            <SecureRoute exact path={appRoutes.userAdmin}>
              <DefaultLayout className="user-admin-list-page">
                <UserAdminList hhPrivate={true} />
              </DefaultLayout>
            </SecureRoute>
            <SecureRoute exact path={`${appRoutes.userAccount}/:documentId`}>
              <DefaultLayout className="user-detail-page">
                <UserAccountDetail />
              </DefaultLayout>
            </SecureRoute>
            <SecureRoute exact path={`${appRoutes.companyAccount}/:documentId`}>
              <DefaultLayout className="account-detail-page">
                <CompanyAccountDetail />
              </DefaultLayout>
            </SecureRoute>
            <SecureRoute path={`${appRoutes.viewCompany}/:documentId`}>
              <DefaultLayout className="account-read-detail-page">
                <CompanyReadDetail />
              </DefaultLayout>
            </SecureRoute>
            <SecureRoute exact path={appRoutes.changePassword}>
              <DefaultLayout className="user-list-page">
                <ChangePassword />
              </DefaultLayout>
            </SecureRoute>
            {/* START: HH admin routes */}
            <SecureRoute exact path={appRoutes.hhUser}>
              <DefaultLayout className="user-list-page">
                <UserAccountList hhPrivate={true} />
              </DefaultLayout>
            </SecureRoute>
            <SecureRoute exact path={`${appRoutes.hhUser}/:documentId`}>
              <DefaultLayout className="user-detail-page">
                <UserAccountDetail hhPrivate={true} />
              </DefaultLayout>
            </SecureRoute>
            <SecureRoute exact path={`${appRoutes.hhCompany}/:documentId`}>
              <DefaultLayout className="account-detail-page">
                <CompanyAccountDetail hhPrivate={true} />
              </DefaultLayout>
            </SecureRoute>
            <SecureRoute path={`${appRoutes.hhViewCompany}/:documentId`}>
              <DefaultLayout className="account-read-detail-page">
                <CompanyReadDetail hhPrivate={true} />
              </DefaultLayout>
            </SecureRoute>
            {/* END: HH admin routes */}
            <SecureRoute exact path={`${appRoutes.securityRole}`}>
              <DefaultLayout className="sec-role-list-page">
                <SecurityRoleList />
              </DefaultLayout>
            </SecureRoute>
            <SecureRoute exact path={`${appRoutes.securityRole}/:roleId?`}>
              <DefaultLayout className="sec-role-detail-page">
                <SecurityRoleDetail />
              </DefaultLayout>
            </SecureRoute>
            <SecureRoute exact path={`${appRoutes.search}`}>
              <DefaultLayout className="search-list-page">
                <Searches />
              </DefaultLayout>
            </SecureRoute>
            <SecureRoute exact path={`${appRoutes.candidateLookup}`}>
              <DefaultLayout className="candidate-lookup">
                <Lookup />
              </DefaultLayout>
            </SecureRoute>
            <SecureRoute
              exact
              path={`${appRoutes.candidateLookup}/:documentId`}
            >
              <DefaultLayout className="candidate-profile">
                <LookupProfile />
              </DefaultLayout>
            </SecureRoute>
            <SecureRoute exact path={`${appRoutes.searchEdit}/:searchId?`}>
              <DefaultLayout className="search-page">
                <Search />
              </DefaultLayout>
            </SecureRoute>
            <SecureRoute path={`${appRoutes.candidateProfile}/:documentId?`}>
              <DefaultLayout className="candidate-profile">
                <CandidateProfile />
              </DefaultLayout>
            </SecureRoute>
            <SecureRoute
              path={`${appRoutes.candidateViewedBookmarked}/:documentId?`}
            >
              <DefaultLayout className="candidate-profile">
                <UserList />
              </DefaultLayout>
            </SecureRoute>
            <SecureRoute path={appRoutes.userAgreement}>
              <DefaultLayout className="user-agreement">
                <UserAgreement />
              </DefaultLayout>
            </SecureRoute>
            <SecureRoute exact path={appRoutes.licensePlan}>
              <DefaultLayout className="license-plan-list-page">
                <LicensePlanList />
              </DefaultLayout>
            </SecureRoute>
            <SecureRoute path={`${appRoutes.licensePlan}/:documentId?`}>
              <DefaultLayout className="license-plan-detail-page">
                <LicensePlanDetail />
              </DefaultLayout>
            </SecureRoute>
            <SecureRoute path={`${appRoutes.autoDialer}/:companyId/:step?`}>
              <DefaultLayout className="auto-dialer-page">
                <AutoDialer />
              </DefaultLayout>
            </SecureRoute>
            <SecureRoute path={appRoutes.communication}>
              <DefaultLayout className="communication-list-page">
                <Communication />
              </DefaultLayout>
            </SecureRoute>
            <SecureRoute path={appRoutes.fulfillRequest}>
              <DefaultLayout className="contact-request-page">
                <FulFillRequest />
              </DefaultLayout>
            </SecureRoute>
            <SecureRoute path="/logout">
              <DefaultLayout>
                <Logout />
              </DefaultLayout>
            </SecureRoute>
            {SearchLegalSecureRoute}
            {PeopleLegalSecureRoute}
            {/* END: Private Routes */}
            <Route path="/unauthorized-page-access">
              <DefaultLayout>
                <UnauthorizedPageAccess redirectUrl="/" />
              </DefaultLayout>
            </Route>
            <Route path="/unauthorized-record-access">
              <DefaultLayout>
                <UnauthorizedRecordAccess redirectUrl={appRoutes.home} />
              </DefaultLayout>
            </Route>
            <Route>
              <DefaultLayout>
                <PageNotFound redirectUrl="/" />
              </DefaultLayout>
            </Route>
          </Switch>
        </Suspense>
      </>
    );
  }
}

const dispatchToProps = {
  resourceGetData,
  userGetData,
};

const stateToProps = ({ user }) => {
  return { user };
};

export default _flow([withRouter, connect(stateToProps, dispatchToProps)])(App);
