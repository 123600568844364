export default {
  SEARCH_SET_DATA: "SET_DATA",
  SEARCH_GET: "SEARCH_GET",
  SEARCH_GET_START: "SEARCH_GET_START",
  SEARCH_GET_COMPLETE: "SEARCH_GET_COMPLETE",
  SEARCH_SAVE: "SEARCH_SAVE",
  SEARCH_SAVE_START: "SEARCH_SAVE_START",
  SEARCH_SAVE_COMPLETE: "SEARCH_SAVE_COMPLETE",
  SEARCH_SAVE_AS: "SEARCH_SAVE_AS",
  SEARCH_SAVE_AS_START: "SEARCH_SAVE_AS_START",
  SEARCH_SAVE_AS_COMPLETE: "SEARCH_SAVE_AS_COMPLETE",
  SEARCH_SET_DEFAULT: "SEARCH_SET_DEFAULT",
  SEARCH_SET_REQUISITION: "SEARCH_SET_REQUISITION",
  SEARCH_SET_NOTIF: "SEARCH_SET_NOTIF",
  SEARCH_RESET_STATE: "SEARCH_RESET_STATE",
  SEARCH_ASSIGN_REQ: "SEARCH_ASSIGN_REQ",
  SEARCH_ASSIGN_REQ_START: "SEARCH_ASSIGN_REQ_START",
  SEARCH_ASSIGN_REQ_COMPLETE: "SEARCH_ASSIGN_REQ_COMPLETE",
  SEARCH_NEW_REQ: "SEARCH_NEW_REQ",
  SEARCH_NEW_REQ_START: "SEARCH_NEW_REQ_START",
  SEARCH_NEW_REQ_COMPLETE: "SEARCH_NEW_REQ_COMPLETE",
  SEARCH_COPY_REQ: "SEARCH_COPY_REQ",
  SEARCH_COPY_REQ_START: "SEARCH_COPY_REQ_START",
  SEARCH_COPY_REQ_COMPLETE: "SEARCH_COPY_REQ_COMPLETE",
  SEARCH_KEYWORD_MATCH: "SEARCH_KEYWORD_MATCH",
  SEARCH_KEYWORD_MATCH_START: "SEARCH_KEYWORD_MATCH_START",
  SEARCH_KEYWORD_MATCH_COMPLETE: "SEARCH_KEYWORD_MATCH_COMPLETE",
  SEARCH_TOGGLE_AUTO_COLLAPSE: "SEARCH_TOGGLE_AUTO_COLLAPSE",
  SEARCH_GET_REFINE_SEARCH_RESULT_COUNT:
    "SEARCH_GET_REFINE_SEARCH_RESULT_COUNT",
  SEARCH_UPDATE_REFINE_SEARCH_RESULT_COUNT:
    "SEARCH_UPDATE_REFINE_SEARCH_RESULT_COUNT",
};
