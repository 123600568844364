import _get from "lodash.get";
import _cloneDeep from "lodash.clonedeep";
import moment from "moment";
import { v4 } from "uuid";

import candidatesList from "./data.candidates-list.json";
import candidatesProfileTasks from "./data.candidate-profile-tasks.json";
import profleData from "./data/profile.js";
import profileRequisitions from "./data/requisitions.js";
import peopleList from "./data/people-search";
import peopleBookmarkListData from "./data/people-bookmark-list.json";

import apiResource from "@config/resource";
import legalPeopleResource from "../../../../people-legal/config/resource";
import mockedData from "./data";

export const activeReqs = (mock) => {
  mock.onGet(`${apiResource.candidates}/activeReqs`).reply((config) => {
    const ids = _get(config, "params.ids", []);
    const data = {
      _embedded: {
        candidateInformations: ids.map((candidateId) => {
          return {
            candidateId,
            requisitionData: {
              active: mockedData.reqLists.map((req, i) => {
                const requisitionId = i + 1;

                return {
                  ...req,
                  requisitionName: `${requisitionId}:${req.requisitionName}`,
                };
              }),
            },
          };
        }),
      },
    };

    return [201, data];
  });
};

// export const getSearch = (mock) => {
//   const searchUri = new RegExp(`${apiResource.candidates}/11`);
//   mock.onGet('api/candidateSearches/0').reply(400, { error: 'Record does not exist.' });
//   mock.onGet(searchUri).reply(201, candidateSearch);
// };

export const findByParameters = (mock) => {
  mock
    .onPost(`${apiResource.candidates}/search/findByParameters`)
    .reply(200, candidatesList);
};

export const findByParametersV2 = (mock) => {
  mock
    .onPost(new RegExp(`${apiResource.candidates}/search/findByParametersV2*`))
    .reply(200, peopleList);
};

export const saveSearch = (mock) => {
  const searchUri = new RegExp(`${apiResource.candidateSearches}/*`);
  mock.onAny(searchUri).reply((config) => {
    config.data = JSON.parse(config.data);
    if (config.data.name === "duplicate") {
      return [
        400,
        {
          error:
            "A search already exists with the same name. Please enter a new name for your search.",
        },
      ];
    }

    const data = Object.assign({}, config.data);

    return [201, data];
  });
};

export const tasks = (mock) => {
  const searchUri = new RegExp(`${apiResource.candidates}/profile/tasks`);
  mock.onAny(searchUri).reply(() => {
    const data = _cloneDeep(candidatesProfileTasks);
    data._embedded.tasks = data._embedded.tasks.map((item) => {
      const id = v4();
      return {
        ...item,
        id,
        title: id.split("-")[0] + " " + item.title,
      };
    });
    return [200, data];
  });
};

export const profile = (mock) => {
  mock.onPost("/candidate-profile").reply(200, profleData);
};

export const getCandidateProfile = (mock) => {
  mock
    .onPost(new RegExp(`${apiResource.candidateProfile}/[0-9]`))
    .reply(200, profleData);
};

export const requisitions = (mock) => {
  mock
    .onGet(`${apiResource.candidates}/candidate-requisitions`)
    .reply(200, profileRequisitions);
};

export const requisitionStage = (mock) => {
  mock.onGet("candidate-requisition-stage").reply(200, {
    _embedded: {
      candidateProspects: [
        {
          exportedDate: moment().subtract(1, "day"),
          stage: "PROSPECT",
          status: "PENDING",
          isDisqualified: false,
          reason: null,
        },
      ],
    },
  });
};

export const peopleSearch = (mock) => {
  mock
    .onPost(new RegExp(`${legalPeopleResource.people}/search*`))
    .reply(200, peopleList);
};

export const peopleBookmarkList = (mock) => {
  mock
    .onGet(new RegExp(`${legalPeopleResource.legalPeople}/bookmark*`))
    .reply(200, peopleBookmarkListData);
};

export const bulkBookmark = (mock) => {
  mock.onPatch(`${apiResource.candidates}/bookmark`).reply(200, {
    candidates: [12345789, 12345788, 123456787, 12345786, 123456785, 123456783],
    bookmark: true,
  });
};

export default [
  // getCandidateProfile,
  // findByParametersV2,
  // profile,
  // requisitions,
  // tasks,
  // requisitionStage,
  // findByParameters,
  // getSearch,
  // saveSearch,
  // findByName,
  // activeReqs,
  // peopleSearch,
  // bulkBookmark,
  // peopleBookmarkList,
];
