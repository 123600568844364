export const configSteps = {
  CONFIGURATION: "configuration",
  MESSAGE_TEMPLATES: "message-templates",
  USER_DETAILS: "user-details",
  ACTIVATION: "activation",
};

export const stepLabels = {
  [configSteps.CONFIGURATION]: "Configuration",
  [configSteps.MESSAGE_TEMPLATES]: "Message Templates",
  [configSteps.USER_DETAILS]: "User Details",
  [configSteps.ACTIVATION]: "Activation",
};

export const urlConfigMapping = {
  [configSteps.CONFIGURATION]: "/configuration",
  [configSteps.USER_DETAILS]: "/configuration/users",
  [configSteps.ACTIVATION]: "/configuration/activation",
};

export const cascaderSearchOptions = {
  filter: (searchTerm, path) => {
    return path.some(
      (option) =>
        option.label.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
    );
  },
};
