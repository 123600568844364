import { messageTemplateGroup } from "@config/auto-dialer/message-template";

const lang = {
  en: "English (US)",
  au: "English (Australian)",
};
const gender = {
  MALE: "MALE",
  FEMALE: "FEMALE",
};
const transferForCandidate = (template, length = 3) =>
  Array.from({ length }, (v, i) => {
    const id = i + 1;
    const first = id === 1;
    return {
      id,
      templateName: template + ` ${id}`,
      messageType: template,
      messageTemplate: `Hi {{candidateFirstName}} from temaplate ${template} ${id} , this is {{userFirstName}} from {{userCompanyName}} I am reaching out to you for a job opportunity {{requisitionJobTitle}} and I’d like to connect to discuss how you would be a great fit for this role. Please let me know if you are interested, and I can provide you with more information. You can reach out to me at {{userTwilioNumber}} or email at {{userEmailAddress}}. Looking forward to discuss this opportunity with you. Thanks.`,
      audioVoice: {
        id: lang.en + "1",
        lang: "English (US)",
        gender: "MALE",
        name: "John",
        type: "NEURAL",
      },
      defaultFlag: first,
      level: first ? "SYSTEM" : "COMPANY",
      accountId: null,
    };
  });

export const fullConfig = {
  country: "US",
  areaCode: "213",
  callRecordings: false,
  candidatePredefinedTransferMessage: false,
  recruiterPredefinedTransferMessage: false,
  callForwarding: true,
  voicemail: true,
  redial: true,
  maxRedialPerDay: 2,
  maxDaysForRedial: 3,
  redialStartTime: "2023-07-06T17:00:00Z",
  delayTime: true,
  leaveVoicemail: "LAST_CALL",
  delayTimeBetweenCalls: 20,
  editDelayTime: true,
  active: false,
  users: [],
  messageTemplates: [
    ...transferForCandidate(messageTemplateGroup.VOICEMAIL),
    ...transferForCandidate(messageTemplateGroup.TRANSFER_FOR_CANDIDATE, 5),
    ...transferForCandidate(messageTemplateGroup.TRANSFER_FOR_RECRUITER, 2),
  ],
  autoDialerUserCount: 100,
  currentAutoDialerUserCount: 0,
  completedSteps: ["CONFIGURATION"],
};

export const voiceList = [
  {
    id: lang.en + "1",
    lang: lang.en,
    gender: gender.MALE,
    name: "John",
    neural: true,
    standard: true,
  },
  {
    id: lang.en + "2",
    lang: lang.en,
    gender: gender.MALE,
    name: "Kevin",
    neural: true,
    standard: false,
  },
  {
    id: lang.en + "3",
    lang: lang.en,
    gender: gender.FEMALE,
    name: "Kendra",
    neural: true,
    standard: true,
  },
  {
    id: lang.en + "4",
    lang: lang.en,
    gender: gender.FEMALE,
    name: "Ruth",
    neural: true,
    standard: false,
  },
  {
    id: lang.au + "1",
    lang: lang.au,
    gender: gender.MALE,
    name: "Russell",
    neural: true,
    standard: true,
  },
  {
    id: lang.au + "2",
    lang: lang.au,
    gender: gender.MALE,
    name: "Brian",
    neural: true,
    standard: false,
  },
  {
    id: lang.au + "3",
    lang: lang.au,
    gender: gender.FEMALE,
    name: "Olivia",
    neural: true,
    standard: true,
  },
  {
    id: lang.au + "4",
    lang: lang.au,
    gender: gender.FEMALE,
    name: "Nicole",
    neural: true,
    standard: false,
  },
];
