import _cloneDeep from "lodash.clonedeep";

import { IDLE } from "@config/constants";
import actionTypes from "../actions/auto-dialer/actionTypes";

export const defaultState = {
  config: {},
  userMessageTemplates: [],
  getConfig: {
    status: IDLE,
    error: null,
  },
  saveConfig: {
    status: IDLE,
    error: null,
  },
  saveMessageTemplate: {
    status: IDLE,
    error: null,
  },
  deleteMessageTemplate: {
    status: IDLE,
  },
  getUserMessageTemplates: {
    status: IDLE,
    error: null,
  },
  saveUserMessageTemplate: {
    status: IDLE,
    error: null,
  },
  bulkSchedule: {
    status: IDLE,
    error: null,
  },
  steps: {
    detail: {},
    completed: [],
    enabled: [],
  },
};

export default (state = _cloneDeep(defaultState), action = {}) => {
  const { type, ...args } = action;

  switch (type) {
    case actionTypes.AUTO_DIALER_SET_CONFIG:
      return {
        ...state,
        config: args.clearConfig
          ? {}
          : {
              ...state.config,
              ...args.config,
            },
        getConfig: args.getConfig,
        steps: {
          ...state.steps,
          ...args.steps,
        },
      };
    case actionTypes.AUTO_DIALER_SET_SAVE_CONFIG:
      return {
        ...state,
        config: args.config ?? state.config,
        steps: args.steps ?? state.steps,
        saveConfig: args.saveConfig ?? state.saveConfig,
      };
    case actionTypes.AUTO_DIALER_RESET_SAVE_CONFIG:
      return {
        ...state,
        saveConfig: {
          status: IDLE,
          error: null,
        },
      };
    case actionTypes.AUTO_DIALER_SET_SAVE_MESSAGE_TEMPLATE:
      return {
        ...state,
        saveMessageTemplate:
          args.saveMessageTemplate ?? state.saveMessageTemplate,
      };
    case actionTypes.AUTO_DIALER_RESET_SAVE_MESSAGE_TEMPLATE:
      return {
        ...state,
        saveMessageTemplate: {
          status: IDLE,
          error: null,
        },
      };
    case actionTypes.AUTO_DIALER_SET_DELETE_MESSAGE_TEMPLATE:
      return {
        ...state,
        deleteMessageTemplate:
          args.deleteMessageTemplate ?? state.deleteMessageTemplate,
      };
    case actionTypes.AUTO_DIALER_RESET_DELETE_MESSAGE_TEMPLATE:
      return {
        ...state,
        deleteMessageTemplate: {
          status: IDLE,
        },
      };
    case actionTypes.AUTO_DIALER_SET_USER_MESSAGE_TEMPLATES:
      return {
        ...state,
        userMessageTemplates:
          args.userMessageTemplates ?? state.userMessageTemplates,
        getUserMessageTemplates: args.getUserMessageTemplates,
      };
    default:
    case actionTypes.AUTO_DIALER_SET_SAVE_USER_MESSAGE_TEMPLATE:
      return {
        ...state,
        saveUserMessageTemplate: args.saveUserMessageTemplate,
      };
    case actionTypes.AUTO_DIALER_SET_BULK_SCHEDULE:
      return {
        ...state,
        bulkSchedule: args.bulkSchedule,
      };
    case actionTypes.AUTO_DIALER_RESET_BULK_SCHEDULE:
      return {
        ...state,
        bulkSchedule: {
          ...defaultState.bulkSchedule,
        },
      };
      return state;
  }
};
