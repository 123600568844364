import { EMAIL, PHONE, COMPLETED, TEXT, ADMIN, status } from '@config/task';
import moment from 'moment';
import Chance from 'chance';
import faker from 'faker';

const chance = new Chance();

const data = {
  _embedded: {
    tasks: Array.from({ length: 20 }, (v, id) => {
      const taskId = id + 1000;
      const even = (id % 2) === 0;
      const type = chance.pickone([EMAIL, PHONE, TEXT, ADMIN]);
      const dueDate = even ? moment().add(1, 'week') : moment().subtract(1, 'week');
      const taskStatus = chance.pickone(Object.keys(status));
      return {
        id: taskId,
        dueDate,
        mainContactInfo: {
          value: type === EMAIL ? faker.internet.email() : chance.phone()
        },
        task: {
          id: taskId
        },
        type,
        title: chance.sentence({ words: 10 }),
        highPriority: chance.bool(),
        requisitionName: taskId + ': ' + faker.name.title(),
        candidateName: faker.name.findName(),
        ownerName: faker.name.findName(),
        completionDate: taskStatus === COMPLETED ? moment().format() : null,
        status: taskStatus
      };
    })
  },
  page: {
    size: 20,
    totalElements: 100,
    totalPages: 5,
    number: 0
  }
};

data._embedded.tasks[0].status = 'CANCELLED';

export default data;