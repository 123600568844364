import apiResource from "@config/resource";
import moment from "moment";

const date = moment().subtract(4, "day");
const secRoleId = 2;
const secRoleDocument = {
  id: secRoleId + 1000,
  name: `Secutiry role ${secRoleId}`,
  authority: "HH_SUPERVISOR",
  description: `Role to allow execution of recruitment features ${secRoleId}`,
  active: true,
  internal: true,
  admin: true,
  userCount: 0,
  dateCreated: date,
  dateModified: date,
  enforceDataSecurity: true,
  menuAccess: ["REQUISITIONS", "REQUISITIONS_HIRING_MANAGER", "MY_PROFILE"],
};

export const getList = (mock) => {
  mock.onGet(apiResource.securityRoles).reply(() => {
    const data = {
      _embedded: {
        securityRoles: Array.from({ length: 12 }, (v, i) => {
          const userCount = i > 2 ? i : 0;
          const id = i + 1000;
          const even = i % 2 === 0;
          return {
            id,
            name: `Secutiry role ${id}`,
            authority: "HH_SUPERVISOR",
            description: `Role to allow execution of recruitment features ${id}`,
            active: even,
            internal: even,
            admin: even,
            userCount,
            dateCreated: moment(),
            dateModified: moment(),
            enforceDataSecurity: true,
            menuAccess: [
              "REQUISITIONS",
              "REQUISITIONS_HIRING_MANAGER",
              "MY_PROFILE",
            ],
          };
        }),
      },
      page: {
        size: 25,
        totalElements: 100,
        totalPages: 4,
        number: 0,
      },
    };

    return [201, data];
  });
};

export const findByName = (mock) => {
  mock
    .onGet(`${apiResource.securityRoles}/search/findByName`)
    .reply((config) => {
      const data = {};

      if (config.params.name === "dup") {
        data._embedded = {
          securityRoles: [
            {
              id: 1,
            },
          ],
        };
      }
      return [200, data];
    });
};

export const create = (mock) => {
  mock.onPost(apiResource.securityRoles).reply(201, secRoleDocument);
};

export const deleteRecord = (mock) => {
  const uri = new RegExp(`${apiResource.securityRoles}/*`);
  mock.onDelete(uri).reply(() => {
    return [204];
  });
};

export const update = (mock) => {
  const uri = new RegExp(`${apiResource.securityRoles}/*`);
  mock.onPatch(uri).reply(200, secRoleDocument);
};

export const getDocument = (mock) => {
  const uri = new RegExp(`${apiResource.securityRoles}/*`);

  mock.onGet(uri).reply(200, secRoleDocument);
};

export default [
  // create,
  // update,
  // deleteRecord,
  // getList,
  // getDocument,
  // findByName
];
