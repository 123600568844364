import { takeEvery, all, put } from 'redux-saga/effects';
import _get from 'lodash.get';
import _clonedeep from 'lodash.clonedeep';

import resource from '@config/resource';
import {
  getListStart,
  getListComplete
} from '../actions/candidate/';
import platormApiSvc from '@services/platform-api/';
import actionTypes from '@store/actions/candidate/actionTypes';
import { SUCCESS } from '@config/constants';

function * search (args) {
  const { config } = args;
  yield put(getListStart());

  let candidate = {
    list: {},
    get: {}
  };

  try {
    const { data } = yield platormApiSvc({
      ..._clonedeep(config),
      method: 'POST',
      url: `${resource.candidates}/search/findByParameters`
    });

    candidate = {
      get: {
        status: SUCCESS
      },
      list: {
        data: _get(data, '_embedded.candidates', []),
        page: _get(data, 'page', {})
        // links: _get(data, '_links', {}), FOR_REMOVAL
      }
    };
  } catch (e) {
    candidate.get = {
      status: 'fail',
      notif: {
        type: 'error',
        message: e.apiMessage
      }
    };
  }

  yield put(getListComplete({ candidate }));
}

function * getList ({ resultLink }) {
  yield put(getListStart());

  let candidate = {
    list: {},
    get: {}
  };
  try {
    const { data } = yield platormApiSvc.get(resultLink);

    candidate = {
      get: {
        status: ''
      },
      list: {
        data: _get(data, '_embedded.candidates', []),
        links: _get(data, '_links', {}),
        page: _get(data, 'page', {})
      }
    };
  } catch (e) {
    candidate.get = {
      status: 'fail',
      notif: {
        type: 'error',
        message: e.apiMessage
      }
    };
  }

  yield put(getListComplete({ candidate }));
}

export default function * candidate () {
  yield all([
    takeEvery(actionTypes.CANDIDATE_SEARCH, search),
    takeEvery(actionTypes.CANDIDATE_GET_LIST, getList)
  ]);
}