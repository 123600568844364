import React from 'react';
import { render } from 'react-dom';
import _get from 'lodash.get';
import { Icon } from 'antd';

import ConfirmRoute from '@components/shared/Modal/ConfirmRoute';
import { Sync as NoOptionsContentSync } from '@components/shared/NoOptionsContent/NoOptionsContent';

export const componentUpdatedValues = (cur = {}, prev = {}, path = '') => {
  return [
    _get(cur, path),
    _get(prev, path)
  ];
};

export const noOptionsMatchedContent = (keyword, loading, options) => {
  let content = null;

  if (keyword) {
    if (loading) {
      content = (
        <div className="text-center">
          <Icon type="loading-3-quarters" spin />
        </div>
      );
    } else if (Array.isArray(options) && !options.length) {
      content = <NoOptionsContentSync />;
    }
  } else {
    content = (
      <div className="text-center">
        Type a keyword.
      </div>
    );
  }

  return content;
};

export const showConfirmRouteModal = (config, cb) => {
  const container = document.getElementById('confirm-modal-cont');
  const props = {
    cb,
    config,
    container
  };

  render(<ConfirmRoute {...props} />, container);
};

export default {
  showConfirmRouteModal,
  componentUpdatedValues
};