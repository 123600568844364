import apiResource from "@config/resource";
import openRequisitionsData from "./data/open-requisitions.js";
import candidateProfileReqData from "./data/candidate-profile-requisition.js";
import candidates from "./data/stage-candidates.json";
import atsJobsData from "./data/ats-jobs.js";
import candidate from "./data/candidate.json";
import getCandidatesData from "./data/requisition-pipeline.json";
import _cloneDeep from "lodash.clonedeep";
import req from "./data.req-document.json";
import atsConfiguration from "./data/ats-configuration.json";
import jobHistoryData from "./data/ats-job-history.json";

export const getReq = (mock) => {
  mock.onGet(new RegExp(`${apiResource.requisitions}/*`)).reply(200, req);
};

export const createReq = (mock) => {
  mock.onPost(apiResource.requisitions).reply(201, req);
};

export const stageCandidates = (mock) => {
  const stageCandidates = _cloneDeep(candidates);
  stageCandidates._embedded.candidateProspects = Array.from(
    { length: 25 },
    (v, k) => {
      const odd = k % 2 > 0;
      const firstName = "Candidate";
      const lastName = `${k}`;
      const fullName = `${firstName} ${lastName}`;
      const contactRequestIndicator = "OPEN";
      const lastRequestDate = new Date();

      let candidateDetail = {
        ...candidate,
        id: k,
        firstName,
        lastName,
        fullName,
        contactRequestEligibility: odd,
        contactRequestIndicator,
        lastRequestDate,
        availablePersonalContactTypes: [1, 2],
      };

      if (odd && !odd) {
        candidateDetail = {
          ...candidateDetail,
          linkedinUrl: null,
          contactRequestIndicator: "OPEN",
          lastRequestDate: null,
        };
      }

      return candidateDetail;
    }
  );

  const uri = new RegExp("stage-candidates");
  mock.onGet(uri).reply(200, stageCandidates);
};

export const openRequisitions = (mock) => {
  mock
    .onGet(`${apiResource.candidates}/open-requisitions`)
    .reply(200, openRequisitionsData);
};

export const candidateProfileReq = (mock) => {
  const uri = new RegExp(
    `${apiResource.candidates}/candidate-profile-requisition/*`
  );
  mock.onGet(uri).reply(200, candidateProfileReqData);
};

export const atsJobs = (mock) => {
  mock.onGet(new RegExp(`${apiResource.ats}/jobs`)).reply(200, atsJobsData);
};

export const getAtsConfig = (mock) => {
  mock
    .onAny(new RegExp(`${apiResource.ats}/configuration*`))
    .reply(200, atsConfiguration);
};

export const getAtsJobHistory = (mock) => {
  mock
    .onGet(new RegExp(`${apiResource.ats}/history/search*`))
    .reply(200, jobHistoryData);
};

export const getRequisitionCandidates = (mock) => {
  mock
    .onGet(
      new RegExp(
        `${apiResource.candidateProspects}/search/findByRequisitionAndStages*`
      )
    )
    .reply(200, getCandidatesData);
};

export const getUserLicense = (mock) => {
  mock.onGet(new RegExp(`${apiResource.userLicense}`)).reply(200, {
    bulkEmailEnabled: true,
    bulkEmailVerification: true,
    diversityEnabled: true,
  });
};

export default [
  // atsJobs,
  // getAtsConfig,
  // getReq,
  // getAtsJobHistory,
  // getRequisitionCandidates,
  // getUserLicense,
  // openRequisitions,
  // candidateProfileReq,
  // stageCandidates,
];
