import actionTypes from "./actionTypes";

export const getConfig = (companyId) => {
  return {
    companyId,
    type: actionTypes.ATS_GET_CONFIG,
  };
};
export const getConfigStart = () => {
  return {
    type: actionTypes.ATS_GET_CONFIG_START,
  };
};
export const getConfigComplete = (state) => {
  return {
    ...state,
    type: actionTypes.ATS_GET_CONFIG_COMPLETE,
  };
};

export const saveConfig = (companyId, config, step) => {
  return {
    config,
    companyId,
    step,
    type: actionTypes.ATS_SAVE_CONFIG,
  };
};
export const saveConfigStart = () => {
  return {
    type: actionTypes.ATS_SAVE_CONFIG_START,
  };
};
export const saveConfigComplete = (config) => {
  return {
    ...config,
    type: actionTypes.ATS_SAVE_CONFIG_COMPLETE,
  };
};
