import _get from 'lodash.get';

import appRoutes, { hhInternal } from '@config/app-routes';

class Auth {
  init (userData) {
    this.userData = userData;
  }

  hasAccess = (roleKeys) => {
    roleKeys = Array.isArray(roleKeys) ? roleKeys : [roleKeys];
    return _get(this.userData, 'access.authorities', []).some(key => roleKeys.includes(key));
  }

  isHhAdminPage = () => {
    const pathName = _get(window, 'location.pathname', '');
    return pathName.startsWith(appRoutes.hhUser) || pathName.startsWith(appRoutes.hhCompany);
  }

  userRouteLink = (link = '') => {
    const pathName = _get(window, 'location.pathname', '');
    const baseUrl = pathName.startsWith(hhInternal) ? appRoutes.hhUser : appRoutes.user;
    link = `${link}`.startsWith('/') ? link : `/${link}`;
    return baseUrl + link;
  }

  companyRouteLink = (link = '') => {
    const pathName = _get(window, 'location.pathname', '');
    const baseUrl = pathName.startsWith(hhInternal) ? appRoutes.hhCompany : appRoutes.company;
    link = `${link}`.startsWith('/') ? link : `/${link}`;
    return baseUrl + link;
  }
}

const AuthInstance = new Auth();

export default AuthInstance;