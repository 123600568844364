import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import classnames from "classnames";

import { hhPrefixer } from "@helpers/collection";
import DefaultHeader from "../Header/Default";
import DefaultBody from "../Body/Default";

class DefaultContainer extends Component {
  render() {
    const { className, children, isAuthenticated } = this.props;

    return (
      <div
        className={classnames(
          className,
          hhPrefixer("layout-container-default")
        )}
      >
        <DefaultHeader className="layout-header" isAuth={isAuthenticated} />
        <DefaultBody>{children}</DefaultBody>
      </div>
    );
  }
}

const stateToProps = (state) => {
  const { user } = state;
  return {
    isAuthenticated: user.isLoggedIn === true,
  };
};

export default connect(stateToProps)(withRouter(DefaultContainer));
