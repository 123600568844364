import _pick from "lodash.pick";
import _get from "lodash.get";
import _set from "lodash.set";
import _upperFirst from "lodash.upperfirst";

import { configSteps, stepLabels } from "@config/auto-dialer/index";
import {
  voiceTypes,
  messageTemplateGroup,
} from "@config/auto-dialer/message-template";

export const getStepDetails = (config) => {
  const completedSteps = [];
  const enabledSteps = [configSteps.CONFIGURATION];

  if (config.completedSteps.includes("CONFIGURATION")) {
    completedSteps.push(configSteps.CONFIGURATION);
    completedSteps.push(configSteps.MESSAGE_TEMPLATES);
    enabledSteps.push(configSteps.MESSAGE_TEMPLATES);
    enabledSteps.push(configSteps.USER_DETAILS);
  }

  if (config.completedSteps.includes("MESSAGE_TEMPLATES")) {
    enabledSteps.push(configSteps.WEBHOOK, configSteps.USER_DETAILS);
  }

  if (config.completedSteps.includes("USERS")) {
    completedSteps.push(configSteps.USER_DETAILS);
    enabledSteps.push(configSteps.ACTIVATION);
  }

  if (config.completedSteps.includes("ACTIVATION")) {
    completedSteps.push(configSteps.ACTIVATION);
  }

  return {
    completed: completedSteps,
    enabled: enabledSteps,
    detail: _pick(stepLabels, [
      configSteps.CONFIGURATION,
      configSteps.MESSAGE_TEMPLATES,
      configSteps.USER_DETAILS,
      configSteps.ACTIVATION,
    ]),
  };
};

export const getEnableMessageTemplateGroups = (config = {}) => {
  const templateGroup = [];

  if (config.voicemail) {
    templateGroup.push(messageTemplateGroup.VOICEMAIL);
  }
  if (config.candidatePredefinedTransferMessage) {
    templateGroup.push(messageTemplateGroup.TRANSFER_FOR_CANDIDATE);
  }
  if (config.recruiterPredefinedTransferMessage) {
    templateGroup.push(messageTemplateGroup.TRANSFER_FOR_RECRUITER);
  }

  return templateGroup;
};

export const groupMessageTemplates = (msgTemplates) => {
  const groupedList = {};

  msgTemplates?.forEach((template) => {
    const { messageType } = template;
    if (!groupedList[messageType]) {
      groupedList[messageType] = [];
    }

    groupedList[messageType].push(template);
  });

  return groupedList;
};

const getDefaultMessageTemplate = (group, templates) => {
  return templates?.[group]?.find((template) => template.defaultFlag)?.id;
};

const getInitialActiveTab = () => {
  return Object.keys(messageTemplateGroup).find((t) => groupKeys.includes(t));
};

const formatTemplateVariable = (key) => `{{${key}}}`;

export const groupVoiceOptions = (voiceOptions) => {
  let optionsObj = {};

  voiceOptions.forEach((voice) => {
    const objKey = `${voice.lang}.${voice.gender}`;
    const genderVoice = _get(optionsObj, objKey, []);
    const children = [];

    if (voice.neural) {
      children.push({
        label: _upperFirst(voiceTypes.NEURAL.toLowerCase()),
        value: voiceTypes.NEURAL,
      });
    }

    if (voice.standard) {
      children.push({
        label: _upperFirst(voiceTypes.STANDARD.toLowerCase()),
        value: voiceTypes.STANDARD,
      });
    }

    _set(optionsObj, objKey, [
      ...genderVoice,
      {
        label: voice.name,
        value: voice.id,
        children,
      },
    ]);
  });

  const toArray = (object) => {
    if (!Array.isArray(object)) {
      return Object.entries(object).map(([key, value]) => {
        return {
          value: key,
          label: key,
          children: toArray(value),
        };
      });
    } else {
      return object;
    }
  };

  return toArray(optionsObj);
};

export default {
  getEnableMessageTemplateGroups,
  groupMessageTemplates,
  getDefaultMessageTemplate,
  getInitialActiveTab,
  getStepDetails,
  groupVoiceOptions,
  formatTemplateVariable,
};
