import React, { PureComponent } from "react";
import classnames from "classnames";

import { hhPrefixer } from "@helpers/collection";

const hhClass = hhPrefixer(["group-nav"], true);

class GroupNav extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
    this.container = React.createRef();
  }

  closeUserNavEvent = (event) => {
    const { target } = event;
    if (this.container.current && !this.container.current.contains(target)) {
      this.toggleMenu();
    }
  };

  toggleMenu = (fixedToggle = false) => {
    this.setState(
      (state) => ({ expanded: fixedToggle ? fixedToggle : !state.expanded }),
      () => {
        const { expanded } = this.state;
        if (expanded) {
          document.addEventListener("click", this.closeUserNavEvent);
        } else {
          document.removeEventListener("click", this.closeUserNavEvent);
        }
      }
    );
  };

  render() {
    const { expanded } = this.state;
    const { className, placement = "bottom-left", trigger } = this.props;

    return (
      <div
        ref={this.container}
        className={classnames(hhClass["group-nav"], { expanded }, className)}
        onClick={() => {
          this.toggleMenu(true);
        }}
      >
        <div className="label">{trigger}</div>
        <div
          className={classnames("menu-cont", placement)}
          onClick={() => {
            this.toggleMenu(true);
          }}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default GroupNav;
