import actionTypes from "../actions/requisition/actionTypes";

const defaultState = {
  candidates: {
    data: [],
    pagination: {},
    errorCode: {},
    loading: false,
  },
  queryParameters: {},
  selectedCandidates: [],
  details: null,
};

export default (state = defaultState, action = {}) => {
  const { type, ...args } = action;
  switch (type) {
    case actionTypes.REQUISITION_RESET:
      return {
        ...defaultState,
      };
    case actionTypes.REQUISITION_SET_CANDIDATES:
      return {
        ...state,
        candidates: {
          ...state.candidates,
          ...args,
        },
      };
    case actionTypes.REQUISITION_SET_QUERY_PARAMETERS:
      return {
        ...state,
        queryParameters: {
          ...state.queryParameters,
          ...args,
        },
      };
    case actionTypes.REQUISITION_SET_SELECTED_CANDIDATES:
      const { id } = args;
      let candidateList = [];

      if (Array.isArray(id)) {
        candidateList = id;
      } else {
        candidateList = state.selectedCandidates.includes(id)
          ? state.selectedCandidates.filter((candId) => candId !== id)
          : [id, ...state.selectedCandidates];
      }

      return {
        ...state,
        selectedCandidates: candidateList,
      };
    case actionTypes.REQUISITION_SET_DETAILS:
      return {
        ...state,
        details: args.requisition,
      };
    default:
      return state;
  }
};
