export const DEFAULT_STATE = {
  searchData: {
    name: "",
    criteria: {},
  },
  get: {
    status: "",
    response: {
      code: null,
      data: {},
    },
  },
  save: {
    status: "",
    response: {
      code: null,
      data: {},
    },
  },
  navigationPanel: {
    isAutoCollapseOn: false,
  },
};
