import { createSelector } from "reselect";

export const communicationState = (state) => state.communication;
export const communcations = createSelector(
  communicationState,
  (communication) => communication.communications
);
export const getCommsList = createSelector(
  communicationState,
  (communication) => communication.getList
);
export const selectedCandidates = createSelector(
  communicationState,
  (communication) => communication.selectedCandidates
);
export const bulkCancel = createSelector(
  communicationState,
  (communication) => communication.bulkCancel
);
