import actionTypes from './actionTypes';

export const getData = (key, requestOpts, extractPath) => {
  return {
    key,
    requestOpts,
    extractPath,
    type: actionTypes.RESOURCE_GET_DATA
  };
};
export const getDataStart = (key) => {
  return {
    key,
    type: actionTypes.RESOURCE_GET_DATA_START
  };
};
export const getDataComplete = (key, data) => {
  return {
    key,
    data,
    type: actionTypes.RESOURCE_GET_DATA_COMPLETE
  };
};