import React from 'react';

export const Sync = () => (
  <div className="text-center">
    No options matched.
    <div> Try another keyword.</div>
  </div>
);

export default {
  Sync
};