import React from "react";
import { takeEvery, all, put } from "redux-saga/effects";
import * as Sentry from "@sentry/browser";
import { message } from "antd";
import jwtDecode from "jwt-decode";
import moment from "moment";

import { handleApiError } from "@helpers/collection";
import { getLicenseHelpersObj } from "@helpers/user";

import { lastSignOnKey } from "@config/user-account";
import {
  SuccessMsgNotif,
  ErrorMsgNotif,
} from "@components/shared/MessageNotif/MessageNotif";

import {
  logoutComplete,
  authComplete,
  setData,
  setLicenseData,
  getLicenseData,
} from "../actions/user/";
import resource from "@config/resource";
import actionTypes from "../actions/user/actionTypes";
import platormApiSvc, { checkTokenExp } from "@services/platform-api/";
import authSvc from "@services/auth/";

function* authenticate(data) {
  localStorage.setItem(
    "tokenExp",
    moment()
      .add(data.expires_in, "seconds")
      .format()
  );
  localStorage.setItem("accessToken", data.access_token);
  localStorage.setItem("refreshToken", data.refresh_token);
  checkTokenExp();
  yield put(authComplete());
}

function* logout({ api, notif }) {
  if (api) {
    try {
      yield platormApiSvc.patch(`${resource.accounts}/logout`);
      if (notif) {
        message.open({
          content: (
            <SuccessMsgNotif>You have successfully logged out.</SuccessMsgNotif>
          ),
        });
      }
    } catch (e) {}
  }

  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("tokenExp");
  localStorage.removeItem(lastSignOnKey);

  yield put(logoutComplete());
}

function* getLicenseSaga() {
  yield put(
    setLicenseData({
      isLoading: true,
    })
  );
  try {
    const { data } = yield platormApiSvc.get(resource.userLicense);

    const license = getLicenseHelpersObj(data);

    yield put(
      setLicenseData({
        ...license,
        isLoading: false,
      })
    );
  } catch (e) {
    yield put(
      setLicenseData({
        isLoading: false,
      })
    );
    handleApiError(e, () => {
      message.open({
        content: (
          <ErrorMsgNotif>Failed to fetch license details.</ErrorMsgNotif>
        ),
      });
    });
  }
}

function* getDataSaga() {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const lastSignOn = localStorage.getItem(lastSignOnKey);
    const access = jwtDecode(accessToken);

    if (!lastSignOn) {
      localStorage.setItem(lastSignOnKey, access.last_sign_on);
    }
    yield put(getLicenseData());

    const { data } = yield platormApiSvc.get(
      `${resource.accounts}/search/me?projection=full`
    );
    const userData = { data, access };

    authSvc.init(userData);
    Sentry.configureScope((scope) => {
      scope.setUser({
        id: data.id,
        email: data.email,
      });
    });

    yield put(setData(userData));
  } catch (e) {
    handleApiError(e, () => {
      message.open({
        content: (
          <ErrorMsgNotif>
            Failed to fetch account details. Please try to reload the page.
          </ErrorMsgNotif>
        ),
      });
    });
  }
}

export default function* user() {
  yield all([
    takeEvery(actionTypes.USER_AUTH, authenticate),
    takeEvery(actionTypes.USER_LOGOUT, logout),
    takeEvery(actionTypes.USER_GET_DATA, getDataSaga),
    takeEvery(actionTypes.USER_GET_LICENSE, getLicenseSaga),
  ]);
}
