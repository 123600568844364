import actionTypes from "./actionTypes";

export const init = () => {
  return {
    type: actionTypes.APP_INIT,
  };
};

export const initStart = (args) => {
  return {
    ...args,
    type: actionTypes.APP_INIT_START,
  };
};

export const initComplete = (args) => {
  return {
    ...args,
    type: actionTypes.APP_INIT_COMPLETE,
  };
};

export const toggleSidePanel = (args) => {
  return {
    ...args,
    type: actionTypes.TOGGLE_SIDEPANEL,
  };
};

export const setSaveChangesPrompt = (args) => {
  return {
    ...args,
    type: actionTypes.SET_SAVE_CHANGES_PROMPT,
  };
};
