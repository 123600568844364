import resource from "../../../../people-legal/config/resource";
import * as PeopleService from "../../../../people-legal/services/people";

export const contactRequest = (mock) => {
  mock
    .onPost(new RegExp(`${resource.legalPeople}/contactRefreshRequests`))
    .reply((config) => {
      let data = {};

      try {
        data = JSON.parse(config.data);
        let contactRequests = PeopleService.getIdsForContactRequests();
        contactRequests = [...contactRequests, ...data.peopleIds].reduce(
          (prev, current) => {
            if (!prev.includes(current)) {
              prev.push(current);
            }

            return prev;
          },
          []
        );

        localStorage.setItem("contactRequests", contactRequests.join());
      } catch (e) {
        console.log(e);
      }

      return [200];
    });
};

export const instantCsvExport = (mock) => {
  // const response = `"Download Name", ${JSON.stringify({ name: "Test" })}`;
  // mock.onPost(`${resource.legalPeople}/download/instant`).reply(200, response, {
  //   "content-disposition":
  //     "attachment; filename=\"HH_12299_PR_021623_23 32' 30''.csv\"",
  // });

  mock.onPost(`${resource.legalPeople}/download/instant`).reply(400, {
    companyExportLimit: 300,
    userExportLimit: 2000,
    companyCurrentExportCount: 548,
    userCurrentExportCount: 377,
    isEnabled: true,
    error: "EXPORT_LIMIT_REACHED",
  });
};

export const scheduledCsvExport = (mock) => {
  const response = `"Download Name", ${JSON.stringify({ name: "Test" })}`;
  mock.onPost(`${resource.legalPeople}/download/async`).reply(200, response, {
    "content-disposition":
      "attachment; filename=\"HH_12299_PR_021623_23 32' 30''.csv\"",
  });
};

export default [
  contactRequest,
  // instantCsvExport,
  // scheduledCsvExport,
];
