import React, { useState, useEffect } from "react";
import { render, unmountComponentAtNode } from "react-dom";
import { NavLink } from "react-router-dom";

import TutorialModal from "@components/Tutorial/Modal/Modal";
import { SubNav } from "@components/Layout/Header/Default";
import { configKey } from "@config/tutorial";

const NavLinks = ({ modalContainer }) => {
  const [isMenudExpanded, setIsMenudExpanded] = useState(true);
  const [tutorial, setTutorial] = useState({
    visible: false,
    key: "",
  });

  const setTutorialModal = (key) => {
    setTutorial({
      key,
      visible: !!key,
    });
  };

  useEffect(() => {
    return () => {
      unmountComponentAtNode(document.querySelector(`.${modalContainer}`));
    };
  }, []);

  useEffect(() => {
    render(
      <TutorialModal
        tutorialKey={tutorial.key}
        visible={tutorial.visible}
        onCancel={() => {
          setTutorialModal();
        }}
      />,
      document.querySelector(`.${modalContainer}`)
    );
  }, [tutorial]);

  return (
    <>
      <div className="collapse-menu">
        <div className="root-menu">
          <SubNav
            label="Show Tutorial"
            links={[
              <NavLink
                to="#"
                key="menu-quick-search"
                onClick={(e) => {
                  e.preventDefault();
                  setTutorialModal(configKey.quickSearch);
                }}
              >
                Quick Search
              </NavLink>,
            ]}
            onExpand={() => setIsMenudExpanded((state) => !state)}
            expand={isMenudExpanded}
          />
        </div>
      </div>
    </>
  );
};

export default NavLinks;
