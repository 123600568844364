export default {
  SEARCH_LEGAL_SET_DATA: "SET_DATA",
  SEARCH_LEGAL_GET: "SEARCH_LEGAL_GET",
  SEARCH_LEGAL_GET_START: "SEARCH_LEGAL_GET_START",
  SEARCH_LEGAL_GET_COMPLETE: "SEARCH_LEGAL_GET_COMPLETE",
  SEARCH_LEGAL_SAVE: "SEARCH_LEGAL_SAVE",
  SEARCH_LEGAL_SAVE_START: "SEARCH_LEGAL_SAVE_START",
  SEARCH_LEGAL_SAVE_COMPLETE: "SEARCH_LEGAL_SAVE_COMPLETE",
  SEARCH_LEGAL_SAVE_AS: "SEARCH_LEGAL_SAVE_AS",
  SEARCH_LEGAL_SAVE_AS_START: "SEARCH_LEGAL_SAVE_AS_START",
  SEARCH_LEGAL_SAVE_AS_COMPLETE: "SEARCH_LEGAL_SAVE_AS_COMPLETE",
  SEARCH_LEGAL_RESET_STATE: "SEARCH_LEGAL_RESET_STATE",
  SEARCH_LEGAL_TOGGLE_AUTO_COLLAPSE: "SEARCH_LEGAL_TOGGLE_AUTO_COLLAPSE",
};
