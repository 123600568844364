import {
  takeEvery,
  all,
  put,
  delay,
  takeLatest,
  select,
} from "redux-saga/effects";
import _get from "lodash.get";
import _isfunction from "lodash.isfunction";

import platormApiSvc from "@services/platform-api/";
import resource from "@config/resource";
import { getData as resourceGetData } from "@store/actions/resource/";
import actionTypes from "@store/actions/search/actionTypes";
import { LOADING, SUCCESS, FAIL, ERROR } from "@config/constants";
import { searchRefineResult } from "@store/selectors/search";

import {
  getStart,
  getComplete,
  saveStart,
  saveComplete,
  saveAsStart,
  saveAsComplete,
  assignReqStart,
  assignReqComplete,
  newReqStart,
  newReqComplete,
  assignReq as assignToReq,
  copyReqStart,
  copyReqComplete,
  searchKeywordStart,
  searchKeywordMatchComplete,
  updateRefineSearchResultCount,
} from "@store/actions/search/";
import axios from "axios";

const cancelTokens = {};

function* save(params) {
  const { config, cb } = params;
  yield put(saveStart());
  const search = {
    save: {
      notif: {
        type: "",
        message: "",
      },
    },
  };
  let error;

  try {
    const { data } = yield platormApiSvc(config);
    search.data = data;
    search.save.status = SUCCESS;
  } catch (e) {
    error = e;
    search.save = {
      status: FAIL,
      notif: {
        type: "error",
        message: e.apiMessage,
      },
    };
  }

  yield put(saveComplete(search));
  if (_isfunction(cb)) {
    return cb(error);
  }
}

function* saveAs(params) {
  const { config } = params;
  yield put(saveAsStart());
  yield delay(2000);

  const state = {
    saveAs: {
      notif: {
        type: "",
        message: "",
      },
    },
  };
  try {
    const { data } = yield platormApiSvc(config);
    state.data = data;
    state.saveAs.status = SUCCESS;
  } catch (error) {
    const message =
      _get(error, "response.status") === 409 ||
      _get(error, "request.status") === 409
        ? "The search name you entered already exists."
        : error.apiMessage;
    state.saveAs = {
      status: FAIL,
      notif: {
        type: ERROR,
        message: message,
      },
    };
  }
  yield put(saveAsComplete({ state }));
}

function* get(params) {
  const { id } = params;
  yield put(getStart());

  const search = {
    data: {},
    get: {},
    requisition: {},
  };
  try {
    const getParams = {
      projection: "full",
    };
    const { data } = yield platormApiSvc.get(
      `${resource.candidateSearches}/${id}`,
      {
        params: getParams,
      }
    );
    search.data = data;
    search.get.status = SUCCESS;
  } catch (e) {
    search.get = {
      status: FAIL,
      notif: {
        type: "error",
        message: e.apiMessage,
      },
    };
  }

  yield put(getComplete(search));
}

function* assignReq(params) {
  const { candidateSearch, requisition, isNew } = params;
  yield put(assignReqStart({ isNew }));

  const req = {
    data: {},
    notif: {
      type: "",
      message: "",
    },
    status: "",
  };

  try {
    const data = {
      requisition:
        requisition === null ? null : _get(requisition, "_links.self.href"),
    };
    yield platormApiSvc.patch(candidateSearch, data);
    req.data = requisition;
  } catch (e) {
    req.status = {
      status: FAIL,
      notif: {
        type: "error",
        message: e.apiMessage,
      },
    };
  }

  yield put(resourceGetData("requisitions", { size: 100000 }));
  yield put(assignReqComplete(req));
}

function* newReq(params) {
  if (params) {
    return null;
  }
  yield put(newReqStart());

  try {
    const { data } = yield platormApiSvc.post(
      `${resource.requisitions}`,
      params.requisition
    );
    search.requisition = data;

    if (data) {
      const reqParams = {
        candidateSearch: params.candidateSearch,
        requisition: data,
        isNew: true,
      };
      yield put(assignToReq(reqParams));
    }
  } catch (e) {
    search.get = {
      status: "fail",
      notif: {
        type: "error",
        message: e.apiMessage,
      },
    };
  }

  yield put(newReqComplete(search.requisition));
}

function* copyToReq(params) {
  const { url, criteria } = params;
  yield put(copyReqStart());
  const apiParams = {
    method: "patch",
    url: url,
    data: {
      parameters: criteria,
    },
  };
  const search = {
    notif: {
      type: "",
      message: "",
    },
  };
  try {
    const authResp = yield platormApiSvc(apiParams);
    search.data = authResp.data;
    // status += '_success';
  } catch (e) {
    search.save = {
      status: "fail",
      notif: {
        type: "error",
        message: e.apiMessage,
      },
    };
  }
  yield put(copyReqComplete(search));
}

function* searchKeyword(params) {
  const { keyword } = params;
  yield put(searchKeywordStart());
  const search = {
    notif: {
      type: "",
      message: "",
    },
  };
  try {
    const { data } = yield platormApiSvc.get(`${resource.searchKeyword}`, {
      params: {
        keywords: keyword,
      },
    });
    search.data = data;
  } catch (e) {
    search.status = {
      status: FAIL,
      notif: {
        type: "error",
        message: e.apiMessage,
      },
    };
  }
  yield put(searchKeywordMatchComplete(search));
}

function* getRefineSearchResultCount({ payload }) {
  const { requestId: cancelId } = yield select(searchRefineResult);

  if (cancelId && cancelTokens[cancelId]) {
    cancelTokens[cancelId].cancel();
  }

  const requestId = Date.now();
  cancelTokens[requestId] = axios.CancelToken.source();

  yield put(
    updateRefineSearchResultCount({
      requestId,
      status: LOADING,
    })
  );
  try {
    const { data } = yield platormApiSvc.post(
      `${resource.candidates}/search/countByParameters`,
      {
        ...payload,
      },
      {
        cancelToken: cancelTokens[requestId].token,
      }
    );
    yield put(
      updateRefineSearchResultCount({ status: "", count: data.resultCount })
    );
  } catch (e) {
    yield put(updateRefineSearchResultCount({ status: FAIL }));
  }
}

export default function* search() {
  yield all([
    takeEvery(actionTypes.SEARCH_GET, get),
    takeEvery(actionTypes.SEARCH_SAVE, save),
    takeEvery(actionTypes.SEARCH_SAVE_AS, saveAs),
    takeEvery(actionTypes.SEARCH_ASSIGN_REQ, assignReq),
    takeEvery(actionTypes.SEARCH_NEW_REQ, newReq),
    takeEvery(actionTypes.SEARCH_COPY_REQ, copyToReq),
    takeEvery(actionTypes.SEARCH_KEYWORD_MATCH, searchKeyword),
    takeLatest(
      actionTypes.SEARCH_GET_REFINE_SEARCH_RESULT_COUNT,
      getRefineSearchResultCount
    ),
  ]);
}
