import Chance from 'chance';

const chance = new Chance();

const data = {
  id: 1001,
  name: chance.profession(),
  parameters: {
    mustHaveSkills: [{
      id: 'JAVA',
      category: 'Programming Language',
      skillsCategory: 'SKILL',
      name: 'Java',
      inferred: true
    }, {
      id: 'C++',
      category: 'Programming Language',
      skillsCategory: 'SKILL',
      name: 'C++'
    }, {
      id: 'TABLEAU',
      category: 'Data Visualization tool',
      skillsCategory: 'SPECIALIZATION',
      name: 'Tableau'
    }, {
      id: 'KNOWLEDGE OF DATABASE',
      category: 'Database',
      skillsCategory: 'SKILL',
      name: 'Knowledge of Database'
    }, {
      id: 'ATLASSIAN JIRA',
      category: 'Collaboration platform',
      skillsCategory: 'PRODUCT',
      name: 'Atlassian Jira'
    }, {
      name: 'NLP'
    }, {
      name: 'Golang'
    }],
    niceToHaveSkills: [{
      id: 'POWER BI',
      name: 'Power BI',
      category: 'Data Visualization tool',
      skillsCategory: 'PRODUCT'
    }, {
      id: 'C',
      name: 'C',
      category: 'Programming Language',
      skillsCategory: 'PRODUCT'
    }, {
      id: 'ZENDESK',
      name: 'Zendesk',
      category: 'Collaboration platform',
      skillsCategory: 'PRODUCT'
    }, {
      id: 'SLACK',
      name: 'Slack',
      category: 'Collaboration platform',
      skillsCategory: 'PRODUCT'
    }]
  }
};

export default data;