import React, { Fragment } from 'react';
import { Modal as AntModal, Button } from 'antd';
import classnames from 'classnames';
import _clonedeep from 'lodash.clonedeep';

import { hhPrefixer } from '@helpers/collection';
const hhClass = hhPrefixer(['modal', 'btn-prim-orange-d1', 'btn-white'], true);
export const ConfirmButtons = props => {
  const { okButtonProps = {}, cancelButtonProps = {}, className } = _clonedeep(
    props
  );
  if (!okButtonProps.children) {
    okButtonProps.children = 'Ok';
  }
  if (!cancelButtonProps.children) {
    cancelButtonProps.children = 'Cancel';
  }
  const okBtnClassName = okButtonProps.className
    ? okButtonProps.className
    : [hhClass['btn-prim-orange-d1'], 'hover-effect'].join(' ');

  return (
    <Button.Group className={classnames(className, 'w-100')}>
      <Button
        {...okButtonProps}
        className={classnames(okBtnClassName, 'w-50 ')}
      />
      <Button
        {...cancelButtonProps}
        className={classnames(
          cancelButtonProps.className,
          hhClass['btn-white'],
          'w-50 hover-effect'
        )}
      />
    </Button.Group>
  );
};

export const UnsavedChangesContent = ({ resource }) => {
  const partialMessage = resource ? (
    <Fragment>
      {`to your ${resource}`}
      <br />
    </Fragment>
  ) : (
    ''
  );

  return (
    <div className="text-center">
      <p>You have made changes {partialMessage} that have not been saved.</p>
      <p>Would you like to save your changes?</p>
    </div>
  );
};

class Modal extends AntModal {
  render () {
    const { className, ...restProps } = this.props;
    return (
      <AntModal
        className={classnames(hhClass.modal, className)}
        {...restProps}
      />
    );
  }
}

Modal.defaultProps = {
  closable: false,
  maskClosable: false,
  footer: null
};

export default Modal;
