import _get from "lodash.get";
import moment from "moment";

import yup from "@services/yup";
import resource from "@config/resource";
import platformApiSvc from "@services/platform-api/";

export const constants = {
  UNAVAILABLE: "UNAVAILABLE",
  LIMITED: "LIMITED",
  UNLIMITED: "UNLIMITED",
  UNLIMITED_ON_DEMAND: "UNLIMITED_ON_DEMAND",
  PENDING: "Pending",
  VERIFIED: "Verified",
  FAILED: "Failed",
  EMAIL_STATUS_PENDING: "PENDING",
  EMAIL_STATUS_SENT: "SENT",
  EMAIL_STATUS_FAILED: "FAILED",
};

export const availabilityType2 = {
  [constants.LIMITED]: "Limited",
  [constants.UNLIMITED]: "Unlimited",
};

export const emailStatusType = {
  [constants.EMAIL_STATUS_PENDING]: "Pending",
  [constants.EMAIL_STATUS_SENT]: "Sent",
  [constants.EMAIL_STATUS_FAILED]: "Failed",
};

export const emailConfigStatusType = {
  [constants.PENDING]: "Domain verification pending",
  [constants.VERIFIED]: "Domain Verified",
  [constants.FAILED]: "Domain verification failed",
};

export const reVerifyEmailConfigStatus = {
  [constants.PENDING]: "Email verification pending",
  [constants.VERIFIED]: "Email verified",
  [constants.FAILED]: "Email verification failed",
};

export const contactAvailabilityType = {
  [constants.UNAVAILABLE]: "Unavailable",
  [constants.UNLIMITED]: "Unlimited",
  [constants.UNLIMITED_ON_DEMAND]: "Unlimited plus On Demand",
};
export const searchAvailabilityType = {
  UNAVAILABLE: "Unavailable",
  LIMITED: "Limited",
  UNLIMITED: "Unlimited",
};
export const licenseTypeKeys = {
  STARTER: "STARTER",
  PROFESSIONAL: "PROFESSIONAL",
  ENTERPRISE: "ENTERPRISE",
};
export const licenseTypeOptions = [
  {
    id: "STARTER",
    label: "Starter",
  },
  {
    id: "PROFESSIONAL",
    label: "Professional",
  },
  {
    id: "ENTERPRISE",
    label: "Enterprise",
  },
];
export const licenseTermsOptions = [
  {
    id: "ONE_MONTH",
    label: "1 Month",
    duration: {
      months: 1,
    },
  },
  {
    id: "THREE_MONTHS",
    label: "3 Months",
    duration: {
      months: 3,
    },
  },
  {
    id: "SIX_MONTHS",
    label: "6 Months",
    duration: {
      months: 6,
    },
  },
  {
    id: "ONE_YEAR",
    label: "1 Year",
    duration: {
      years: 1,
    },
  },
  {
    id: "THREE_YEARS",
    label: "3 Years",
    duration: {
      years: 3,
    },
  },
];

export const searchResultLimit = 25;

export const fieldMapping = {
  name: "Company Name",
  active: "Active",
  allowNotes: "Allow Notes",
  msa: "MSA",
  relatedContracts: "Related Contract",
  licenseType: "License Type",
  licenseTerms: "License Term",
  licensedUserCount: "Licensed Users",
  activationDate: "Activation Date",
  expirationDate: "Expiration Date",
  totalExportCount: "Export Limit per Company",
  exportPerUserCount: "Export Limit per User",
  billingContact: "Contact Name",
  billingStreet: "Street",
  billingEmail: "Email",
  billingPhone: "Phone",
  location: "City/State/Zip code",
  enforceDataSecurity: "Enforce Data Security",
  allowedDepartments: "Department Access",
  accountContact: "Contact Name",
  accountEmail: "Email",
  searchAvailabilityType: "Saved Searches",
  totalSearchCount: "Saved Search Limit per Company",
  searchPerUserCount: "Saved Search Limit per User",
  searchesPerDay: "Searches per Day",
  searchesPerDayLimit: "Search Limit per Day per User",
  searchResult: "Search Results",
  searchResultLimit: "Search Results per User",
  requisitionPerUser: "Requisitions",
  requisitionPerUserLimit: "Requisition Limit per User",
  currentRequisitionCount: "Current Requisitions per Company",
  contactAvailabilityType: "Contact Information",
  totalContactRequestCount: "Request Limit per Company",
  totalContactRequestPerUserCount: "Request Limit per User",
  countries: "Country Access",
  exportAddOn: "Export Add-ons",
  exportToAts: "Export to ATS",
  exportAsCsv: "Export as CSV",
  atsLastUpdate: "Last Update",
  atsLastActivation: "Last ATS Activation",
  diversity: "Diversity",
  communicationAddOn: "Communication Add-ons",
  candidateProfileAddOn: "Candidate Profile Add-ons",
  emailIntegration: "Email Integration",
  emailDomain: "Email Domain",
  itAdministratorEmail: "Domain Adminstrator’s Email Address",
  ccEmails: "CC Email Addresses",
  bccEmails: "BCC Email Addresses",
  moduleName: "Module",
  securityClearance: "Security Clearance",
  hhioInternal: "HHIO Internal Company",
  autoDialerEnabled: "Auto Dialer Integration",
  autoDialerUserCount: "Licensed Users for Auto Dialer",
  licensedAutoDialerUsers: "Current Licensed Users for Auto Dialer",
};

export const ADMIN_TOOLTIP = {
  diversity: "Includes Ethnicity, Gender & Veteran",
  securityClearance:
    "Includes Clearance Level, Authorizations & Additional Inclusions",
};

export const defaultDocumentValue = {
  name: "",
  active: true,
  allowNotes: true,
  msa: "",
  relatedContracts: [],
  licenseType: "",
  licenseTerms: null,
  licensedUserCount: 0,
  activationDate: null,
  expirationDate: null,
  totalExportCount: 100,
  exportPerUserCount: 100,
  billingContact: "",
  billingStreet: "",
  billingEmail: "",
  billingPhone: {
    country: "US",
    number: "",
  },
  location: null,
  enforceDataSecurity: false,
  allowedDepartments: [],
  accountContact: "",
  accountEmail: "",
  hhMasterId: null,
  hhMasterSiteId: null,
  contactAvailabilityType: "UNAVAILABLE",
  searchAvailabilityType: "UNAVAILABLE",
  totalSearchCount: 0,
  searchPerUserCount: 0,
  searchesPerDay: constants.LIMITED,
  searchesPerDayLimit: 0,
  searchResult: constants.LIMITED,
  searchResultLimit: searchResultLimit,
  requisitionPerUser: constants.LIMITED,
  requisitionPerUserLimit: 0,
  totalContactRequestCount: 0,
  totalContactRequestPerUserCount: 0,
  countries: [],
  atsExportEnabled: false,
  csvExportEnabled: true,
  diversityEnabled: false,
  bulkEmailEnabled: false,
  securityLicenseEnabled: false,
  moduleName: [],
  hhioInternal: false,
  autoDialerEnabled: false,
  autoDialerUserCount: 0,
};

const validExpirationDate = (activationDate, schema) => {
  return moment.isMoment(activationDate)
    ? schema.min(
        activationDate,
        "${path} must be later than the activation date." // eslint-disable-line
      )
    : schema;
};

const isUniqueName = (validateDuplicateName, documentId, schema) => {
  return validateDuplicateName
    ? schema.test({
        name: "isUniqueName",
        message:
          "${path} already exists. Enter a new name for your company account.", // eslint-disable-line
        async test(name) {
          if (!name) {
            return true;
          }
          try {
            const resp = await platformApiSvc.get(
              `${resource.companyAccounts}/search/findByName`,
              {
                params: { name },
              }
            );
            const hasDuplicate = _get(
              resp,
              "data._embedded.companyAccounts",
              []
            ).some(({ id }) => documentId !== id);
            return !hasDuplicate;
          } catch (e) {
            return false;
          }
        },
      })
    : schema;
};

const validNumber = "${path} must be an integer"; // eslint-disable-line
const shortText = (message) =>
  yup
    .string()
    .max(50, message)
    .trim();
const countSchema = () =>
  yup
    .number(validNumber)
    .min(0)
    .typeError(validNumber);
const searchSchema = () =>
  yup
    .number(validNumber)
    .min(1, "Please enter the number of saved searches")
    .typeError(validNumber);
const contactSchema = () =>
  yup.number(validNumber).when("contactAvailabilityType", {
    is: "UNLIMITED_ON_DEMAND",
    then: yup
      .number()
      .min(1, "Please enter the number of contact requests")
      .typeError(validNumber),
    otherwise: yup
      .number()
      .min(0, "Please enter the number of contact requests")
      .typeError(validNumber),
  });
const dateSchema = (label) =>
  yup
    .date()
    .nullable()
    .required()
    .typeError("${path} is required") // eslint-disable-line
    .label(label);

export const schema = {
  name: shortText()
    .required()
    .when(["$validateDuplicateName", "id"], isUniqueName)
    .label(fieldMapping.name),
  active: yup.boolean().label(fieldMapping.active),
  allowNotes: yup.boolean().label(fieldMapping.allowNotes),
  msa: shortText().label(fieldMapping.msa),
  relatedContracts: yup
    .array()
    .of(
      shortText(
        `${fieldMapping.relatedContracts} is limited to ` + "${max} characters" // eslint-disable-line
      ).nullable()
    )
    .label(fieldMapping.relatedContracts),
  licenseType: shortText()
    .required()
    .label(fieldMapping.licenseType),
  licenseTerms: shortText()
    .nullable()
    .label(fieldMapping.licenseTerms),
  licensedUserCount: countSchema()
    .required()
    .label(fieldMapping.licensedUserCount),
  expirationDate: dateSchema(fieldMapping.expirationDate).when(
    "$activationDate",
    validExpirationDate
  ),
  activationDate: dateSchema(fieldMapping.activationDate),
  totalExportCount: countSchema().label(fieldMapping.totalExportCount),
  exportPerUserCount: countSchema()
    .required()
    .max(
      yup.ref("totalExportCount"),
      "${path} should not be greater than " + fieldMapping.totalExportCount // eslint-disable-line
    )
    .label(fieldMapping.exportPerUserCount),
  billingContact: shortText().label(fieldMapping.billingContact),
  billingEmail: shortText()
    .email(
      "You have entered an invalid email. Please add a valid email address"
    )
    .label(fieldMapping.billingEmail),
  billingPhone: yup
    .object()
    .shape({
      country: shortText().label(fieldMapping.billingPhone),
      number: shortText().label(fieldMapping.billingPhone),
    })
    .transform((value) => {
      const { country, number } = value;
      return {
        country,
        number: number.replace(/[^0-9]+/g, ""),
      };
    }),
  billingStreet: shortText().label(fieldMapping.billingStreet),
  location: shortText()
    .nullable()
    .label(fieldMapping.location),
  enforceDataSecurity: yup.boolean().label(fieldMapping.enforceDataSecurity),
  allowedDepartments: yup
    .array()
    .when("$licenseType", (licenseType, schema) => {
      return schema; // temporary disable validation based on licenseType

      // if (licenseType === licenseTypeKeys.STARTER) {
      //   return schema
      //     .required()
      //     .min(1, "${path} is required.") // eslint-disable-line
      //     .max(1, "Max selected option is 1");
      // } else if (licenseType === licenseTypeKeys.PROFESSIONAL) {
      //   return schema
      //     .required()
      //     .min(1, "${path} is required.") // eslint-disable-line
      //     .max(2, "Max selected option is 2");
      // } else {
      //   schema;
      // }
    })
    .label(fieldMapping.allowedDepartments),
  accountContact: shortText().label(fieldMapping.accountContact),
  accountEmail: shortText()
    .email(
      "You have entered an invalid email. Please add a valid email address"
    )
    .label(fieldMapping.accountEmail),
  id: yup.mixed().notRequired(),
  hhMasterId: yup
    .mixed()
    .nullable()
    .notRequired(),
  hhMasterSiteId: yup
    .mixed()
    .nullable()
    .notRequired(),
  searchAvailabilityType: shortText()
    .required()
    .label(fieldMapping.searchAvailabilityType),
  totalSearchCount: searchSchema()
    .required()
    .label(fieldMapping.totalSearchCount),
  searchPerUserCount: searchSchema()
    .required()
    .max(
      yup.ref("totalSearchCount"),
      "${path} should not be greater than " + fieldMapping.totalSearchCount // eslint-disable-line
    )
    .label(fieldMapping.searchPerUserCount),
  searchesPerDay: shortText()
    .required()
    .label(fieldMapping.searchesPerDay),
  searchesPerDayLimit: countSchema().label(fieldMapping.searchesPerDayLimit),
  searchResult: shortText()
    .required()
    .label(fieldMapping.searchResult),
  searchResultLimit: countSchema().label(fieldMapping.searchResultLimit),
  requisitionPerUser: shortText()
    .required()
    .label(fieldMapping.requisitionPerUser),
  requisitionPerUserLimit: countSchema().label(
    fieldMapping.requisitionPerUserLimit
  ),
  contactAvailabilityType: shortText()
    .required()
    .label(fieldMapping.contactAvailabilityType),
  totalContactRequestCount: contactSchema()
    .required()
    .label(fieldMapping.totalContactRequestCount),
  totalContactRequestPerUserCount: contactSchema()
    .required()
    .max(
      yup.ref("totalContactRequestCount"),
      "${path} should not be greater than " + // eslint-disable-line
        fieldMapping.totalContactRequestCount
    )
    .label(fieldMapping.totalContactRequestPerUserCount),
  countries: yup
    .array()
    .required()
    .min(1, `${fieldMapping.countries} is required`)
    .when("$licenseType", (licenseType, schema) => {
      if (licenseType === licenseTypeKeys.STARTER) {
        return schema.max(1, `Max selected option is 1`);
      } else {
        return schema;
      }
    }),
  atsExportEnabled: yup.boolean(),
  csvExportEnabled: yup.boolean(),
  diversityEnabled: yup.boolean(),
  bulkEmailEnabled: yup.boolean(),
  securityLicenseEnabled: yup.boolean(),
  domainVerificationStatus: shortText().nullable(),
  moduleName: yup
    .array()
    .min(1, "Module is required")
    .max(1, "Module are limited to 1"),
  hhioInternal: yup.boolean(),
  autoDialerEnabled: yup.boolean(),
  autoDialerUserCount: countSchema().label(fieldMapping.autoDialerUserCount),
};

export const emailDefaultFormData = {
  domain: "",
  itAdministratorEmail: "",
  cc: [],
  bcc: [],
};

export const emailFormSchema = {
  domain: yup
    .string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Invalid email domain"
    )
    .trim()
    .typeError("${path} is required") // eslint-disable-line
    .notRequired()
    .label("Email Domain"),
  itAdministratorEmail: yup
    .string()
    .trim()
    .email("Invalid email address")
    .notRequired(),
  cc: yup.array().of(yup.string().email("Invalid email address")),
  bcc: yup.array().of(yup.string().email("Invalid email address")),
};

export const maxEmailCount = 10;

export default {
  emailDefaultFormData,
  fieldMapping,
  defaultDocumentValue,
  licenseTypeOptions,
  licenseTermsOptions,
};
