import { combineReducers } from "redux";

import app from "./app";
import user from "./user";
import search from "./search";
import criteria from "./criteria";
import candidate from "./candidate";
import resource from "./resource";
import tipboard from "./tipboard";
import ats from "./ats";
import requisition from "./requisition";
import searchLegal from "../../search-legal/store/reducers/search";
import autoDialer from "./auto-dialer";
import company from "./company";
import communication from "./communication";

export default combineReducers({
  app,
  user,
  search,
  searchLegal,
  criteria,
  candidate,
  resource,
  tipboard,
  ats,
  requisition,
  autoDialer,
  company,
  communication,
});
