import Chance from "chance";

const chance = new Chance();

const data = {
  _embedded: {
    candidateProspects: Array.from({ length: 50 }, () => {
      return {
        requisition: {
          name: chance.profession(),
          requiredByDate: chance.date(),
          recruiterName: chance.name(),
          status: chance.pickone(["DRAFT", "OPEN", "ON_HOLD", "CLOSED"]),
        },
        histories: Array.from({ length: 5 }, () => {
          return {
            isDisqualified: chance.bool(),
            time: chance.date(),
            stage: chance.pickone([
              "PROSPECT",
              "SHORT_LIST",
              "SCREEN",
              "MANAGER_REVIEW",
              "INTERVIEW",
              "OFFER",
              "HIRE",
              "DISQUALIFIED",
            ]),
            status: chance.pickone(["PENDING", "ACTIVE", "DNQ"]),
          };
        }),
      };
    }),
  },
};

export default data;
