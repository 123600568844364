export function templateStringValidation(value, ctx) {
  const { variables, matcher } = this;
  if (!value) return true;

  const invalidVariables = [];
  const varriablesWithError = [];

  (value.match(matcher) || []).forEach((variable) => {
    const variableKey = variable.replace(/{|}/g, "");

    if (
      !invalidVariables.includes(variableKey) &&
      !variables.includes(variableKey)
    ) {
      invalidVariables.push(variableKey);

      if (!varriablesWithError.includes(variableKey)) {
        variables.some((variable) => {
          if (variableKey.search(variable) > -1) {
            varriablesWithError.push(variable);
            return true;
          }
        });
      }
    }
  });

  variables.forEach((variable) => {
    if (varriablesWithError.includes(variable)) return;

    const keyOccurence = value.match(new RegExp(variable, "g")) || [];
    const formattedOccurence =
      value.match(new RegExp("{{" + variable + "}}", "g")) || [];

    if (keyOccurence.length !== formattedOccurence.length) {
      invalidVariables.push(variable);
    }
  });

  return invalidVariables.length
    ? ctx.createError({
        message:
          "Invalid variable format: " +
          invalidVariables.map((v) => `"${v}"`).join(", "),
      })
    : true;
}
