import resource from "@config/resource";
import moment from "moment";
import data from "./data";
import companyAdminData from "./data-company.json";

export const auth = (mock) => {
  mock.onPost(resource.auth).reply(200, data.auth);
};

export const profile = (mock) => {
  mock.onGet(`${resource.accounts}/search/me`).reply(200, data.profile);
};

export const userList = (mock) => {
  const users = Array.from({ length: 25 }, (user, i) => {
    const id = i + 1000;
    return {
      id,
      name: `John Doe ${id}`,
      username: `username${id}`,
      activationDate: moment().format(),
      expirationDate: moment().format(),
      active: !(i % 2),
    };
  });
  mock.onGet("/user-list").reply(200, {
    _embedded: { users },
    page: {
      size: 25,
      totalElements: 150,
      totalPages: 6,
    },
  });
};

export const getCountry = (mock) => {
  mock.onGet(`${resource.countries}/v2`).reply(200, {
    defaultOption: [
      {
        id: "US",
        name: "United States",
      },
    ],
    items: [
      {
        id: "US",
        name: "United States of America",
      },
      {
        id: "CA",
        name: "Canada",
      },
      {
        id: "PH",
        name: "Philippines",
      },
      {
        id: "ENG",
        name: "England",
      },
    ],
  });
};

export const getLicense = (mock) => {
  mock.onGet(`${resource.accounts}/license`).reply(200, {
    diversityEnabled: true,
    bulkEmailEnabled: true,
    emailAllActiveUsers: false,
    securityLicenseEnabled: false,
    identityEmailStatus: "Verified",
    exportLicense: {
      companyExportLimit: 300,
      userExportLimit: 300,
      companyCurrentExportCount: 527,
      userCurrentExportCount: 355,
      isEnabled: true,
    },
    contactRequestLicense: {
      contactAvailabilityType: "UNLIMITED_ON_DEMAND",
      totalContactRequestCount: 10000,
      totalContactRequestCountPerUser: 10000,
      currentContactRequestCountPerUser: 70,
      currentContactRequestCount: 676,
      isEnabled: true,
    },
  });
};

export const getCompanyAdminData = (mock) => {
  mock
    .onGet(new RegExp(`${resource.companyAccounts}/[0-9]`))
    .reply(200, companyAdminData);
};

export const updateCompanyAdminData = (mock) => {
  mock
    .onPatch(new RegExp(`${resource.companyAccounts}/[0-9]`))
    .reply(200, companyAdminData);
};

const handlers = [
  // userList,
  // getCountry,
  // auth,
  // getLicense,
  // getCompanyAdminData,
  // updateCompanyAdminData,
];

export default handlers;
