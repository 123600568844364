import actionTypes from "./actionTypes";

export const startGetCommunications = (queryParams) => {
  return {
    queryParams,
    type: actionTypes.COMM_START_GET_LIST,
  };
};

export const setGetCommunications = (args) => {
  return {
    communications: args.communications,
    getList: args.getList,
    type: actionTypes.COMM_SET_GET_LIST,
  };
};

export const startBulkCancel = (ids) => {
  return {
    ids,
    type: actionTypes.COMM_START_BULK_CANCEL,
  };
};

export const setBulkCancel = ({ bulkCancel }) => {
  return {
    bulkCancel,
    type: actionTypes.COMM_SET_BULK_CANCEL,
  };
};

export const selectCandidates = (args) => {
  return {
    ids: Array.isArray(args.ids) ? args.ids : [args.ids],
    toggle: args.toggle,
    type: actionTypes.COMM_SELECT_CANDIDATES,
  };
};

export const setSelectedCandidates = (ids) => {
  return {
    selectedCandidates: ids,
    type: actionTypes.COMM_SET_SELECTED_CANDIDATES,
  };
};

export const resetGetCommunications = () => {
  return {
    type: actionTypes.COMM_RESET,
  };
};
