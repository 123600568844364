import React, { Component } from 'react';
import classnames from 'classnames';

import { hhPrefixer } from '@helpers/collection';

class DefaultBody extends Component {
  render () {
    const { className, children } = this.props;
    return (
      <div className={classnames(className, 'layout-body', hhPrefixer('layout-body-default'))}>
        {children}
      </div>
    );
  }
}

export default DefaultBody;