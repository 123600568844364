import React from "react";
import { Link } from "react-router-dom";

export const configKey = {
  quickSearch: "quick-search",
};

export const modalTitle = {
  [configKey.quickSearch]: "How to Use Quick Search?",
};

export const searchTips = {
  SAVE_SEARCH: "SAVE_SEARCH",
  LINK_REQUI: "LINK_REQUI",
  ADD_CANDIDATES: "ADD_CANDIDATES",
  MANAGE_CANDIDATES: "MANAGE_CANDIDATES",
};

const ManageCandidateTip = ({ requiId }) => (
  <>
    Visit your requisition to manage candidates,{" "}
    <Link to={`/requisition/${requiId}`} className="req-link">
      Go to Requisition
      <span className="hhi-arrow-forward arrow" />
    </Link>
  </>
);

export const searchTipContent = {
  [searchTips.SAVE_SEARCH]: () =>
    "Save your search and associate with a requisition to add candidates to the pipeline.",
  [searchTips.LINK_REQUI]: () =>
    "Associate your search with a requisition & add candidates to the pipeline.",
  [searchTips.ADD_CANDIDATES]: () =>
    "Add candidates as prospects to your requisition or disqualify them.",
  [searchTips.MANAGE_CANDIDATES]: ManageCandidateTip,
};
