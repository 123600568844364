import { fork, all } from "redux-saga/effects";
import user from "./user";
import search from "./search";
import candidate from "./candidate";
import resource from "./resource";
import app from "./app";
import ats from "./ats";
import requisition from "./requisition";
import searchLegal from "../../search-legal/store/sagas/search";
import autoDialer from "./auto-dialer";
import company from "./company";
import communication from "./communication";

export default function* sagas() {
  yield all([
    fork(app),
    fork(user),
    fork(searchLegal),
    fork(search),
    fork(resource),
    fork(candidate),
    fork(ats),
    fork(requisition),
    fork(autoDialer),
    fork(company),
    fork(communication),
  ]);
}
