export default {
  USER_AUTH: "USER_AUTH",
  USER_AUTH_START: "USER_AUTH_START",
  USER_AUTH_COMPLETE: "USER_AUTH_COMPLETE",
  USER_GET_DATA: "USER_GET_DATA",
  USER_SET_DATA: "USER_SET_DATA",
  USER_LOGOUT: "USER_LOGOUT",
  USER_LOGOUT_COMPLETE: "USER_LOGOUT_COMPLETE",
  USER_DISABLE_TUTORIAL: "USER_DISABLE_TUTORIAL",
  USER_SET_LICENSE: "USER_SET_LICENSE",
  USER_GET_LICENSE: "USER_GET_LICENSE",
};
