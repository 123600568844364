import React from "react";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
// import moment from "moment-timezone";
import { message } from "antd";

import App from "./App";
import { Provider as HhioProvider } from "@providers/hhio";
import store from "./store/configure";
// import initLocale from "@services/locale";
import Logger from "@services/logger";
import { showConfirmRouteModal } from "@helpers/component";
import apiMock from "@services/api-mock";

const userConfirmation = (config, cb) => {
  try {
    config = JSON.parse(config);
  } catch (e) {
    config = {};
  }

  if (config.eventKey) {
    showConfirmRouteModal(config, cb);
  } else {
    cb();
  }
};

const Root = () => (
  <Provider store={store}>
    <BrowserRouter getUserConfirmation={userConfirmation}>
      <HhioProvider>
        <App />
      </HhioProvider>
    </BrowserRouter>
  </Provider>
);

const bootstrap = async () => {
  global.headhuntr = {};

  // moment.tz.setDefault("America/Los_Angeles");
  Logger.init();
  // await initLocale();
  await apiMock();
  message.config({
    maxCount: 2,
  });

  render(<Root />, document.getElementById("root"));
};

bootstrap();
