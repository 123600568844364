import _cloneDeep from 'lodash.clonedeep';
import _set from 'lodash.set';

import actionTypes from '@store/actions/criteria/actionTypes';
import { defaultCriteria } from '@config/criteria';

export default (state = _cloneDeep(defaultCriteria), action = {}) => {
  const { type, ...args } = action;
  const newState = _cloneDeep(state);

  switch (type) {
    case (actionTypes.CRITERIA_SET):
      return args.criteria;
    case (actionTypes.CRITERIA_SET_FIELD):
      _set(newState, args.key, args.value);
      return newState;
    case (actionTypes.CRITERIA_RESET_STATE):
      return _cloneDeep(defaultCriteria);
    default:
      return state;
  }
};