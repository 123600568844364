import apiResource from "@config/resource";
import _data from "./data";

export const getGenericOptions = (mock) => {
  mock.onGet(apiResource.genericOptions).reply(() => {
    const data = {
      result: _data.arrayOptions,
    };

    return [201, data];
  });
};

export default [
  // getGenericOptions
];
