import apiResource from '@config/resource';
import tasks from './tasks';

export const taskList = (mock) => {
  const uri = new RegExp(`${apiResource.tasks}/task-list-page/*`);

  mock.onGet(uri).reply(200, tasks);
};

export default [
  taskList
];