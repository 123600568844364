import React, { Component } from "react";
import { Select, Tag, Menu, Dropdown } from "antd";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import classnames from "classnames";

// const fieldTypes = {
//   MUST_HAVE: 'MUST_HAVE',
//   LIKE_TO_HAVE: 'LIKE_TO_HAVE'
// };

const { Option } = Select;
const options = Array.from({ length: 30 }, (v, key) => ({
  key: `tag-${key + 1}`,
  label: `Candidate skill ${key + 1}`,
}));

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

function Skill(props) {
  const { data, index, children } = props;
  return (
    <Draggable draggableId={data.key} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {children}
        </div>
      )}
    </Draggable>
  );
}

function SkillGroup(props) {
  const { id, children } = props;

  return (
    <Droppable droppableId={id}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          className={classnames("skill-group", {
            over: snapshot.isDraggingOver,
          })}
        >
          {children}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}

function MultiSelect(props) {
  const {
    value = [],
    onChange,
    moveLabel,
    onMove,
    onDrop,
    onRemoveItem,
    fieldType,
    accept,
    options,
  } = props;

  const selected = value.map(({ key }) => key);

  const optionsList = options.map(({ key, label }) => {
    if (props.hideSelected && selected.includes(value)) return null;

    return (
      <Option key={key} value={key}>
        {label}
      </Option>
    );
  });

  const onSelectHandler = (item) => {
    onChange([item, ...value]);
  };

  return (
    <>
      <Select
        value={value}
        onSelect={onSelectHandler}
        onDeselect={onRemoveItem}
        autoClearSearchValue={false}
        notFoundContent={null}
        labelInValue
        mode="multiple"
        className="w-100"
      >
        {optionsList}
      </Select>
      {!!props.showExtTags && (
        <div className={classnames("selected-items mt-2", fieldType)}>
          <SkillGroup id={fieldType} accept={accept} onDrop={onDrop}>
            {value.map((item, i) => {
              const { key, label } = item;
              const menu = (
                <Menu>
                  <Menu.Item onClick={() => onMove(item, i)}>
                    {moveLabel}
                  </Menu.Item>
                </Menu>
              );

              return (
                <Skill
                  key={key}
                  index={i}
                  data={item}
                  type={fieldType}
                  moveCard={() => {}}
                >
                  <Dropdown overlay={menu} trigger={["contextMenu"]}>
                    <Tag
                      className="skill"
                      onClose={() => onRemoveItem(item)}
                      closable
                    >
                      {label}
                    </Tag>
                  </Dropdown>
                </Skill>
              );
            })}
          </SkillGroup>
        </div>
      )}
    </>
  );
}

export default class Poc extends Component {
  state = {
    option1: [],
    option2: [],
    option3: [],
    option4: [],
    mustHave: options.slice(0, 5),
    likeToHave: options.slice(5, 10),
  };

  updateValue(value) {
    const { field } = this;

    this.setState({
      [field]: value,
    });
  }

  moveToMustHave = (item) => {
    this.setState((state) => ({
      likeToHave: state.likeToHave.filter(({ key }) => item.key !== key),
      mustHave: [item, ...state.mustHave],
    }));
  };

  moveToLikeToHave = (item) => {
    this.setState((state) => ({
      mustHave: state.mustHave.filter(({ key }) => item.key !== key),
      likeToHave: [item, ...state.likeToHave],
    }));
  };

  removeItem = (key, item) => {
    this.setState((state) => ({
      [key]: state[key].filter(({ key }) => item.key !== key),
    }));
  };

  onDragEndHandler = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    const sInd = source.droppableId;
    const dInd = destination.droppableId;

    if (sInd === dInd) {
      const items = reorder(this.state[sInd], source.index, destination.index);
      this.setState({
        [sInd]: items,
      });
    } else {
      const result = move(
        this.state[sInd],
        this.state[dInd],
        source,
        destination
      );
      this.setState({
        [sInd]: result[sInd],
        [dInd]: result[dInd],
      });
    }
  };

  render() {
    const { mustHave, likeToHave } = this.state;

    return (
      <DragDropContext onDragEnd={this.onDragEndHandler}>
        <div className="dnd2-poc-container">
          <div className="ms-cont keywords hide-selected">
            <h3>Must Have</h3>
            <MultiSelect
              value={mustHave}
              fieldType="mustHave"
              onChange={(value) => this.setState({ mustHave: value })}
              onMove={this.moveToLikeToHave}
              onDrop={this.moveToMustHave}
              onRemoveItem={(item) => this.removeItem("mustHave", item)}
              moveLabel="Move to `Like To Have`"
              showExtTags={true}
              options={options}
            />

            <h3 className="mt-3">Like to Have</h3>
            <MultiSelect
              value={likeToHave}
              fieldType="likeToHave"
              onChange={(value) => this.setState({ likeToHave: value })}
              onMove={this.moveToMustHave}
              onDrop={this.moveToLikeToHave}
              onRemoveItem={(item) => this.removeItem("likeToHave", item)}
              moveLabel="Move to `Must Have`"
              showExtTags={true}
              options={options}
            />
          </div>
        </div>
      </DragDropContext>
    );
  }
}
