import apiResource from "@config/resource";
import moment from "moment";

const licenseId = 2;
const document = {
  id: licenseId + 1000,
  licensePlan: `License plan ${licenseId}`,
  licenseDescription: "This is test license plan",
  effectiveDate: moment().format(),
  renewalPlan: "123",
  activeStatus: true,
  availableFrequencies: ["DAILY", "WEEKLY", "MONTHLY"],
  type: "BASE",
  scope: "COMPANY",
  support: "EMAIL",
  paymentType: "FREE",
  contactInformation: "LIMITED",
  contactInformationLimit: 100,
  exportType: "LIMITED",
  exportLimit: 200,
  savedSearchType: "LIMITED",
  savedSearchLimit: 300,
  activeUsersCount: 20,
  isAllowedForDelete: false,
  isParentAllowedForDelete: false,
  parentLicense: licenseId + 1000,
  modified: {
    username: "qaappadmin",
    date: moment(),
    startOfDay: moment(),
    name: "Admin QA Application Security",
    userId: 10006,
  },
  created: {
    username: "qaappadmin",
    date: moment(),
    startOfDay: moment(),
    name: "Admin QA Application Security",
    userId: 10006,
  },
};

export const getList = (mock) => {
  mock.onGet("get-licenses").reply(() => {
    const generateRecords = (length = 5) =>
      Array.from({ length }, (v, i) => {
        const id = i + 1000;
        const odd = i % 2 > 0;
        return {
          ...document,
          id,
          licensePlan: "User level License " + id,
          scope: "USER",
          activeStatus: odd,
          isAllowedForDelete: odd,
        };
      });
    const dataSet1 = generateRecords();
    const dataSet2 = Array.from({ length: 5 }, (v, i) => {
      const id = i + 1000;
      return {
        ...document,
        licensePlan: "Company level License " + id,
        id,
      };
    });
    const dataSet3 = Array.from({ length: 5 }, (v, i) => {
      const id = i + 1000;
      return {
        ...document,
        id,
        name: "Add-on " + id,
        scope: "ADD_ON",
      };
    });
    const licenses = [...dataSet1, ...dataSet2, ...dataSet3];
    const data = {
      items: licenses,
      totalPages: licenses.length,
      number: 0,
      size: 25,
    };

    return [201, data];
  });
};

export const findByName = (mock) => {
  mock.onGet("get-licenses-by-name").reply((config) => {
    const data = {};

    if (config.params.name === "dup") {
      data._embedded = {
        securityRoles: [
          {
            id: 1,
          },
        ],
      };
    }
    return [200, data];
  });
};

export const createLicense = (mock) => {
  mock.onPost("ceate-license").reply((config) => {
    const data = JSON.parse(config.data);
    data.id = document.id;
    return [201, data];
  });
};

export const createVersion = (mock) => {
  const uri = new RegExp(`${apiResource.licenseVersions}/*`);
  mock.onPost(uri).reply((config) => {
    const data = JSON.parse(config.data);
    data.id = document.id;
    return [201, data];
  });
};

export const updateVersion = (mock) => {
  const uri = new RegExp(`${apiResource.licenseVersions}/*`);
  mock.onPatch(uri).reply((config) => {
    const data = JSON.parse(config.data);
    data.id = document.id;
    return [201, data];
  });
};

export const getVersions = (mock) => {
  const uri = new RegExp("license-versions/*");
  const data = {
    items: Array.from({ length: 5 }, (v, i) => ({ id: i + 1000 })),
  };
  mock.onGet(uri).reply(200, data);
};

export const deleteRecord = (mock) => {
  const uri = new RegExp(`${apiResource.licenses}/*`);
  mock.onDelete(uri).reply(() => {
    return [204];
  });
};

export const update = (mock) => {
  const uri = new RegExp(`${apiResource.licenses}/*`);
  mock.onPatch(uri).reply((config) => {
    const data = {
      ...document,
      ...JSON.parse(config.data),
    };

    return [200, data];
  });
};

export const getDocument = (mock) => {
  mock.onGet("get-license-detail").reply((config) => {
    const url = config.url.split("/");
    return [
      200,
      {
        ...document,
        id: url[3],
      },
    ];
  });
};

export default [
  getList,
  // createLicense,
  getVersions,
  createVersion,
  updateVersion,
  // getDocument,
  update,
  deleteRecord,
];
