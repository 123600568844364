import _get from "lodash.get";

import apiResource from "@config/resource";
import sourcingSearch from "./search-sourcing-detail.json";
import legalSearch from "./search-legal-detail.json";
import tutorialData from "./quick-search-tutorial.json";
import candidateData from "./candidate.json";
import searchesLegalData from "./data.search-list.json";

export const getTags = (mock) => {
  mock.onGet("/tags").reply((config) => {
    const keyword = _get(config, "params.keyword");
    const divisors = [
      {
        divisor: 3,
        type: "prod",
      },
      {
        divisor: 2,
        type: "skill",
      },
      {
        divisor: 1,
        type: "spec",
      },
    ];
    const data = {
      tags: Array.from({ length: 5 }, (v, i) => {
        let category;
        divisors.some(({ divisor, type }) => {
          const q = i % divisor;
          category = type;
          return q === 0;
        });

        return {
          category,
          value: `${keyword} ${i}`,
        };
      }),
    };

    return [201, data];
  });
};

export const findByName = (mock) => {
  const uri = new RegExp(`${apiResource.candidateSearches}/search/findByName`);
  mock.onAny(uri).reply((config) => {
    const data = config.params.name.indexOf("dup") > -1 ? [{ id: 1 }] : [];
    return [
      200,
      {
        _embedded: { candidateSearches: data },
      },
    ];
  });
};

export const findId = (mock) => {
  const uri = new RegExp(`/${apiResource.candidateSearches}/d+`);
  mock.onAny(uri).reply((config) => {
    const data = config.params.name.indexOf("dup") > -1 ? [{ id: 1 }] : [];
    return [
      200,
      {
        _embedded: { candidateSearches: data },
      },
    ];
  });
};

export const getSearch = (mock) => {
  mock
    .onGet(new RegExp(`${apiResource.candidateSearches}/[0-9]`))
    .reply(200, legalSearch);
};

export const getTutorialDetails = (mock) => {
  mock
    .onGet("http://localhost:3001/quick-search-tutorial.json")
    .reply(200, tutorialData);
};

export const setMaintenance = (mock) => {
  mock.onAny(new RegExp(`/api/*`)).reply(503, {
    error: "ONGOING_MAINTENANCE",
    errorDescription: "Maintenance is on going",
  });
};

export const getGenderOptions = (mock) => {
  mock.onGet(`/api/gender-options`).reply(200, {
    _embedded: {
      genderOptions: [
        {
          id: "1",
          name: "Male",
        },
        {
          id: "2",
          name: "Female",
        },
        {
          id: "3",
          name: "Other",
        },
      ],
    },
  });
};

export const getEthnicityOptions = (mock) => {
  mock.onGet(`/api/ethnicity-options`).reply(200, {
    _embedded: {
      ethnicityOptions: [
        {
          id: "1",
          name: "Irish",
        },
        {
          id: "2",
          name: "Fijian",
        },
        {
          id: "3",
          name: "Sioux",
        },
      ],
    },
  });
};

const candidates = [];
let cand = 1;
while (cand <= 10) {
  candidates.push({
    ...candidateData,
    id: cand,
    candId: cand,
    fullName: `Austin Justin ${cand}`,
  });
  cand++;
}

export const getBookmarkList = (mock) => {
  mock
    .onGet(new RegExp(`${apiResource.candidateUsers}/bookmarked*`))
    .reply(200, {
      page: { size: 50, totalElements: 150, totalPages: 3, number: 0 },
      _embedded: {
        candidates,
      },
    });
};

export const getCandidateDetails = (mock) => {
  mock.onAny(new RegExp(`${apiResource.candidates}/*`)).reply(200, {
    ...candidates[0],
  });
};

export const saveSearch = (mock) => {
  mock
    .onPost(new RegExp(`${apiResource.candidateSearches}/*`))
    .reply(201, sourcingSearch);
};

export const getSearches = (mock) => {
  mock
    .onGet(
      new RegExp(`${apiResource.candidateSearches}/search/findByNameLikeV2*`)
    )
    .reply(200, searchesLegalData);
};

export default [
  // getBookmarkList,
  // getCandidateDetails,
  // getGenderOptions,
  // getEthnicityOptions,
  // setMaintenance,
  // getTutorialDetails,
  // findId
  // getTags
  // getSearch,
  // getSearches,
  // saveSearch,
];
