export const tagMaxLength = 15;
export const hhPrefix = "hh";
export const auth = {
  unauthorizedNotifKey: "UNAUTHORIZED_NOTIF",
};

export const autoSuggestResultCount = 100;

export const timeZone = ""; // PST
export const dateFormat = {
  client: "M/D/YY",
  api: "YYYY-MM-DD",
};

export const timeFormat = {
  client: "h:mm A",
};

export const monthYearFormat = "MMMM YYYY";

export const shortMonthYearFormat = "MMM YYYY";

export const dateTimeFormat = {
  client: "M/D/YY h:mm A",
  clientWithZone: "M/D/YY h:mm a z",
  api: "YYYY-MM-DDTHH:mm:ssZ",
};

export const tooltipDefaultProps = {
  mouseLeaveDelay: 0,
  mouseEnterDelay: 0.2,
  overlayClassName: `${hhPrefix}-tooltip`,
};
// Start: Web client pagination
export const pageSizeOptions = [25, 50];
export const defaultPagination = {
  pageSizeOptions,
  page: 1,
  size: pageSizeOptions[0],
};
// End: Web client pagination

export const stageCountFilter = {
  PENDING_REVIEW: "PENDING_REVIEW",
  IN_PROCESS: "IN_PROCESS",
  DISQUALIFIED: "DISQUALIFIED",
  ALL: "ALL",
};

export const notifMessage = {
  internalError: "Something went wrong.",
};

export const requisitionStatus = {
  DRAFT: "Draft",
  OPEN: "Open",
  ON_HOLD: "On Hold",
  CANCELED: "Canceled",
  CLOSED: "Closed",
};

export const prefixIds = {
  RL: "RL",
  US: "US",
  CO: "CO",
  LP: "LP",
};

export const contactNumRegex = /^[(][0-9]{3}[)][\s][0-9]{3}[-][0-9]{4}$/;

export default {
  autoSuggestResultCount,
  tagMaxLength,
  prefixIds,
  requisitionStatus,
  tooltipDefaultProps,
  notifMessage,
  defaultPagination,
  stageCountFilter,
  hhPrefix,
  auth,
};
