import userHandler from "./user/";
import searchHandler from "./search/";
import resourceHandler from "./resource/";
import companiesHandler from "./companies/";
import utilityHandler from "./utility/";
import candidateHandler from "./candidate/";
import transactionalOps from "./transactional-ops/";
import requisition from "./requisition/";
import secRole from "./security-role/";
import task from "./task/";
import license from "./license/";
import download from "./download/";
import people from "./people/";
import autoDialer from "./auto-dialer/";
import communication from "./communication/";

const handlers = [
  ...candidateHandler,
  ...userHandler,
  ...resourceHandler,
  ...companiesHandler,
  ...utilityHandler,
  ...searchHandler,
  ...transactionalOps,
  ...requisition,
  ...secRole,
  ...task,
  ...license,
  ...download,
  ...people,
  ...autoDialer,
  ...communication,
];

export default handlers;
