import _cloneDeep from "lodash.clonedeep";
import _set from "lodash.set";

import { LOADING } from "@config/constants";
import actionTypes from "@store/actions/candidate/actionTypes";

const defaultNotif = {
  message: "",
  type: "",
};
const defaultList = {
  data: [],
  // parameters: {} FOR_CLEANUP
};
const defaultPage = {
  page: 0,
  size: 25,
};
export const defaultState = {
  list: defaultList,
  get: {
    status: "",
    notif: defaultNotif,
  },
  page: defaultPage,
  exportData: {},
};

export default (state = _cloneDeep(defaultState), action = {}) => {
  const { type, ...args } = action;
  const stateCopy = _cloneDeep(state);

  switch (type) {
    case actionTypes.CANDIDATE_GET_LIST_START:
      return {
        ...state,
        list: { ...defaultList },
        get: {
          status: LOADING,
          notif: { ...defaultNotif },
        },
        page: args.pagination ? args.pagination : { ...defaultPage },
      };
    case actionTypes.CANDIDATE_GET_LIST_COMPLETE:
      return {
        ...state,
        ...args.candidate,
        list: {
          ...state.list,
          ...args.candidate.list,
        },
        get: {
          ...state.get,
          ...args.candidate.get,
        },
        page: {
          ...state.page,
          ...args.candidate.page,
        },
      };
    case actionTypes.CANDIDATE_UPDATE_PROP:
      return _set(stateCopy, args.key, args.value);
    case actionTypes.CANDIDATE_RESET_STATE:
      return _cloneDeep(defaultState);
    case actionTypes.CANDIDATE_SET_EXPORT_DATA:
      return {
        ...state,
        exportData: args.exportData,
      };
    default:
      return state;
  }
};
