import { takeLatest, all, put } from "redux-saga/effects";

import actionTypes from "../actions/action-types";
import { SUCCESS, FAIL } from "@config/constants";
import * as SearchService from "../../services/search";

import { saveStart, saveComplete } from "../actions/";
import { SAVE_SEARCH } from "../../config/error-codes";
import { DEFAULT_STATE } from "../../constants/store";

function* save(params) {
  const { searchDoc } = params;
  yield put(saveStart());

  let state = {};

  try {
    const { data } = yield SearchService.save(searchDoc);
    state = {
      searchData: data,
      status: SUCCESS,
      response: DEFAULT_STATE.save.response,
    };
  } catch (e) {
    state = {
      status: FAIL,
      response: {
        ...DEFAULT_STATE.save.response,
        code: SAVE_SEARCH.INTERNAL_ERROR,
      },
    };
  }

  yield put(saveComplete(state));
}

export default function* search() {
  yield all([takeLatest(actionTypes.SEARCH_LEGAL_SAVE, save)]);
}
