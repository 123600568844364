import moment from "moment";

const atsJobs = {
  items: Array.from({ length: 20 }, (v, i) => ({
    id: `JOB1000${i}`,
    name: i === 3 ? "Test" : `Software Engineer ${i}`,
    createdAt: moment().add(i, "days"),
    hiringManagers: [
      {
        name: i === 3 ? "Jane" : "John Doe",
      },
    ],
  })),
};

export default atsJobs;
