import React from "react";
import DefaultLayout from "@components/Layout/Container/Default";
import SecureRoute from "@containers/SecureRoute/SecureRoute";
import ComponentLoader from "@components/shared/ComponentLoader/ComponentLoader";
import routes from "../config/routes";

const SearchDetail = ComponentLoader({
  loader: () => import("../pages/detail/detail"),
});

const SearchList = ComponentLoader({
  loader: () => import("../pages/list/search-list"),
});

const SearchSecureRoute = [
  <SecureRoute exact path={routes.search} key="legal-search-list">
    <DefaultLayout className="legal-search-list">
      <SearchList />
    </DefaultLayout>
  </SecureRoute>,
  <SecureRoute exact path={routes.search + "/:searchId?"} key="legal-detail">
    <DefaultLayout className="legal-search-detail">
      <SearchDetail />
    </DefaultLayout>
  </SecureRoute>,
];

export default SearchSecureRoute;
