/* eslint-disable */
export const hhLocale = {
  string: {
    max: '${path} is limited to ${max} characters',
    required: '${path} is required'
  },
  mixed: {
    required: '${path} is required'
  }
};
/* eslint-enable */