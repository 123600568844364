import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import _get from 'lodash.get';

import appRoutes from '@config/app-routes';

const excludedUserAgreementRoutes = [appRoutes.login, appRoutes.logout];

function SecureRoute ({ children, isAuthenticated, userAgreement, ...rest }) {
  const renderHandler = ({ location }) => {
    if (isAuthenticated) {
      if (!excludedUserAgreementRoutes.includes(location.pathname)) {
        if (appRoutes.userAgreement === location.pathname) {
          if (userAgreement) {
            return <Redirect to={{ pathname: appRoutes.home }} />;
          }
        } else {
          if (!userAgreement) {
            return <Redirect to={{ pathname: appRoutes.userAgreement }} />;
          }
        }
      }

      return children;
    } else {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location }
          }}
        />
      );
    }
  };

  return (
    <Route
      {...rest}
      render={renderHandler}
    />
  );
}

const stateToProps = (state) => {
  const { user } = state;

  return {
    userAgreement: _get(user, 'data.userAgreement'),
    isAuthenticated: user.isLoggedIn === true
  };
};

export default connect(stateToProps)(SecureRoute);