const data = {
  invalidToken: {
    error: "invalid_token",
    error_description: "Cannot convert access token to JSON",
  },
  auth: {
    id: "0474bc21-9ebe-45a2-91cb-0cc48f0d3fb3",
    token: Date.now(),
    user_name: "john.wick@talentstcout.com",
    access_token:
      "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE1NzY2NTE2NzksInVzZXJfbmFtZSI6InJlY3J1aXRlckBoZWFkaHVudHIuaW8iLCJhdXRob3JpdGllcyI6WyJST0xFX1JFQ1JVSVRFUiJdLCJqdGkiOiI3ZDkwYjZjNC01ODBmLTQ0ZGQtYjM0MS04OTM5OWY1ODk0ZGMiLCJjbGllbnRfaWQiOiJ3ZWJhcHAiLCJzY29wZSI6WyJhbnkiXX0.JZZV2TGHKoyOI8vTiPsV97LurK8QmllKrAdzZvYN1xeFLMX4fPyppO9p1BatP2oZWM2jdMhi9jhYoAphUN88qFqJqiGHlZaNWeCfAc_leJmjGu757s0144L5mMwgAdkq3itD14QAVdqJUXdZ19iVUkxV6lPm4D0zBt8-OppBKj9uIigyc1Zl7xk8h4eFiWYwqnxLyzzt5lZSxJJ6HpZoi8eLRKfGzcY4M0DQv1RZl4iK6NNrb-ee0o5x4EJY8cMz3-1K5r_9FvOn0Qi-HHGOuTwMtTLd47Z53nF4R1b7gLHLmuVsEDr5cwlpRGnnmq6N67H5a7LKnJFfXiHzstcC2w",
    refresh_token:
      "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE1NzY2NTE2NzksInVzZXJfbmFtZSI6InJlY3J1aXRlckBoZWFkaHVudHIuaW8iLCJhdXRob3JpdGllcyI6WyJST0xFX1JFQ1JVSVRFUiJdLCJqdGkiOiI3ZDkwYjZjNC01ODBmLTQ0ZGQtYjM0MS04OTM5OWY1ODk0ZGMiLCJjbGllbnRfaWQiOiJ3ZWJhcHAiLCJzY29wZSI6WyJhbnkiXX0.JZZV2TGHKoyOI8vTiPsV97LurK8QmllKrAdzZvYN1xeFLMX4fPyppO9p1BatP2oZWM2jdMhi9jhYoAphUN88qFqJqiGHlZaNWeCfAc_leJmjGu757s0144L5mMwgAdkq3itD14QAVdqJUXdZ19iVUkxV6lPm4D0zBt8-OppBKj9uIigyc1Zl7xk8h4eFiWYwqnxLyzzt5lZSxJJ6HpZoi8eLRKfGzcY4M0DQv1RZl4iK6NNrb-ee0o5x4EJY8cMz3-1K5r_9FvOn0Qi-HHGOuTwMtTLd47Z53nF4R1b7gLHLmuVsEDr5cwlpRGnnmq6N67H5a7LKnJFfXiHzstcC2w",
  },
  profile: {
    id: 10006,
    created: {
      username: "system",
      date: "2021-09-02T03:07:55+00:00",
      startOfDay: "2021-09-02T00:00:00+00:00",
      name: "system",
      userId: 0,
    },
    modified: {
      username: "qaappadmin",
      date: "2022-12-19T06:43:42+00:00",
      startOfDay: "2022-12-19T00:00:00+00:00",
      name: "Admin QA Application Security",
      userId: 10006,
    },
    username: "qaappadmin",
    email: "roderick.mauricio@headhuntr.io",
    firstName: "Admin",
    lastName: "QA Application Security",
    fullName: "Admin QA Application Security",
    emailStatus: "VERIFIED",
    emailVerificationStatus: "PENDING",
    lastPasswordReset: "2022-12-15T13:05:36+00:00",
    lastSignOn: "2022-12-19T12:30:32+00:00",
    dateVerificationSent: null,
    isManager: true,
    isHiringManager: true,
    enforceDataSecurity: false,
    allowedDepartments: [],
    phone: {
      country: "CA",
      countryCode: "1",
      number: "1234567890",
    },
    cell: {
      country: "CA",
      countryCode: "1",
      number: "2345678901",
    },
    userDefaults: {
      search: {
        excludeCurrentReq: false,
        excludeOtherReq: true,
        excludeOtherReqStage: "PROSPECT",
        excludeExported: false,
      },
      task: {
        type: "PHONE",
      },
    },
    admin: true,
    allowedToLogin: true,
    securityRoles: [
      {
        id: 10007,
        name: "QA - APP SEC ADMIN",
        menuAccess: [
          "TASKS",
          "SEARCH",
          "REQS",
          "MY_PROFILE",
          "HOME",
          "HIRING_MANAGER_REVIEW",
          "ACCOUNT_ADMIN",
          "APP_ADMIN",
        ],
        _links: {
          self: {
            href: "https://qa.api.headhuntr.io/api/securityRoles/10007",
          },
        },
      },
    ],
    companyAccount: {
      id: 10002,
      name: "headhuntr.io - QA(internal)",
      active: true,
      enforceDataSecurity: false,
      internal: true,
      allowNotes: true,
      expirationDate: "2024-01-01T00:00:00+00:00",
      activeUserCount: 6,
      totalExportCount: 100,
      exportPerUserCount: 10,
      currentExportCount: 1,
      allowedDepartments: [],
      contactAvailabilityType: "UNAVAILABLE",
      searchAvailabilityType: "UNLIMITED",
      searchPerUserCount: 0,
      totalSearchCount: 0,
      currentSearchCount: 44,
      countries: [],
      totalContactRequestCount: 9999999,
      totalContactRequestPerUserCount: 9999999,
      currentContactRequestCount: 61,
      atsExportEnabled: false,
      csvExportEnabled: true,
      diversityEnabled: false,
      bulkEmailEnabled: false,
      emailAllActiveUsers: false,
      domainVerificationStatus: null,
      domain: null,
      _links: {
        self: {
          href:
            "https://qa.api.headhuntr.io/api/companyAccounts/10002{?projection}",
          templated: true,
        },
        deactivatedBy: {
          href:
            "https://qa.api.headhuntr.io/api/companyAccounts/10002/deactivatedBy{?projection}",
          templated: true,
        },
        accounts: {
          href:
            "https://qa.api.headhuntr.io/api/companyAccounts/10002/accounts{?projection}",
          templated: true,
        },
      },
    },
    manager: null,
    userAgreement: true,
    hideWelcomeMessage: false,
    hideTutorial: false,
    currentExportCount: 0,
    currentSearchCount: 25,
    searchPerUserCount: 0,
    countries: [],
    contactAvailabilityType: "UNAVAILABLE",
    searchAvailabilityType: "UNLIMITED",
    currentContactRequestCount: 1,
    totalContactRequestPerUserCount: 9999999,
    active: true,
    _links: {
      self: {
        href: "https://qa.api.headhuntr.io/api/accounts/10006",
      },
      account: {
        href: "https://qa.api.headhuntr.io/api/accounts/10006{?projection}",
        templated: true,
      },
      deactivatedBy: {
        href:
          "https://qa.api.headhuntr.io/api/accounts/10006/deactivatedBy{?projection}",
        templated: true,
      },
      manager: {
        href:
          "https://qa.api.headhuntr.io/api/accounts/10006/manager{?projection}",
        templated: true,
      },
      securityRoles: {
        href: "https://qa.api.headhuntr.io/api/accounts/10006/securityRoles",
      },
      companyAccount: {
        href:
          "https://qa.api.headhuntr.io/api/accounts/10006/companyAccount{?projection}",
        templated: true,
      },
    },
  },
};

export default data;
