import * as dataJs from "./data";
import resource from "../../../../config/resource";

export const getCommunications = (mock) => {
  mock.onGet(new RegExp(`${resource.communication}`)).reply(200, {
    total: dataJs.communicationsList.length || 0,
    total: 150,
    communications: dataJs.communicationsList(25),
  });
};

export const deleteCommunications = (mock) => {
  mock.onDelete(new RegExp(`${resource.communication}`)).reply(200, {});
};

export default [
  // getCommunications, 
  deleteCommunications];
