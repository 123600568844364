const records = [
  {
    id: 0,
    exportedFrom: "Workers of Walmart OR",
    exportedBy: "Amanda Briggs",
    count: 16,
    exportDate: "2023-02-01T00:00:00.000+00:00",
    lastDownload: "2023-02-01T00:00:00.000+00:00",
    isDownloadable: true,
    status: "SUCCESS",
  },
  {
    id: 1,
    exportedFrom: "Bookmarks",
    exportedBy: "Amanda Man",
    count: 20,
    exportDate: "2023-01-01T00:00:00.000+00:00",
    lastDownload: "2023-02-01T00:00:00.000+00:00",
    isDownloadable: false,
    status: "PENDING",
  },
  {
    id: 2,
    exportedFrom: "Person Lookup",
    exportedBy: "Amanda Man",
    count: 10,
    exportDate: "2022-01-01T00:00:00.000+00:00",
    lastDownload: "2023-02-01T00:00:00.000+00:00",
    isDownloadable: false,
    status: "PENDING",
  },
  {
    id: 3,
    exportedFrom: "Person Lookup",
    exportedBy: "Amanda Briggs",
    count: 10,
    exportDate: "2022-01-01T00:00:00.000+00:00",
    lastDownload: "2022-02-01T00:00:00.000+00:00",
    isDownloadable: true,
    status: "FAILED",
  },
  {
    id: 4,
    exportedFrom: "Bookmarks",
    exportedBy: "Amanda Doe",
    count: 300,
    exportDate: "2022-01-01T00:00:00.000+00:00",
    lastDownload: "2022-02-01T00:00:00.000+00:00",
    isDownloadable: true,
    status: "SUCCESS",
  },
];

const data = {
  _embedded: {
    records: [...records],
  },
  page: {
    size: 25,
    totalElements: 5,
    totalPages: 1,
    number: 0,
    totalExports: 2000,
    totalDownloaded: 630,
  },
};

export default data;
