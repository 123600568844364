import actionTypes from "./actionTypes";

export const setData = (params) => {
  return {
    data: { ...params },
    type: actionTypes.SEARCH_SET_DATA,
  };
};
export const get = (id) => {
  return {
    id,
    type: actionTypes.SEARCH_GET,
  };
};
export const getStart = () => {
  return {
    type: actionTypes.SEARCH_GET_START,
  };
};
export const getComplete = (params) => {
  return {
    ...params,
    type: actionTypes.SEARCH_GET_COMPLETE,
  };
};
export const save = (config, cb) => {
  return {
    config,
    cb,
    type: actionTypes.SEARCH_SAVE,
  };
};
export const saveStart = () => {
  return {
    type: actionTypes.SEARCH_SAVE_START,
  };
};
export const saveComplete = (params) => {
  return {
    ...params,
    type: actionTypes.SEARCH_SAVE_COMPLETE,
  };
};
export const saveAs = (config) => {
  return {
    config,
    type: actionTypes.SEARCH_SAVE_AS,
  };
};
export const saveAsStart = () => {
  return {
    type: actionTypes.SEARCH_SAVE_AS_START,
  };
};
export const saveAsComplete = (params) => {
  return {
    ...params,
    type: actionTypes.SEARCH_SAVE_AS_COMPLETE,
  };
};
export const setDefault = () => {
  return {
    type: actionTypes.SEARCH_SET_DEFAULT,
  };
};
export const setRequisition = (requisition) => {
  return {
    requisition,
    type: actionTypes.SEARCH_SET_REQUISITION,
  };
};
export const setNotif = (notif) => {
  return {
    notif,
    type: actionTypes.SEARCH_SET_NOTIF,
  };
};
export const resetState = () => {
  return {
    type: actionTypes.SEARCH_RESET_STATE,
  };
};
export const assignReq = (params) => {
  return {
    ...params,
    type: actionTypes.SEARCH_ASSIGN_REQ,
  };
};
export const assignReqStart = () => {
  return {
    type: actionTypes.SEARCH_ASSIGN_REQ_START,
  };
};
export const assignReqComplete = (params) => {
  return {
    ...params,
    type: actionTypes.SEARCH_ASSIGN_REQ_COMPLETE,
  };
};
export const newReq = (params) => {
  return {
    ...params,
    type: actionTypes.SEARCH_NEW_REQ,
  };
};
export const newReqStart = () => {
  return {
    type: actionTypes.SEARCH_NEW_REQ_START,
  };
};
export const newReqComplete = (params) => {
  return {
    ...params,
    type: actionTypes.SEARCH_NEW_REQ_COMPLETE,
  };
};
export const copyReq = (params) => {
  return {
    ...params,
    type: actionTypes.SEARCH_COPY_REQ,
  };
};
export const copyReqStart = () => {
  return {
    type: actionTypes.SEARCH_COPY_REQ_START,
  };
};
export const copyReqComplete = (params) => {
  return {
    ...params,
    type: actionTypes.SEARCH_COPY_REQ_COMPLETE,
  };
};
export const searchKeywordMatch = (params) => {
  return {
    ...params,
    type: actionTypes.SEARCH_KEYWORD_MATCH,
  };
};
export const searchKeywordStart = () => {
  return {
    type: actionTypes.SEARCH_KEYWORD_MATCH_START,
  };
};
export const searchKeywordMatchComplete = (params) => {
  return {
    ...params,
    type: actionTypes.SEARCH_KEYWORD_MATCH_COMPLETE,
  };
};
export const searchToggleAutoCollapse = (isAutoCollapseOn) => {
  return {
    isAutoCollapseOn,
    type: actionTypes.SEARCH_TOGGLE_AUTO_COLLAPSE,
  };
};
export const getRefineSearchResultCount = (params) => {
  return {
    payload: params.payload,
    type: actionTypes.SEARCH_GET_REFINE_SEARCH_RESULT_COUNT,
  };
};
export const updateRefineSearchResultCount = (params) => {
  return {
    data: params,
    type: actionTypes.SEARCH_UPDATE_REFINE_SEARCH_RESULT_COUNT,
  };
};
