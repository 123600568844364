import * as Sentry from "@sentry/browser";

class Logger {
  init() {
    if (process.env.SENTRY_DSN) {
      Sentry.init({
        dsn: process.env.SENTRY_DSN,
        environment: process.env.NODE_ENV,
      });
      Sentry.configureScope((scope) => {
        scope.setExtra("App Version", process.env.APP_VERSION);
      });
    }
  }

  captureException(data) {
    Sentry.captureException(data);
  }
}

const loggerInstance = new Logger();

export default loggerInstance;
