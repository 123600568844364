import MockAdapter from "axios-mock-adapter";

import platormApiSvc from "@services/platform-api/";
import resourceHandlers from "./resource-handlers/";

const apiMock = () => {
  const mock = new MockAdapter(platormApiSvc, {
    delayResponse: 1000,
  });

  resourceHandlers.forEach((endpoint) => {
    endpoint(mock);
  });

  mock.onAny().passThrough();
};

export default apiMock;
