import actionTypes from "./actionTypes";

export const auth = (args) => {
  return {
    ...args,
    type: actionTypes.USER_AUTH,
  };
};
export const authStart = () => {
  return {
    type: actionTypes.USER_AUTH_START,
  };
};
export const authComplete = () => {
  return {
    type: actionTypes.USER_AUTH_COMPLETE,
  };
};
export const logout = (ars) => {
  return {
    ...ars,
    type: actionTypes.USER_LOGOUT,
  };
};
export const getData = () => {
  return {
    type: actionTypes.USER_GET_DATA,
  };
};
export const setData = (args) => {
  return {
    ...args,
    type: actionTypes.USER_SET_DATA,
  };
};
export const logoutComplete = () => {
  return {
    type: actionTypes.USER_LOGOUT_COMPLETE,
  };
};
export const disableTutorial = () => {
  return {
    type: actionTypes.USER_DISABLE_TUTORIAL,
  };
};
export const getLicenseData = () => {
  return {
    type: actionTypes.USER_GET_LICENSE,
  };
};
export const setLicenseData = (args) => {
  return {
    ...args,
    type: actionTypes.USER_SET_LICENSE,
  };
};
