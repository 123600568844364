import platformApiSvc from "@services/platform-api/";
import resource from "@config/resource";
import moment from "moment";

export const getRequisitionDetails = async (requiId, reqArgs = {}) => {
  const resp = await platformApiSvc.get(
    `${resource.requisitions}/${requiId}`,
    reqArgs
  );

  resp.data.timeFetched = moment();

  return resp;
};
